import { IconTags } from '@tabler/icons-react';
import {
  ActionIcon,
  Combobox,
  Divider,
  Group,
  Pill,
  PillsInput,
  Popover,
  rem,
  Text,
  ThemeIcon,
  Tooltip,
  useCombobox,
} from '@mantine/core';
import If from '@/components/Common/If/If';
import { useManageRiskTags } from '@/components/Risks/RiskTags/use-manage-risk-tags';
import { TagOptionMenu } from './TagOptionMenu';
import { RiskTagBadge } from './RiskTagBadge';

import styles from './RiskTagsMenu.module.css';

interface RiskTagsMenuProps {
  riskId: string;
}

export const RiskTagsMenu = ({ riskId }: RiskTagsMenuProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
    },
  });

  const {
    selectedTags,
    searchQuery,
    setSearchQuery,
    availableTags,
    availableTagsMap,
    handleTagSelect,
    handleCreateNewTag,
    handleTagRemove,
    handleDeleteTagOption,
    handleTagColorChange,
  } = useManageRiskTags({ riskId });

  const selectedTagPills = selectedTags.map((tagId) => (
    <RiskTagBadge
      key={tagId}
      tag={availableTagsMap[tagId]}
      onRemove={handleTagRemove}
      withRemoveButton
    />
  ));

  const filteredAvailableTags = availableTags
    .filter((tag) => !selectedTags.includes(tag.id))
    .filter((tag) => tag.tag.toLowerCase().includes(searchQuery.trim().toLowerCase()))
    .map((tag) => (
      <Combobox.Option
        className={styles.optionItem}
        value={tag.id}
        key={tag.tag}
        active={selectedTags.includes(tag.tag)}
        py="xs"
      >
        <Group justify="space-between">
          <Pill c={tag.color} bg="transparent">
            {tag.tag}
          </Pill>

          <TagOptionMenu
            tagColor={tag.color || 'blue'}
            onColorChange={(color) => handleTagColorChange(tag.id, color)}
            onDeleteTag={() => handleDeleteTagOption(tag.id)}
          />
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleTagSelect}
      withArrow={false}
      withinPortal={false}
    >
      <Popover position="bottom" withArrow shadow="md">
        <Popover.Target>
          <Tooltip label="Custom Tags" withArrow>
            <ActionIcon size="sm">
              <ThemeIcon>
                <IconTags stroke={1.5} size={18} />
              </ThemeIcon>
            </ActionIcon>
          </Tooltip>
        </Popover.Target>

        <Popover.Dropdown w={250} maw={350} px={0} py="xs">
          <If condition={selectedTags.length > 0}>
            <Pill.Group px="xs" gap={rem(4)}>
              {selectedTagPills}
            </Pill.Group>
            <Divider mt="xs" />
          </If>

          <PillsInput
            className={styles.tagsInput_input}
            classNames={{ input: styles.tagsInput_input }}
          >
            <Combobox.EventsTarget>
              <PillsInput.Field
                value={searchQuery}
                placeholder="Search or Create new"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearchQuery(event.currentTarget.value.replace(',', ''));
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && searchQuery.length === 0) {
                    event.preventDefault();
                  } else if (event.key === 'Enter' || event.key === ',') {
                    handleCreateNewTag(searchQuery);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </PillsInput>

          <Combobox.Options className={styles.optionsWrapper}>
            {filteredAvailableTags.length > 0 ? (
              filteredAvailableTags
            ) : (
              <Combobox.Empty>
                <Text textfor="caption">No tags found.</Text>
              </Combobox.Empty>
            )}
          </Combobox.Options>
        </Popover.Dropdown>
      </Popover>
    </Combobox>
  );
};

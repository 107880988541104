import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { ensureUseRiskRetrieveData } from '@/openapi/queries/ensureQueryData';

export const EditRiskLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { riskId } = params;
    if (!riskId) throw new Error('Risk not found');

    const risk = await ensureUseRiskRetrieveData(queryClient, { path: { riskId } });

    return { risk };
  };

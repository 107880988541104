import { Link } from 'react-router-dom';
import { Anchor, Card, Divider, Group, rem, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconMessageDots, IconPaperclip } from '@tabler/icons-react';
import { CopyButton } from '@/components/Core/CopyButton/CopyButton';
import { SubmissionItemDto, UserTypeEnum } from '@/openapi/requests';
import SeverityStatus from '@/components/Common/SeverityStatus/SeverityStatus';
import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { SubmissionStatusSelect } from '../SubmissionStatusSelect/SubmissionStatusSelect';
import { useUserType } from '@/hooks/use-user-type';
import { SubmissionTagsView } from '../SubmissionTags/SubmissionTagsView';
import { SubmissionTagsMenu } from '../SubmissionTags/SubmissionTagsMenu';

const SubmissionDate = ({ date }: { date: string }) => (
  <Text textfor="info">
    <DateDisplay showDate date={date} />
  </Text>
);

const SubmissionTitle = ({ id, title }: { id: string; title: string }) => (
  <Group gap={rem(4)} wrap="nowrap">
    <Anchor underline="hover" component={Link} to={`/submission/${id}`}>
      <Title order={3} textfor="h6" lineClamp={1} style={{ maxWidth: '650px' }}>
        {title}
      </Title>
    </Anchor>
    <CopyButton value={title} />
  </Group>
);

const CommentCount = ({ count = 0 }: { count: number }) => (
  <Group>
    <ThemeIcon size="xs">
      <IconMessageDots size="100%" />
    </ThemeIcon>
    <Text span textfor="info">
      {count}
    </Text>
  </Group>
);

const AttachmentCount = ({ count = 0 }: { count: number }) => (
  <Group>
    <ThemeIcon size="xs">
      <IconPaperclip size="100%" />
    </ThemeIcon>
    <Text span textfor="info">
      {count}
    </Text>
  </Group>
);

const SubmissionCardRow = ({
  submission,
  projectId,
}: {
  submission: SubmissionItemDto;
  projectId: string;
}) => {
  const userType = useUserType();
  const isResearcher = userType === UserTypeEnum.RESEARCHER;

  return (
    <Card shadow="xs">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={rem(4)}>
          <SeverityStatus severity={submission.severity} />
          <SubmissionTitle id={submission.id} title={submission.title} />
        </Stack>
        <Group justify="space-between">
          <SubmissionStatusSelect
            submissionId={submission.id}
            status={submission.status}
            projectId={projectId}
          />
        </Group>
      </Group>
      <Divider mt="md" mb="xs" />
      <Group justify="space-between">
        <Group gap="sm" wrap="wrap">
          <RiskCategoryBadge category={submission.category} />
          <RiskEnvironmentsBadge environment={submission.environment} />
          <Divider orientation="vertical" />
          <CommentCount count={submission.comments} />
          <AttachmentCount count={submission.attachments} />
          <Divider orientation="vertical" />
          {!isResearcher && (
            <Group>
              <Divider orientation="vertical" />
              <SubmissionTagsView submissionId={submission.id} />
              <SubmissionTagsMenu submissionId={submission.id} />
            </Group>
          )}
        </Group>
        <Group gap="sm" wrap="wrap">
          <SubmissionDate date={submission.createdAt} />
        </Group>
      </Group>
    </Card>
  );
};

export const SubmissionCard = ({
  layout = 'Row',
  submission,
  projectId,
}: {
  layout?: 'Row' | 'Column';
  submission: SubmissionItemDto;
  projectId: string;
}) =>
  layout === 'Row' ? (
    <SubmissionCardRow submission={submission} projectId={projectId} />
  ) : (
    'not implemented yet'
  );

import { Container, Title, Text, Button, Stack, Paper, Group } from '@mantine/core';
import { IconRefresh, IconAlertTriangle, IconHome, IconBug } from '@tabler/icons-react';
import { useRouteError } from 'react-router-dom';

interface ErrorFallbackTypes {
  data?: { message: string };
  internal: boolean;
  status: number;
  statusText: string;
  message?: string;
  stack?: string;
}

function ErrorFallback() {
  const error = useRouteError() as ErrorFallbackTypes;
  const isDevelopment = import.meta.env.DEV;

  return (
    <Container size="md" py="xl">
      <Paper shadow="sm" p="xl" withBorder>
        <Stack align="center" gap="md">
          <IconAlertTriangle size={50} color="red" stroke={1.5} />

          <Title order={2} c="red" fw="bold">
            Something went wrong
          </Title>

          <Text c="dimmed" size="lg" ta="center">
            An unexpected error has occurred. Our team has been notified.
          </Text>

          {isDevelopment && (error.data?.message || error?.message) && (
            <Paper withBorder p="md" bg="gray.0" style={{ width: '100%' }}>
              <Stack gap="xs">
                <Group>
                  <IconBug size={16} />
                  <Text fw={700}>Error Details:</Text>
                </Group>
                <Text ff="monospace" size="sm">
                  {error.data?.message || error.message}
                </Text>
                <Group>
                  <IconBug size={16} />
                  <Text fw={700}>Stack:</Text>
                </Group>
                <Text ff="monospace" size="sm">
                  {error.stack}
                </Text>
              </Stack>
            </Paper>
          )}

          <Group gap="md">
            <Button
              leftSection={<IconRefresh size={16} />}
              onClick={() => window.location.reload()}
              variant="filled"
            >
              Try again
            </Button>
            <Button
              leftSection={<IconHome size={16} />}
              onClick={() => (window.location.href = '/')}
              variant="light"
            >
              Go to Home
            </Button>
          </Group>
        </Stack>
      </Paper>
    </Container>
  );
}

export default ErrorFallback;

import { useLoaderData, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Container, LoadingOverlay, Space } from '@mantine/core';
import { SubmissionDetailDto, SubmissionUpdateDto } from '@/openapi/requests';
import { useProjectRetrieve, useSubmissionRetrieve, useSubmissionUpdate } from '@/openapi/queries';
import { paths } from '@/configuration';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';
import { SubmissionForm } from '@/components/Submissions/SubmissionForm/SubmissionForm';

interface LoaderProps {
  submission: SubmissionDetailDto;
}

export const EditSubmissionPage = () => {
  const { submission: _submisison } = useLoaderData() as LoaderProps;
  const { data: submission, refetch } = useSubmissionRetrieve({
    path: { submissionId: _submisison.id },
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: project,
    isLoading,
    isError,
  } = useProjectRetrieve({
    path: { projectId: submission!.projectId },
  });

  const updateSubmission = useSubmissionUpdate(undefined, {
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Submission updated successfully',
      });
      await refetch();
      await queryClient.invalidateQueries();
      navigate(paths.submission(submission!.id));
    },
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to update submission',
        message: (error as Error).message,
      });
    },
  });

  const handleUpdateSubmission = async (values: SubmissionUpdateDto) => {
    await updateSubmission.mutateAsync({
      path: { submissionId: submission!.id },
      body: { ...values },
    });
  };

  useTrackPageView('edit_submission');

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return (
    <Container>
      <Box mt="lg">
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: 'Project', href: '/projects/' },
            { label: project?.name ?? '', href: `/project/${project?.id}` },
            { label: submission?.title ?? '', href: `/submission/${submission?.id}` },
          ]}
        />
      </Box>
      <Space h="sm" />
      <SubmissionForm
        edit
        onUpdate={handleUpdateSubmission}
        submission={submission}
        projectId={submission!.projectId}
      />
    </Container>
  );
};

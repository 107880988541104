import { Link } from 'react-router-dom';
import moment from 'moment';

import { IconBook, IconDots, IconPencil } from '@tabler/icons-react';
import {
  Badge,
  Button,
  Card,
  Group,
  Text,
  Title,
  Box,
  ActionIcon,
  ThemeIcon,
  Stack,
  Flex,
  Menu,
} from '@mantine/core';
import { useState } from 'react';
import { useUserType } from '@/hooks/use-user-type';
import { ProjectDto, UserTypeEnum } from '@/openapi/requests';
import If from '@/components/Common/If/If';
import { ProjectTitleEditModal } from '../ProjectTitleEditModal/ProjectTitleEditModal';
import { ProjectScopeModal } from '../ProjectScopeModal/ProjectScopeModal';
import classes from './ProjectCard.module.css';
import ProjectStats from '../ProjectStats/ProjectStats';
import ProjectCardSubmissions from '../ProjectCardSubmissions/ProjectCardSubmissions';
import { useProjectSubmissionsStats } from '@/openapi/queries';

interface ProjectCardProps {
  project: ProjectDto;
}

export function ProjectCard({ project }: ProjectCardProps) {
  const userType = useUserType();
  const [projectName, setProjecName] = useState(project.name);
  const { data: submissionsStats } = useProjectSubmissionsStats({
    path: { projectId: project.id },
  });

  return (
    <Card pt="md" style={{ overflow: 'visible' }}>
      <Flex direction="column" justify="space-between" align="start" h="100%">
        <Flex direction="column" flex={1} w="100%" align="start">
          <Group justify="space-between" align="center" w="100%">
            <Group>
              <Badge color={project.status === 'ACTIVE' ? 'green' : 'red'} variant="light">
                {project.status}
              </Badge>
            </Group>

            <Box>
              <Menu
                shadow="md"
                classNames={{
                  itemSection: classes.leftIcon,
                  item: classes.menuItem,
                }}
              >
                <Menu.Target>
                  <ActionIcon color="" variant="subtle">
                    <ThemeIcon>
                      <IconDots />
                    </ThemeIcon>
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <If condition={userType !== UserTypeEnum.RESEARCHER}>
                    <Menu.Item
                      onClick={() => {
                        document.getElementById(`editModal-${project.id}`)?.click();
                      }}
                      leftSection={
                        <ThemeIcon>
                          <IconPencil size={16} />
                        </ThemeIcon>
                      }
                    >
                      <Text>Edit Project name</Text>
                    </Menu.Item>
                  </If>
                  <Menu.Item
                    leftSection={
                      <ThemeIcon>
                        <IconBook size={16} />
                      </ThemeIcon>
                    }
                    onClick={() => {
                      document.getElementById(`scopeModal-${project.id}`)?.click();
                    }}
                  >
                    <Text>View scope</Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <ProjectScopeModal projectId={project.id}>
                <div id={`scopeModal-${project.id}`} />
              </ProjectScopeModal>
              <ProjectTitleEditModal
                projectId={project.id}
                currentTitle={projectName}
                onSuccess={setProjecName}
              >
                <div id={`editModal-${project.id}`} />
              </ProjectTitleEditModal>
            </Box>
          </Group>

          <Box h={86} w={160} p={8} mx="auto">
            <img src={project.logo_url} alt={projectName} className={classes.logo} />
          </Box>
          <Group gap={16}>
            <Box flex={1}>
              <Title textfor="h3" fw={600}>
                {projectName}
              </Title>
              <Text textfor="info">{project.company.name}</Text>
            </Box>
          </Group>
        </Flex>
        <Stack w="100%">
          <Group>
            {project.environments.length > 0 ? (
              project.environments.map((environment) => (
                <Badge key={environment} color="blue" variant="light">
                  {environment}
                </Badge>
              ))
            ) : (
              <Badge key="other" color="blue" variant="light">
                other
              </Badge>
            )}
          </Group>
          <Card.Section withBorder>
            <ProjectStats nonFiltered project={project} />
          </Card.Section>
          <Group justify="space-between">
            <Box>
              <Text textfor="info">{moment(project.created).format('LL')}</Text>
            </Box>
            <Box>
              <Button component={Link} to={`/project/${project.id}`}>
                Show Risks
              </Button>
            </Box>
          </Group>
        </Stack>
        <ProjectCardSubmissions submissions={submissionsStats?.submissionsInReview ?? 0} />
      </Flex>
    </Card>
  );
}

import { Badge, BadgeProps, Group } from '@mantine/core';

import { RISK_CATEGORY_LABEL_MAP, type RiskCategoryType } from '@/constants';
import { RiskCategoryEnum } from '@/openapi/requests';

interface RiskCategoryProps {
  category: RiskCategoryType | RiskCategoryEnum;
  size?: BadgeProps['size'];
}

export const RiskCategoryBadge = ({ category, size }: RiskCategoryProps) => (
  <Group gap="xs">
    <Badge variant="light" radius="sm" color="gray" size={size} tt="capitalize">
      {RISK_CATEGORY_LABEL_MAP[category]}
    </Badge>
  </Group>
);

import { QueryClient } from '@tanstack/react-query';
import { paths } from '@/configuration';
import EditRiskPage from './EditRisk.page';
import { EditRiskLoader } from './EditRisk.loader';

export const EditRiskPath = (queryClient: QueryClient) => ({
  path: paths.editRisk(':riskId'),
  element: <EditRiskPage />,
  loader: EditRiskLoader(queryClient),
});

import { Avatar, Group, Stack, Table, Text, Title } from '@mantine/core';
import { UserStatusSwitcher } from '@/components/Users/UsersTable/UserStatusSwitcher';
import { UserDto } from '@/openapi/requests';

export const UserRow = ({ user }: { user: UserDto }) => (
  <Table.Tr>
    <Table.Td>
      <Group>
        <Avatar
          size={32}
          src={`https://www.gravatar.com/avatar/${user.email_sha256}?d=robohash&r=g&s=512`}
        />
        <Stack gap={0}>
          <Group align="center">
            <Title order={4} textfor="avatar">
              {user.name}
            </Title>
          </Group>
          <Text textfor="info">{user.email}</Text>
        </Stack>
      </Group>
    </Table.Td>
    <Table.Td>
      <UserStatusSwitcher />
    </Table.Td>
  </Table.Tr>
);

import { Anchor, Box, Card, Group, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  IconCirclePlus,
  IconCopyright,
  IconExternalLink,
  IconParkingCircle,
  IconUnlink,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import { modals } from '@mantine/modals';
import {
  useSubmissionRiskCreate,
  useSubmissionRiskLinkDelete,
  useSubmissionRiskLinkList,
} from '@/openapi/queries';
import { SubmissionRiskLinkDetailDto, SubmissionRiskLinkTypeEnum } from '@/openapi/requests';
import classes from './ChildRiskList.module.css';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';

export const ChildRiskList = ({ submissionId }: { submissionId: string }) => {
  const [childRisks, setChildRisks] = useState<SubmissionRiskLinkDetailDto[]>([]);

  const { data: childRiskData, refetch: refetchChildRisks } = useSubmissionRiskLinkList({
    query: { submissionId, linkType: SubmissionRiskLinkTypeEnum.PARENTCHILD },
  });

  useEffect(() => {
    if (childRiskData !== undefined) {
      setChildRisks(childRiskData);
    }
  }, [childRiskData]);

  const unlinkRisk = useSubmissionRiskLinkDelete(undefined, {
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Risk unlink failed',
        message: (error as Error).message,
      });
    },
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk unlinked successfully',
      });
      await refetchChildRisks();
    },
  });

  const createRisk = useSubmissionRiskCreate(undefined, {
    onError: (error: Error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to create risk',
        message: error.message,
      });
    },
    onSuccess: (data) => {
      const riskId = data.data?.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk created successfully',
      });
      window.open(`/risk/${riskId}`, '_blank');
    },
  });

  const handleCreateRisk = () => {
    modals.openConfirmModal({
      title: 'Confirm child risk creation',
      centered: true,
      children: (
        <Text size="sm">
          This submission already has child risk. Would you like to create a new child risk?
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        await createRisk.mutateAsync({ path: { submissionId } });
        await refetchChildRisks();
      },
    });
  };

  if (childRisks.length === 0) {
    return null;
  }

  return (
    <Stack gap={4}>
      <Group justify="space-between">
        <Text textfor="label">Child Risks</Text>
        <IconCirclePlus size={16} onClick={handleCreateRisk} />
      </Group>
      <Box>
        <Stack gap={4}>
          <Card withBorder radius="md" className={classes.container}>
            <Stack gap={4}>
              {childRisks.map((linked_risk) => (
                <Group
                  className={classes.childRisk}
                  justify="space-between"
                  key={linked_risk.riskId}
                >
                  <Group className={classes.title}>
                    {linked_risk.linkType === SubmissionRiskLinkTypeEnum.PARENTCHILD ? (
                      <IconParkingCircle size={15} />
                    ) : (
                      <IconCopyright size={15} />
                    )}
                    <Anchor
                      component={Link}
                      target="_blank"
                      to={`/risk/${linked_risk.riskId}`}
                      key={linked_risk.riskId}
                    >
                      {linked_risk.riskTitle}
                    </Anchor>
                    <IconExternalLink
                      className={classes.linkIcon}
                      size={15}
                      style={{ cursor: 'pointer' }}
                    />
                  </Group>
                  <ConfirmModal
                    buttonText="Unlink Risk"
                    onConfirm={async () => {
                      await unlinkRisk.mutateAsync({
                        path: {
                          submissionRiskLinkId: linked_risk.id,
                        },
                      });
                    }}
                    title="Confirm Risk Unlink"
                    description="Are you sure you want to unlink this risk?"
                    color="red"
                  >
                    <Group className={classes.unlinkButton}>
                      <IconUnlink size={15} style={{ cursor: 'pointer' }} />
                      <Text color="gray" fz="12px">
                        Unlink
                      </Text>
                    </Group>
                  </ConfirmModal>
                </Group>
              ))}
            </Stack>
          </Card>
        </Stack>
      </Box>
    </Stack>
  );
};

import { Card, Grid, Text, Title, Space } from '@mantine/core';

import { ContractDto } from '@/openapi/requests';
import ProjectStats from '@/components/Projects/ProjectStats/ProjectStats';
import { ContractSummary } from './ContractSummary';
import { useProjectRetrieve } from '@/openapi/queries';

interface ContractDetailProps {
  contract: ContractDto;
}

export const ContractDetail = ({ contract }: ContractDetailProps) => {
  const {
    data: project,
    isLoading,
    isError,
  } = useProjectRetrieve({ path: { projectId: contract.project.id } });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (!project) {
    return <div>Error loading project</div>;
  }

  return (
    <Grid>
      <Grid.Col
        order={{
          md: 1,
          xs: 2,
        }}
        span={{
          xs: 12,
          md: 8,
        }}
      >
        <Card>
          <Title order={2} textfor="h2" mb="lg">
            Risk Stats
          </Title>

          {/* TODO: Change with contract stats */}
          <ProjectStats project={project} />
        </Card>

        <Space h="md" />

        <Card>
          <Title order={2} textfor="h2" mb="lg">
            Description
          </Title>

          <Text lineClamp={3}>{contract.project.description}</Text>
        </Card>
      </Grid.Col>

      <Grid.Col
        order={{
          md: 2,
          xs: 1,
        }}
        span={{
          xs: 12,
          md: 4,
        }}
      >
        <Card>
          <Title order={2} textfor="h2" mb="lg">
            Summary
          </Title>

          <ContractSummary contract={contract} />
        </Card>
      </Grid.Col>
    </Grid>
  );
};

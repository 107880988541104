import { Link } from 'react-router-dom';
import moment from 'moment';

import { Badge, Box, Button, Card, Group, Progress, Stack, Text, Title } from '@mantine/core';

import { ContractDto, ProjectStatusEnum } from '@/openapi/requests';
import { currencyFormat } from '@/utils';

interface BadgeCardProps {
  contract: ContractDto;
}

export function ContractCard({ contract }: BadgeCardProps) {
  return (
    <Card>
      <Group justify="space-between">
        <Group>
          <Title textfor="h2">{contract.project.name}</Title>
          <Badge
            color={contract.project.status === ProjectStatusEnum.ACTIVE ? 'green' : 'red'}
            variant="light"
          >
            {contract.project.status}
          </Badge>
          <Text>
            {currencyFormat(contract.rate.toString())}
            {contract.payment_option === 'fixed' && '/hr'}
          </Text>
        </Group>
        <Text textfor="info">{moment(contract.project.created).format('LL')}</Text>
      </Group>

      <Group>
        <Text>{contract.companyName}</Text>
      </Group>

      <Stack gap="xs">
        <Box>
          <Text lineClamp={2} textfor="description">
            {contract.project.description}
          </Text>
        </Box>
      </Stack>

      <Box my="md">
        <Group justify="space-between">
          <Text component="span" textfor="label">
            Risk Submitted
          </Text>
          <Text component="span" textfor="body">
            10/24
          </Text>
        </Group>
        <Progress size="sm" color="cyan" value={80} striped />
      </Box>

      <Group>
        <Button fullWidth component={Link} to={`/contract/${contract.id}`}>
          Show details
        </Button>
      </Group>
    </Card>
  );
}

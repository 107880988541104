import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, zodResolver } from '@mantine/form';
import { IconCircleX, IconLock, IconUser } from '@tabler/icons-react';
import {
  Alert,
  Anchor,
  Box,
  Button,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import If from '@/components/Common/If/If';

import { loginSchema } from '@/lib/validations';
import { paths } from '@/configuration';
import { StorageKeys } from '@/utils/constants';
import { useAuthLogin, useUserMeRetrieveKey } from '@/openapi/queries';
import { useQueryClient } from '@tanstack/react-query';

export function AuthenticationTitle() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const icon = <IconCircleX />;
  const { search } = useLocation();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: zodResolver(loginSchema),
  });

  const loginMutation = useAuthLogin();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const intended = query.get('intended');
    if (loginMutation.isSuccess) {
      if (loginMutation.data.data?.accessToken) {
        localStorage.setItem(
          StorageKeys.JWT_TOKEN,
          JSON.stringify(loginMutation.data.data.accessToken)
        );
      }

      if (loginMutation.data.data?.refreshToken) {
        localStorage.setItem(
          StorageKeys.REFRESH_TOKEN,
          JSON.stringify(loginMutation.data.data.refreshToken)
        );
      }

      queryClient.invalidateQueries({
        queryKey: [useUserMeRetrieveKey],
      });

      // Go to the original URL or default '/' if it's not provided
      if (intended) {
        navigate(intended);
      } else {
        navigate('/');
      }
    }
  }, [
    loginMutation.data?.data?.accessToken,
    loginMutation.data?.data?.refreshToken,
    loginMutation.isSuccess,
    navigate,
    queryClient,
    search,
  ]);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        loginMutation.mutate({
          body: {
            email: values.email,
            password: values.password,
          },
        });
      })}
    >
      <Group justify="center" mb={32}>
        <Text>We are glad to see you again. Please log in to continue.</Text>
      </Group>
      <Stack gap="md">
        <TextInput
          label="Email"
          placeholder="johnny@discloze.com"
          disabled={loginMutation.isPending}
          required
          {...form.getInputProps('email')}
          leftSection={
            <ThemeIcon>
              <IconUser strokeWidth="inherit" />
            </ThemeIcon>
          }
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          required
          disabled={loginMutation.isPending}
          {...form.getInputProps('password')}
          leftSection={
            <ThemeIcon>
              <IconLock strokeWidth="inherit" />
            </ThemeIcon>
          }
        />
      </Stack>

      <If condition={loginMutation.isError}>
        <Alert color="red" icon={icon} title="Sign-in failed" variant="light" mt="sm">
          Sign-in failed
        </Alert>
      </If>

      <Button type="submit" fullWidth loading={loginMutation.isPending} size="md" mt="xl">
        Sign In
      </Button>

      <Box ta="center" mt="md">
        <Anchor component={Link} to={paths.requestResetPassword}>
          Forgot your password?
        </Anchor>
      </Box>
    </form>
  );
}

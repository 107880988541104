import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { Button, Text } from '@mantine/core';
import { useUserType } from '@/hooks/use-user-type';
import { paths } from '@/configuration';
import { useUnlockRequestList } from '@/openapi/queries';
import { UserTypeEnum } from '@/openapi/requests';

const useRiskUnlockRequestsNotifications = () => {
  const userType = useUserType();

  const riskUnlockRequests = useUnlockRequestList(undefined, undefined, {
    enabled: userType !== UserTypeEnum.RESEARCHER,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    riskUnlockRequests.data?.results.forEach((r) =>
      notifications.show({
        id: r.id,
        title: 'Unlock Request',
        message: (
          <div>
            <Text>
              <b>{r.risk_details.researcher.name}</b> requested to unlock the{' '}
              <b>{r.risk_details.title}</b> risk
            </Text>
            <Button
              onClick={() => {
                navigate(paths.risk(r.risk));
                notifications.hide(r.id);
              }}
              variant="light"
              size="compact-xs"
              mt="xs"
            >
              Show me
            </Button>
          </div>
        ),
      })
    );
  }, [riskUnlockRequests.data, navigate]);
};

const NotificationsProvider = () => {
  useRiskUnlockRequestsNotifications();

  return null;
};

export default NotificationsProvider;

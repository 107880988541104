import moment from 'moment';

import { Table } from '@mantine/core';

import { ContractDto } from '@/openapi/requests';
import { currencyFormat } from '@/utils';

interface ContractSummaryProps {
  contract: ContractDto;
}

export const ContractSummary = ({ contract }: ContractSummaryProps) => (
  <Table>
    <Table.Tbody>
      <Table.Tr>
        <Table.Th>Contract type :</Table.Th>
        <Table.Td>{contract.payment_option}</Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Th>Rate :</Table.Th>
        <Table.Td>
          {currencyFormat(contract.rate.toString())}
          {contract.payment_option === 'hourly' && '/hr'}
        </Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Th>Start date :</Table.Th>
        <Table.Td>{moment(contract.created).format('LL')}</Table.Td>
      </Table.Tr>
    </Table.Tbody>
  </Table>
);

import { QueryClient } from '@tanstack/react-query';
import { paths } from '@/configuration';
import { RiskDetailLoader } from './RiskDetail.loader';
import { RiskDetailPage } from './RiskDetail.page';

export const RiskDetailPath = (queryClient: QueryClient) => ({
  path: paths.risk(':riskId'),
  element: <RiskDetailPage />,
  loader: RiskDetailLoader(queryClient),
});

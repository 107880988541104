import { Center, Text, rem } from '@mantine/core';
import { RiskHistoryCard } from '@/components/Risks/RiskHistory/RiskHistoryCard';
import If from '@/components/Common/If/If';
import { useRiskHistoryList } from '@/openapi/queries';

interface RiskHistoryListProps {
  riskId: string;
}

const RiskHistoryList = ({ riskId }: RiskHistoryListProps) => {
  const historyQuery = useRiskHistoryList({
    path: { riskId },
  });

  if (historyQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (historyQuery.isError) {
    return <div>Failed to load history</div>;
  }

  const histories = historyQuery.data?.results ?? [];

  const renderEmptyHistory = (
    <Center h={rem(100)}>
      <Text textfor="body">No history found for this risk.</Text>
    </Center>
  );

  return (
    <If condition={histories.length > 0} fallback={renderEmptyHistory}>
      {histories.map((history) => (
        <RiskHistoryCard key={history.id} riskHistory={history} />
      ))}
    </If>
  );
};

export default RiskHistoryList;

import { Anchor, Group, Text, ThemeIcon } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons-react';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { useAttachmentRetrieve } from '@/openapi/queries';

export interface AttachmentItemProps {
  attachmentId: string;
}

export const AttachmentItem = ({ attachmentId }: AttachmentItemProps) => {
  const { data: attachmentDetails } = useAttachmentRetrieve({ path: { attachmentId } });

  if (!attachmentDetails) {
    // Handle the case when attachment details are not available or there's an error
    return null;
  }

  return (
    <Anchor download href={attachmentDetails.url} underline="hover">
      <Group>
        <ThemeIcon variant="transparent" size="xs" color="dark">
          <IconPaperclip stroke={1.5} size="14px" />
        </ThemeIcon>
        <Text textfor="attachment">
          {attachmentDetails.filename || `Attachment ${attachmentId}`}
        </Text>
        {attachmentDetails.created && (
          <Text textfor="info">
            <DateDisplay date={attachmentDetails.created} />
          </Text>
        )}
      </Group>
    </Anchor>
  );
};

import { RiskTagBadge } from './RiskTagBadge';
import { useRiskTagList } from '@/openapi/queries';

interface RiskTagsViewProps {
  riskId: string;
}

export const RiskTagsView = ({ riskId }: RiskTagsViewProps) => {
  const selectedTagsQuery = useRiskTagList({ path: { riskId } });

  return selectedTagsQuery.data?.results.map((tag) => (
    <RiskTagBadge key={tag.id} tag={tag.tag_details} />
  ));
};

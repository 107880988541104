import { useContext } from 'react';

import { UserContext } from '@/contexts/UserContext';
import { UserDto } from '@/openapi/requests';

export const useUserMe = (): UserDto => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserMe must be used within a UserProvider');
  }
  return context.user;
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';

import {
  Box,
  Button,
  Card,
  Container,
  Group,
  LoadingOverlay,
  rem,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';

import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';
import SeverityStatus from '@/components/Common/SeverityStatus/SeverityStatus';
import AttachmentUploadButton from '@/components/Attachments/AttachmentUploadButton/AttachmentUploadButton';
import { useCreateNewAttachment } from '@/components/Attachments/create-new-attachment';
import If from '@/components/Common/If/If';
import { AttachmentLoader } from '@/components/Attachments/AttachmentDisplay/AttachmentLoader';
import { AttachmentFile } from '@/components/Attachments/AttachmentDisplay/AttachmentFile';
import { MarkdownEditor } from '@/components/Common/Markdown/MarkdownEditor';
import { MarkdownViewer } from '@/components/Common/Markdown/MarkdownViewer';

import { isFile } from '@/utils';
import {
  RISK_CATEGORY_LABEL_MAP,
  RISK_ENVIRONMENT_LABEL_MAP,
  SEVERITY_LABEL_MAP,
} from '@/constants';

import {
  CategoryEnum,
  EnvironmentTypeEnum,
  RiskDto,
  RiskCreateDto,
  RiskUpdateDto,
  SeverityEnum,
  RiskStatusEnum,
} from '@/openapi/requests';

import { paths } from '@/configuration';

interface RiskFormProps {
  risk: PartialExcept<RiskDto, 'contract' | 'project'>;
  onUpdate?: (values: RiskUpdateDto) => Promise<unknown>;
  onCreate?: (values: RiskCreateDto) => Promise<unknown>;
  edit?: boolean;
}

interface CreateRiskMutationProps {
  title: string;
  category: string;
  description: string;
  environment: string;
  severity: string;
  contract: string | undefined;
  project: string | undefined;
  contractId: string | undefined;
  attachments: string[];
}

function getEnumOptions<T>(enumType: T) {
  return Object.entries(enumType as string).map(([key, value]) => ({
    value: key,
    label: value,
  }));
}

export function RiskForm({ risk, onUpdate, onCreate, edit }: RiskFormProps) {
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      project: risk.project,
      contract: risk.contract,
      title: risk.title,
      description: risk.description,
      category: risk.category,
      environment: risk.environment,
      severity: risk.severity,
      attachments: risk.attachments ?? [],
    } as CreateRiskMutationProps,
  });

  const [isMutating, setIsMutating] = useState(false);
  const [attachments, setAttachments] = useState<(File | string)[]>(form.values.attachments);
  const { createAttachment } = useCreateNewAttachment();
  /** Uploads files attachments, Handles both files and IDs.
   *  Returns array of all attachment IDs.
   */
  const uploadAttachments = async () => {
    const attachmentIds: string[] = [];

    const promises = attachments
      .map((attachment) => {
        if (!isFile(attachment)) {
          attachmentIds.push(attachment);
          return null;
        }

        return createAttachment(attachment)
          .then((res) => res.data?.id)
          .catch((err: Error) => {
            showNotification({
              title: 'Error uploading attachment',
              message: err.message,
              color: 'red',
            });
            throw err;
          });
      })
      .filter(Boolean);

    const results = await Promise.allSettled(promises);
    return results
      .filter((r) => r.status === 'fulfilled')
      .map((r) => r.status === 'fulfilled' && r.value)
      .concat(...attachmentIds);
  };

  const handleSubmit = async (
    values: CreateRiskMutationProps,
    riskStatus: RiskStatusEnum | undefined
  ) => {
    try {
      setIsMutating(true);
      const attachmentIds = await uploadAttachments();

      if (edit) {
        if (onUpdate) {
          await onUpdate({
            id: risk.id,
            title: values.title,
            description: values.description,
            category: values.category,
            environment: values.environment,
            severity: values.severity,
            status: riskStatus ?? 'NEW',
            project: values.project,
            contract: values.contract,
            attachmentIds,
          } as RiskUpdateDto);
        }
      } else if (onCreate) {
        await onCreate({
          title: values.title,
          description: values.description,
          category: values.category,
          environment: values.environment,
          severity: values.severity,
          status: riskStatus ?? 'DRAFT',
          projectId: values.project,
          contractId: values.contract,
          attachmentIds,
        } as RiskCreateDto);
      }
    } finally {
      setIsMutating(false);
    }
  };

  const handleReset = () => {
    navigate(risk.id ? paths.risk(risk.id) : paths.project(risk.project));
  };

  const isLoading = isMutating;

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Group justify="space-between" mb={12}>
        <Text textfor="h1" mt={12}>
          {edit ? `Edit Risk "${risk.title}"` : 'Add New Risk'}
        </Text>
      </Group>
      <form
        onSubmit={form.onSubmit(() => {
          // This will prevent the form from actually submitting when the buttons are clicked
          // and we will manually call handleSubmit instead
        })}
      >
        <Stack gap="md">
          <TextInput
            label="Risk Title"
            placeholder="User96 Endpoint is susceptible to XSS"
            withAsterisk
            required
            {...form.getInputProps('title')}
          />
          <Group mb={12}>
            <Select
              label="Severity"
              data={getEnumOptions(SEVERITY_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('severity')}
            />
            <Space />
            <Select
              label="Category"
              data={getEnumOptions(RISK_CATEGORY_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('category')}
            />
            <Space />
            <Select
              label="Environment"
              data={getEnumOptions(RISK_ENVIRONMENT_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('environment')}
            />
          </Group>
        </Stack>
        <Stack py="sm">
          <Textarea
            label="Description"
            placeholder="Describe the risk. Feel free to use Markdown syntax."
            autosize
            withAsterisk
            renderRoot={() => (
              <MarkdownEditor
                value={form.values.description}
                onChange={(value) => {
                  form.setFieldValue('description', value ?? '');
                }}
              />
            )}
          />

          <Group justify="space-between">
            <AttachmentUploadButton
              onChange={(files) => {
                setAttachments((prev) => [...prev, ...files]);
              }}
            />
            <Group gap="sm">
              <Button variant="outline" type="button" onClick={handleReset} size="sm">
                Cancel
              </Button>
              <If condition={edit}>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, risk.status)}
                >
                  Save
                </Button>
              </If>
              <If condition={!edit}>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, RiskStatusEnum.DRAFT)}
                >
                  Save as Draft
                </Button>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, RiskStatusEnum.REVIEW)}
                >
                  Send for Review
                </Button>
              </If>
            </Group>
          </Group>

          <If condition={attachments.length > 0}>
            <Group gap="sm">
              {attachments.map((attachment) => {
                if (!isFile(attachment)) {
                  return (
                    <AttachmentLoader
                      key={attachment}
                      attachmentId={attachment}
                      onDelete={(attachmentId) => {
                        setAttachments((prev) =>
                          prev.filter((a) => (!isFile(a) ? a !== attachmentId : a))
                        );
                      }}
                    />
                  );
                }

                return (
                  <AttachmentFile
                    key={attachment.name}
                    file={attachment}
                    onDelete={(file) => {
                      setAttachments((prev) =>
                        prev.filter((f) => (isFile(f) ? f.name !== file.name : f))
                      );
                    }}
                  />
                );
              })}
            </Group>
          </If>
        </Stack>
      </form>

      <Container px={0} mt={16} mb={32}>
        <Text textfor="h3">Preview</Text>
        <Card pb={32} mt={16}>
          <Box>
            <Card component={Stack} px={0} gap="sm" bg="none" withBorder={false}>
              <Group justify="space-between">
                <Stack gap={12}>
                  {form.values.title && (
                    <Title order={1} textfor="h1">
                      <Group gap={rem(4)}>{form.values.title}</Group>
                    </Title>
                  )}
                  {form.values.severity && (
                    <SeverityStatus
                      variant="filled"
                      severity={form.values.severity as SeverityEnum}
                    />
                  )}
                </Stack>
              </Group>
              <Group mt={8}>
                {form.values.category && (
                  <RiskCategoryBadge category={form.values.category as CategoryEnum} />
                )}
                {form.values.environment && (
                  <RiskEnvironmentsBadge
                    environment={form.values.environment as EnvironmentTypeEnum}
                  />
                )}
              </Group>
            </Card>
          </Box>
          <MarkdownViewer content={form.values.description} />
        </Card>
      </Container>
    </>
  );
}

import { Center, Table, Text } from '@mantine/core';
import { useCompany } from '@/hooks/use-company';
import { InvitationRow } from './InvitationRow';
import classes from './UsersTable.module.css';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useCompanyInvitationList } from '@/openapi/queries';

export const PendingInvitationsTable = () => {
  const company = useCompany();

  const { data, isLoading, isError } = useCompanyInvitationList({
    path: { companyId: company.id },
  });

  const pendingInvitations = data?.results ?? [];

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return null;
  }

  if (pendingInvitations.length === 0) {
    return (
      <Center>
        <Text>No Pending Invitations</Text>
      </Center>
    );
  }

  return (
    <Table classNames={classes}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>USERS</Table.Th>
          <Table.Th w="70px" />
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {pendingInvitations.map((invite) => (
          <InvitationRow key={invite.id} invite={invite} companyId={company.id} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default PendingInvitationsTable;

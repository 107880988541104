import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationsProvider from '@/Providers/NotificationsProvider';
import { UserContext } from '@/contexts/UserContext';
import { paths } from '@/configuration';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useUserMeRetrieve } from '@/openapi/queries';

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const AuthenticatedProvider = ({ children }: UserContextProviderProps) => {
  const navigate = useNavigate();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
  } = useUserMeRetrieve(undefined, undefined, {
    retry: false,
  });

  useEffect(() => {
    if (isSuccess && window.location.pathname === '/' && user?.user_type) {
      navigate(paths.appHome[user.user_type]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isSuccess, navigate, window.location.pathname]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !user) {
    return null;
  }

  return (
    <UserContext.Provider value={{ user }}>
      {children}
      <NotificationsProvider />
    </UserContext.Provider>
  );
};

import { Avatar, Tooltip } from '@mantine/core';

interface TeamMember {
  id: string;
  name: string;
  email_sha256: string;
}

interface AvatarStackProps {
  members: TeamMember[];
  max?: number;
}

const AvatarStack = ({ members, max = 10 }: AvatarStackProps) => (
    <Avatar.Group spacing="xs">
      {members.slice(0, max).map((member, idx) => (
        <Tooltip label={member.name} withArrow key={idx}>
          <Avatar
            src={`https://www.gravatar.com/avatar/${member.email_sha256}?s=200&d=robohash`}
            alt={member.name}
          />
        </Tooltip>
      ))}
      {members.length > max && (
        <Tooltip
          multiline
          w={200}
          label={members
            .slice(max - 1)
            .map((member) => member.name)
            .join(',\n')}
          withArrow
        >
          <Avatar>+{members.length - max + 1}</Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  );

export default AvatarStack;

import { json, LoaderFunctionArgs } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
  ensureUseContractRetrieveData,
  ensureUseProjectRetrieveData,
  ensureUseUserMeRetrieveData,
} from '@/openapi/queries/ensureQueryData';
import { UserTypeEnum } from '@/openapi/requests';

export const NewRiskLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const { projectId } = params;
    if (projectId === undefined) {
      throw json({ message: 'projectId is required' }, { status: 401 });
    }

    const contractId = new URL(request.url).searchParams.get('contract');
    if (contractId === null) {
      throw json({ message: 'contractId is required' }, { status: 401 });
    }

    const project = await ensureUseProjectRetrieveData(queryClient, {
      path: { projectId },
    });

    const contract = await ensureUseContractRetrieveData(queryClient, {
      path: { contractId },
    });

    const user = await ensureUseUserMeRetrieveData(queryClient);

    if (user.user_type === UserTypeEnum.RESEARCHER && contract.researcher.id !== user.id) {
      throw json({ message: 'You are not the researcher on this contract' }, { status: 401 });
    }

    return { project, contract, user };
  };

import { useState } from 'react';
import { Box, Button, Divider, Group, Tabs } from '@mantine/core';
import { IconFilter, IconLayoutKanban, IconLayoutList, IconPlus } from '@tabler/icons-react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ContractListDto, ProjectDto, UserDto, UserTypeEnum } from '@/openapi/requests';
import { KanbanLayout } from './KanbanLayout';
import { ListLayout } from './ListLayout';
import { useLayout } from './utils';

import classes from './RiskList.module.css';
import { TabActions } from './TabActions';
import { useUserType } from '@/hooks/use-user-type';
import { paths } from '@/configuration';
import If from '@/components/Common/If/If';

interface RiskListProps {
  project: ProjectDto;
}
interface LoaderProps {
  contractList: ContractListDto;
  user: UserDto;
}

type TabValue = 'list' | 'kanban';

const RiskList = ({ project }: RiskListProps) => {
  const layout = useLayout();
  const [activeTab] = useState<TabValue>(layout as TabValue);
  const [showFilter, setShowFilter] = useState(false);
  const usertype = useUserType();
  const navigate = useNavigate();
  const { contractList } = useLoaderData() as LoaderProps;
  const contract = contractList.results[0];
  const [filterStyle, setFilterStyle] = useState({
    overflow: 'hidden',
    maxHeight: '0',
    transition: 'max-height 0.5s ease-in-out',
  });

  const toggleFilter = () => {
    if (!showFilter) {
      setFilterStyle({
        maxHeight: '50px',
        transition: 'max-height 0.5s ease-in-out',
        overflow: 'hidden',
      });
    } else {
      setFilterStyle({
        overflow: 'hidden',
        maxHeight: '0',
        transition: 'max-height 0.5s ease-in-out',
      });
    }

    setShowFilter(!showFilter);
  };

  return (
    <>
      <Tabs
        defaultValue={layout}
        classNames={{
          list: classes['mantine-Tabs-list'],
        }}
      >
        <Tabs.List
          styles={{
            list: {
              border: 0,
            },
          }}
          justify="flex-end"
          className={classes.tabList}
        >
          <Group>
            <Tabs.Tab
              key="list"
              value="list"
              leftSection={<IconLayoutList stroke={1.5} size="1rem" />}
            >
              List
            </Tabs.Tab>
            <Tabs.Tab
              key="kanban"
              value="kanban"
              leftSection={<IconLayoutKanban stroke={1.5} size="1rem" />}
            >
              Kanban
            </Tabs.Tab>
          </Group>

          <Group>
            <Button
              onClick={toggleFilter}
              leftSection={<IconFilter stroke={1.5} size="1rem" />}
              mb={5}
              variant={showFilter ? 'light' : 'subtle'}
            >
              {showFilter ? 'Hide' : 'Filter'}
            </Button>
            <If condition={usertype === UserTypeEnum.RESEARCHER}>
              <Button
                mb={5}
                leftSection={<IconPlus stroke={1.5} size="1rem" />}
                variant=""
                onClick={() => {
                  navigate(`${paths.newRisk(project.id)}?contract=${contract.id}`);
                }}
              >
                Add Risk
              </Button>
            </If>
          </Group>
        </Tabs.List>
        <Box style={filterStyle}>
          <TabActions activeTab={activeTab} />
          <Divider />
        </Box>
        <Tabs.Panel value="list">
          <ListLayout project={project} />
        </Tabs.Panel>
        <Tabs.Panel value="kanban">
          <KanbanLayout project={project} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default RiskList;

import { useLoaderData } from 'react-router-dom';
import { Container, Divider, rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import {
  ContractDto,
  ProjectDto,
  SubmissionCreateDto,
  UserDto,
  UserTypeEnum,
} from '@/openapi/requests';
import ProjectHeader from '@/components/Projects/ProjectHeader/ProjectHeader';
import { paths } from '@/configuration';
import { SubmissionForm } from '@/components/Submissions/SubmissionForm/SubmissionForm';
import { useSubmissionCreate } from '@/openapi/queries';

interface LoaderProps {
  project: ProjectDto;
  contract: ContractDto;
  user: UserDto;
}

export function NewSubmissionPage() {
  const { project, contract, user } = useLoaderData() as LoaderProps;

  const createSubmission = useSubmissionCreate(undefined, {
    onSuccess: (data) => {
      const submissionId = data.data?.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Submission created successfully',
      });
      window.location.href = `/submission/${submissionId}`;
    },
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Failed to create submission',
        message: (error as Error).message,
      });
    },
  });

  const handleCreateSubmission = async (values: SubmissionCreateDto) => {
    await createSubmission.mutateAsync(
      {
        body: values,
      }
      // {
      //   onSuccess: (data) => {
      //     const submissionId = data.data?.id;
      //     notifications.show({
      //       color: 'green',
      //       title: 'Success',
      //       message: 'Submission created successfully',
      //     });
      //     window.location.href = `/submission/${submissionId}`;
      //   },
      //   onError: (error: unknown) => {
      //     notifications.show({
      //       color: 'red',
      //       title: 'Failed to create submission',
      //       message: (error as Error).message,
      //     });
      //   },
      // }
    );
  };

  useTrackPageView('new_submission');

  return (
    <Container>
      <ProjectHeader
        project={project}
        contract={contract}
        userType={user.user_type as UserTypeEnum}
        breadcrumbs={[
          { label: 'Project', href: paths.projects },
          { label: project.name, href: paths.project(project.id) },
          {
            label: 'New Submission',
            href: `${paths.newSubmission(project.id)}?contract=${contract.id}`,
          },
        ]}
      />
      <Divider mb={rem(4)} />
      <SubmissionForm
        onCreate={handleCreateSubmission}
        projectId={project.id}
        submission={undefined}
      />
    </Container>
  );
}

import { modals } from '@mantine/modals';
import { Combobox, Group, InputBase, ThemeIcon, useCombobox, Text } from '@mantine/core';
import { IconBolt, IconArrowNarrowRight, IconChevronDown } from '@tabler/icons-react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { SubmissionRiskLinkTypeEnum, SubmissionStatusEnum } from '@/openapi/requests';
import { RiskStatusBadge } from '@/components/Risks/RiskStatusBadge/RiskStatusBadge';
import classes from './SubmissionStatusSelect.module.css';
import {
  useSubmissionAvailableStatuses,
  useSubmissionRiskCreate,
  useSubmissionRiskLinkCreate,
  useSubmissionUpdateStatus,
} from '@/openapi/queries';
import { ConfirmDuplicateModal } from '../ConfirmDuplicateModal/ConfirmDuplicateModal';

interface StatusOptionProps {
  label: string;
  description: string;
  value: SubmissionStatusEnum;
  className: string;
  icon: JSX.Element;
}

const statusOptions: Record<SubmissionStatusEnum, StatusOptionProps> = {
  DRAFT: {
    label: 'Draft',
    description: 'Mark as Draft',
    value: SubmissionStatusEnum.DRAFT,
    className: 'draft',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  REVIEW: {
    label: 'Review',
    description: 'Mark as Review',
    value: SubmissionStatusEnum.REVIEW,
    className: 'review',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  DUPLICATE: {
    label: 'Duplicate',
    description: 'Mark as Duplicate',
    value: SubmissionStatusEnum.DUPLICATE,
    className: 'duplicate',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  ACCEPTED: {
    label: 'Accepted',
    description: 'Mark as Accepted',
    value: SubmissionStatusEnum.ACCEPTED,
    className: 'accepted',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
} as const;

const StatusOption = ({
  status,
  description,
}: {
  status: SubmissionStatusEnum;
  description: string;
}) => (
  <Group justify="space-between">
    {description}
    <ThemeIcon>
      <IconArrowNarrowRight />
    </ThemeIcon>
    <RiskStatusBadge size="md" status={status} />
  </Group>
);

export const SubmissionStatusSelect = ({
  submissionId,
  status,
  projectId,
}: {
  submissionId: string;
  status: SubmissionStatusEnum;
  projectId: string;
}) => {
  const queryClient = useQueryClient();
  const {
    data: availableStatuses,
    isLoading,
    isError,
  } = useSubmissionAvailableStatuses({
    path: { submissionId },
  });
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<SubmissionStatusEnum>(status);
  const statuses: StatusOptionProps[] =
    availableStatuses?.statuses.map((_status) => statusOptions[_status]) ?? [];
  const duplicatedRiskIdRef = useRef<string>();
  // Object.values(statusOptions).slice(0);

  // Sync selectedStatus with status prop when it changes
  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  const updateMutation = useSubmissionUpdateStatus(undefined, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ refetchType: 'all' });
    },
  });

  const updateSubmissionStatus = async (newStatus: SubmissionStatusEnum) => {
    await updateMutation.mutateAsync({
      path: { submissionId },
      body: {
        status: newStatus,
      },
    });
  };

  const linkCreateMutation = useSubmissionRiskLinkCreate(undefined, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ refetchType: 'all' });
    },
  });

  const createDuplicateLink = async (riskId: string) => {
    await linkCreateMutation.mutateAsync({
      body: {
        linkType: SubmissionRiskLinkTypeEnum.DUPLICATE,
        riskId,
        submissionId,
      },
    });
  };

  const createRisk = useSubmissionRiskCreate(undefined, {
    onError: (error: Error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to create risk',
        message: error.message,
      });
    },
    onSuccess: (data) => {
      const riskId = data.data?.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk created successfully',
      });
      window.open(`/risk/${riskId}`, '_blank');
    },
  });

  const confirmSubmissionAcceptance = () => {
    modals.openConfirmModal({
      title: 'Confirm Submission Acceptance',
      centered: true,
      children: (
        <Text size="sm">
          Accepting the submission will create a new risk for the project. Are you sure you want to
          accept the submission?
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        await createRisk.mutateAsync({ path: { submissionId } });
        await updateSubmissionStatus(SubmissionStatusEnum.ACCEPTED);
        setSelectedStatus(SubmissionStatusEnum.ACCEPTED);
        combobox.closeDropdown();
      },
    });
  };

  const confirmSubmissionDuplicate = () => {
    modals.openConfirmModal({
      title: 'Confirm Submission Duplication',
      centered: true,
      children: (
        <QueryClientProvider client={queryClient}>
          <Text size="sm">
            You are about to mark submission as duplicate. Please choose the risk this submission is
            duplicating.
          </Text>
          <ConfirmDuplicateModal
            submissionId={submissionId}
            projectId={projectId}
            onSelectRisk={(riskId) => {
              duplicatedRiskIdRef.current = riskId;
            }}
          />
        </QueryClientProvider>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        if (!duplicatedRiskIdRef.current) {
          notifications.show({
            message: 'Please choose the risk this submission is duplicating',
            color: 'red',
            title: 'Missing duplicated risk',
          });
          return;
        }
        await createDuplicateLink(duplicatedRiskIdRef.current);
        await updateSubmissionStatus(SubmissionStatusEnum.DUPLICATE);
        setSelectedStatus(SubmissionStatusEnum.DUPLICATE);
        duplicatedRiskIdRef.current = undefined;
        combobox.closeDropdown();
      },
    });
  };

  const handleStatusChange = async (val: string) => {
    const newStatus = val as SubmissionStatusEnum;
    if (selectedStatus === newStatus) {
      combobox.closeDropdown();
      return;
    }

    if (newStatus === SubmissionStatusEnum.ACCEPTED) {
      combobox.closeDropdown();
      confirmSubmissionAcceptance();
      return;
    }

    if (newStatus === SubmissionStatusEnum.DUPLICATE) {
      combobox.closeDropdown();
      // const duplicatedRisks = await queryClient.fetchQuery({
      //   queryKey: UseSubmissionRiskLinkListKeyFn(),
      //   queryFn: () =>
      //     submissionRiskLinkList({
      //       query: { submissionId, linkType: SubmissionRiskLinkTypeEnum.DUPLICATE },
      //     }),
      // });
      // console.log(duplicatedRisks);
      confirmSubmissionDuplicate();
      return;
    }

    await updateSubmissionStatus(newStatus);
    setSelectedStatus(newStatus);
    combobox.closeDropdown();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <></>;
  }

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleStatusChange}
      classNames={{
        dropdown: classes.Dropdown,
      }}
      disabled={statuses.length === 0}
    >
      <Combobox.Target>
        <InputBase
          ref={dropdownTriggerRef}
          component="button"
          type="button"
          onClick={() => {
            combobox.toggleDropdown();
          }}
          pointer
          rightSection={statuses.length > 0 && <IconChevronDown stroke={1.5} size="16px" />}
          rightSectionPointerEvents="none"
          className={clsx(classes[statusOptions[selectedStatus].className])}
          classNames={{
            input: classes.input,
            section: classes.icon,
          }}
          leftSection={statusOptions[selectedStatus].icon}
        >
          {statusOptions[selectedStatus].label}
        </InputBase>
      </Combobox.Target>
      {statuses.length > 0 && (
        <Combobox.Dropdown>
          <Combobox.Options>
            {statuses.map(({ value, description, className }) => (
              <Combobox.Option key={value} value={value} className={classes[className]}>
                <StatusOption status={value} description={description} />
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      )}
    </Combobox>
  );
};

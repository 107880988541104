import { useDisclosure } from '@mantine/hooks';
import { IconColorSwatch, IconDots, IconTrash } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  ColorPicker,
  DEFAULT_THEME,
  Menu,
  Popover,
  ThemeIcon,
} from '@mantine/core';

const ColorSwatchPopover = ({
  selectedColor,
  onColorChange,
}: {
  selectedColor: string;
  onColorChange: (color: string) => void;
}) => {
  const [isPopoverOpen, popoverHandlers] = useDisclosure(false);

  return (
    <Popover
      opened={isPopoverOpen}
      onOpen={popoverHandlers.open}
      onClose={popoverHandlers.close}
      withinPortal={false}
    >
      <Popover.Target>
        <Button
          variant="subtle"
          color="gray"
          onClick={(e) => {
            e.stopPropagation();
            popoverHandlers.toggle();
          }}
          leftSection={
            <ThemeIcon>
              <IconColorSwatch size={14} />
            </ThemeIcon>
          }
        >
          Change Color
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <ColorPicker
          value={selectedColor}
          onChange={onColorChange}
          format="hex"
          withPicker={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          fullWidth
          swatches={[
            ...DEFAULT_THEME.colors.red.slice(6, 10),
            ...DEFAULT_THEME.colors.green.slice(6, 10),
            ...DEFAULT_THEME.colors.blue.slice(6, 10),
            ...DEFAULT_THEME.colors.pink.slice(6, 8),
          ]}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export const TagOptionMenu = ({
  tagColor,
  onColorChange,
  onDeleteTag,
}: {
  tagColor: string;
  onColorChange: (color: string) => void;
  onDeleteTag: () => void;
}) => (
  <Menu withinPortal={false}>
    <Menu.Target>
      <ActionIcon
        variant="subtle"
        onClick={(e) => {
          e.stopPropagation();
        }}
        color="gray"
      >
        <IconDots stroke={1.5} size={14} />
      </ActionIcon>
    </Menu.Target>

    <Menu.Dropdown>
      <ColorSwatchPopover selectedColor={tagColor} onColorChange={onColorChange} />

      <Menu.Item
        color="red"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteTag();
        }}
        leftSection={
          <ThemeIcon>
            <IconTrash stroke={1.5} size={14} color="red" />
          </ThemeIcon>
        }
      >
        Delete
      </Menu.Item>
    </Menu.Dropdown>
  </Menu>
);

import { QueryClient } from '@tanstack/react-query';
import { paths } from '@/configuration';
import { SubmissionLoader } from './NewSubmission.loader';
import { NewSubmissionPage } from './NewSubmission.page';

export const NewSubmissionPath = (queryClient: QueryClient) => ({
  path: paths.newSubmission(':projectId'),
  element: <NewSubmissionPage />,
  loader: SubmissionLoader(queryClient),
});

import { useLoaderData } from 'react-router';
import { notifications } from '@mantine/notifications';

import { Box, Container, Space } from '@mantine/core';
import { RiskForm } from '@/components/Risks/RiskForm/RiskForm';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { RiskDto, RiskUpdateDto } from '@/openapi/requests';
import { useRiskUpdate } from '@/openapi/queries';

interface LoaderProps {
  risk: RiskDto;
}

const EditRiskPage = () => {
  const { risk } = useLoaderData() as LoaderProps;

  const updateRiskMutation = useRiskUpdate(undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to update risk',
        message: (error as Error).message,
      });
    },
    onSuccess: (data) => {
      const riskId = data.data?.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk updated successfully',
      });
      window.location.href = `/risk/${riskId}`;
    },
  });

  const handleUpdateRisk = async (values: RiskUpdateDto) =>
    updateRiskMutation.mutateAsync({
      path: { riskId: risk.id },
      body: values,
    });

  useTrackPageView('edit_risk');

  return (
    <Container>
      <Box mt="lg">
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: 'Project', href: '/projects/' },
            { label: risk.project, href: `/project/${risk.id}` },
            { label: risk.title, href: `/risk/${risk.id}` },
          ]}
        />
      </Box>
      <Space h="sm" />
      <RiskForm edit onUpdate={handleUpdateRisk} risk={risk} />
    </Container>
  );
};

export default EditRiskPage;

import { Tooltip } from '@mantine/core';
import classes from './ProjectCardSubmissions.module.css';

interface ProjectCardSubmissionsProps {
  submissions: number;
}

const ProjectCardSubmissions = ({ submissions }: ProjectCardSubmissionsProps) => {
  if (!submissions) return null;

  return (
    <Tooltip label="Submissions">
      <div className={classes.notificationDot}>{submissions}</div>
    </Tooltip>
  );
};

export default ProjectCardSubmissions;

import { Box, Container, Divider, Flex, Group, Stack, Text } from '@mantine/core';
import RiskStatusSelect from '../RiskStatusSelect/RiskStatusSelect';
import { SeverityStatus } from '@/components/Common';
import RiskSLA from '../RiskSLA/RiskSLA';
import { RiskCategoryBadge } from '../RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '../RiskEnvironmentBadge/RiskEnvironmentBadge';
import { RiskTagsView } from '../RiskTags/RiskTagsView';
import { RiskTagsMenu } from '../RiskTags/RiskTagsMenu';
import { RiskSubmissionsList } from './RiskSubmissionsList';
import { useUserType } from '@/hooks/use-user-type';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { ProjectDto, RiskDto, UserTypeEnum } from '@/openapi/requests';
import If from '@/components/Common/If/If';
import RiskAssign from '../RiskAssign/RiskAssign';
import RiskLink from '../RiskLink/RiskLink';
import classes from './RiskSidebar.module.css';
import { useProjectDetailsRetrieve } from '@/openapi/queries';
import AvatarStack from '@/components/Common/AvatarStack/AvatarStack';

const RiskSidebar = ({ risk, project }: { risk: RiskDto; project: ProjectDto }) => {
  const projectDetailsQuery = useProjectDetailsRetrieve({
    path: { projectId: project.id },
  });

  const userType = useUserType();
  const isResearcher = userType === UserTypeEnum.RESEARCHER;
  if (!projectDetailsQuery.isSuccess && userType !== UserTypeEnum.RESEARCHER) {
    return (
      <Container>
        <Box>Failed to load risk</Box>
      </Container>
    );
  }
  const projectDetails = projectDetailsQuery.data;

  return (
    <Stack justify="start" gap={16}>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <RiskStatusSelect status={risk.status} riskId={risk.id} />
        </Flex>
      </Stack>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <SeverityStatus variant="filled" severity={risk.severity} />
        </Flex>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">SLA</Text>
        <Box>
          <RiskSLA createdDate={risk.created} closedDate={risk.closed} />
        </Box>
      </Stack>
      {!isResearcher && (
        <Stack gap={4}>
          <Text textfor="label">Links</Text>
          <Box>
            <RiskLink risk={risk} projectId={project.id} />
          </Box>
        </Stack>
      )}
      <Stack gap={4} className={classes.hideMobile}>
        <Text textfor="label">Tags</Text>
        <Box>
          <Group>
            <RiskCategoryBadge category={risk.category} />
            <RiskEnvironmentsBadge environment={risk.environment} />
            {!isResearcher && (
              <>
                <Divider orientation="vertical" />
                <RiskTagsView riskId={risk.id} />
                <RiskTagsMenu riskId={risk.id} />
              </>
            )}
          </Group>
        </Box>
      </Stack>
      <If condition={userType === UserTypeEnum.CLIENT}>
        <Stack gap={4}>
          <Text textfor="label">Assignee</Text>
          <Flex>
            <RiskAssign projectId={project.id} risk={risk} />
          </Flex>
        </Stack>
      </If>
      <Stack gap={4}>
        <Text textfor="label">Disclosed</Text>
        <Text textfor="body">
          <DateDisplay showDate date={risk.created} />
        </Text>
      </Stack>
      {risk.closed && (
        <Stack gap={4}>
          <Text textfor="label">Closed</Text>
          <Text textfor="body">
            <DateDisplay showDate date={risk.closed} />
          </Text>
        </Stack>
      )}
      <Stack gap={4}>
        <Text textfor="label">Project</Text>
        <Text textfor="body">{project.name}</Text>
      </Stack>
      <If condition={userType === UserTypeEnum.CLIENT}>
        <Stack gap={4}>
          <Text textfor="label">Team</Text>
          {projectDetails && <AvatarStack members={projectDetails.team} />}
        </Stack>
      </If>
      {userType === UserTypeEnum.TRIAGER && <RiskSubmissionsList riskId={risk.id} />}
    </Stack>
  );
};

export default RiskSidebar;

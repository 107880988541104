import { Container, Space, Title } from '@mantine/core';
import ContractList from '@/components/Contracts/ContractList/ContractList';

import { useTrackPageView } from '@/hooks/use-track-page-view';

export function ContractsPage() {
  useTrackPageView('contracts');

  return (
    <Container>
      <Title order={3} textfor="h1" mt="md">
        Active Contracts
      </Title>
      <Space h="sm" />
      <ContractList />
    </Container>
  );
}

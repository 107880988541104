import { useLoaderData } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

import { Container, Divider, rem } from '@mantine/core';
import { ProjectHeader } from '@/components/Projects/ProjectHeader/ProjectHeader';
import { RiskForm } from '@/components/Risks/RiskForm/RiskForm';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { paths } from '@/configuration';

import type {
  ContractDto,
  ProjectDto,
  UserDto,
  UserTypeEnum,
  RiskCreateDto,
} from '@/openapi/requests';
import { useRiskCreate } from '@/openapi/queries';

interface LoaderProps {
  project: ProjectDto;
  contract: ContractDto;
  user: UserDto;
}

export function NewRisk() {
  const { project, contract, user } = useLoaderData() as LoaderProps;

  const createRisk = useRiskCreate(undefined, {
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Failed to create risk',
        message: (error as Error).message,
      });
    },
    onSuccess: (data) => {
      const riskId = data.data?.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk created successfully',
      });
      window.location.href = `/risk/${riskId}`;
    },
  });

  const handleCreateRisk = async (values: RiskCreateDto) =>
    createRisk.mutateAsync({ body: values });

  useTrackPageView('new_risk');

  return (
    <Container>
      <ProjectHeader
        project={project}
        contract={contract}
        userType={user.user_type as UserTypeEnum}
        breadcrumbs={[
          { label: 'Project', href: paths.projects },
          { label: project.name, href: paths.project(project.id) },
          { label: 'new risk', href: `${paths.newRisk(project.id)}?contract=${contract.id}` },
        ]}
      />
      <Divider mb={rem(4)} />
      <RiskForm
        onCreate={handleCreateRisk}
        risk={{ contract: contract.id, project: contract.project.id }}
      />
    </Container>
  );
}

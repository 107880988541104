import { Paper, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import { ContractCard } from '@/components/Contracts/ContractCard/ContractCard';

import { ContractDto } from '@/openapi/requests';
import { useContractList } from '@/openapi/queries';
import { hasResults } from '@/utils';

interface ContractCardProps {
  projectId?: string;
}

const ContractList = ({ projectId }: ContractCardProps) => {
  const {
    data: contracts,
    isLoading,
    isError,
  } = useContractList({
    path: { projectId },
  });

  if (isError) {
    return <Paper>Error: loading contracts</Paper>;
  }

  if (!hasResults(contracts?.results)) return null;

  return (
    <Stack>
      <Skeleton visible={isLoading}>
        <SimpleGrid
          cols={{
            xs: 1,
            md: 2,
          }}
        >
          {contracts?.results.map((contract: ContractDto) => (
            <ContractCard key={contract.id} contract={contract} />
          ))}
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
};

export default ContractList;

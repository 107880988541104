import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { DateValue } from '@mantine/dates';
import type { SubmissionStatusEnum, CategoryEnum, SeverityEnum } from '@/openapi/requests';

export type DateRange = [DateValue, DateValue];

export type Status =
  | SubmissionStatusEnum.ACCEPTED
  | SubmissionStatusEnum.DRAFT
  | SubmissionStatusEnum.DUPLICATE
  | SubmissionStatusEnum.REVIEW;

interface UseSubmissionFilters {
  categories: CategoryEnum[];
  severities: SeverityEnum[];
  dateRange: DateRange;
  tags: string[];
  status: Status[];
  setStatus: (status: Status[]) => void;
  setCategories: (category: CategoryEnum[]) => void;
  setSeverities: (severity: SeverityEnum[]) => void;
  setDateRange: (dateRange: DateRange) => void;
  setTags: (tags: string[]) => void;
  removeCategory: (category: CategoryEnum) => void;
  removeSeverity: (severity: SeverityEnum) => void;
  removeDateRange: (range: 'from' | 'to') => void;
  removeTag: (tagId: string) => void;
  removeAll: (type?: keyof typeof initialFilters) => void;
  removeStatus: (status: Status) => void;
}

const initialFilters = {
  categories: [] as CategoryEnum[],
  severities: [] as SeverityEnum[],
  dateRange: [null, null] as DateRange,
  tags: [],
  status: [],
};

export const useSubmissionFiltersStore = create(
  persist<UseSubmissionFilters>(
    (set) => ({
      categories: initialFilters.categories,
      severities: initialFilters.severities,
      dateRange: initialFilters.dateRange,
      tags: initialFilters.tags,
      status: initialFilters.status,

      setDateRange: (dateRange) => {
        set({ dateRange });
      },
      setTags: (tags) => {
        set({ tags });
      },
      setStatus: (status) => {
        set({ status });
      },
      setCategories: (categories) => {
        set({ categories });
      },
      setSeverities: (severities) => {
        set({ severities });
      },

      removeDateRange: (range) => {
        set((state) => ({
          dateRange: range === 'from' ? [null, state.dateRange[1]] : [state.dateRange[0], null],
        }));
      },
      removeStatus: (status) => {
        set((state) => ({ status: state.status.filter((s) => s !== status) }));
      },
      removeCategory: (category) => {
        set((state) => ({ categories: state.categories.filter((c) => c !== category) }));
      },
      removeSeverity: (severity) => {
        set((state) => ({ severities: state.severities.filter((s) => s !== severity) }));
      },
      removeTag: (tagId) => {
        set((state) => ({ tags: state.tags.filter((t) => t !== tagId) }));
      },

      removeAll: () => {
        set({
          categories: [],
          severities: [],
          status: [],
          dateRange: [null, null],
          tags: [],
        });
      },
    }),
    {
      name: 'submission-filters',
    }
  )
);

import { Box, Button, Divider, Group, Tabs } from '@mantine/core';
import { useState } from 'react';
import { IconFilter, IconLayoutList } from '@tabler/icons-react';
import { ListLayout } from './ListLayout';
import { ProjectDto } from '@/openapi/requests';
import { SubmissionFilters } from '../SubmissionFilters/SubmissionFilters';
import { useLayout } from '@/components/Risks/RiskList/utils';
import classes from '@/components/Risks/RiskList/RiskList.module.css';

interface SubmissionListProps {
  project: ProjectDto;
}

const SubmissionList = ({ project }: SubmissionListProps) => {
  const layout = useLayout();
  const [showFilter, setShowFilter] = useState(false);
  const [filterStyle, setFilterStyle] = useState({
    overflow: 'hidden',
    maxHeight: '0',
    transition: 'max-height 0.5s ease-in-out',
  });

  const toggleFilter = () => {
    if (!showFilter) {
      setFilterStyle({
        maxHeight: '50px',
        transition: 'max-height 0.5s ease-in-out',
        overflow: 'hidden',
      });
    } else {
      setFilterStyle({
        overflow: 'hidden',
        maxHeight: '0',
        transition: 'max-height 0.5s ease-in-out',
      });
    }

    setShowFilter(!showFilter);
  };

  return (
    <Tabs
      defaultValue={layout}
      classNames={{
        list: classes['mantine-Tabs-list'],
      }}
    >
      <Tabs.List
        styles={{
          list: {
            border: 0,
          },
        }}
        justify="flex-end"
        className={classes.tabList}
      >
        <Group>
          <Tabs.Tab
            key="list"
            value="list"
            leftSection={<IconLayoutList stroke={1.5} size="1rem" />}
          >
            List
          </Tabs.Tab>
        </Group>

        <Group>
          <Button
            onClick={toggleFilter}
            leftSection={<IconFilter stroke={1.5} size="1rem" />}
            mb={5}
            variant={showFilter ? 'light' : 'subtle'}
          >
            {showFilter ? 'Hide' : 'Filter'}
          </Button>
        </Group>
      </Tabs.List>
      <Box style={filterStyle}>
        <SubmissionFilters activeTab={layout} />
        <Divider />
      </Box>
      <Tabs.Panel value="list">
        <ListLayout project={project} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default SubmissionList;

import { json, LoaderFunctionArgs } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
  ensureUseProjectRetrieveData,
  ensureUseRiskRetrieveData,
} from '@/openapi/queries/ensureQueryData';

export const RiskDetailLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { riskId } = params;
    if (!riskId) {
      throw json({ message: 'risk id is required' }, { status: 404 });
    }

    if (riskId === 'undefined') {
      throw json({ message: 'risk id can not be undefined' }, { status: 404 });
    }

    const risk = await ensureUseRiskRetrieveData(queryClient, { path: { riskId } });

    const project = await ensureUseProjectRetrieveData(queryClient, {
      path: { projectId: risk.project },
    });

    return { risk, project };
  };

import { Button, Divider, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { z } from 'zod';
import { useCompany } from '@/hooks/use-company';
import classes from './Settings.module.css';
import { useCompanyListKey, useCompanyUpdate } from '@/openapi/queries';

const companySettingsSchema = z.object({
  companyName: z.string().min(1, 'Company name is required'),
});

type CompanySettingsValues = z.infer<typeof companySettingsSchema>;

export const CompanySettingsForm = () => {
  const queryClient = useQueryClient();
  const company = useCompany();

  const companyUpdate = useCompanyUpdate(undefined, {
    onSuccess: async () => {
      notifications.show({
        title: 'Settings Updated',
        message: 'Successfully updated company settings',
        color: 'green',
      });

      await queryClient.invalidateQueries({
        queryKey: [useCompanyListKey],
      });
    },
    onError: () => {
      notifications.show({
        title: 'Settings Update Failed',
        message: 'Failed to update company settings',
        color: 'red',
      });
    },
  });

  const form = useForm<CompanySettingsValues>({
    validateInputOnChange: true,
    initialValues: {
      companyName: company.name,
    },
    validate: zodResolver(companySettingsSchema),
  });

  const { reset, onSubmit, isValid, isDirty } = form;

  useEffect(() => {
    reset();
  }, [company, reset]);

  const handleSubmit = (values: CompanySettingsValues) => {
    companyUpdate.mutate({
      path: { companyId: company.id },
      body: {
        name: values.companyName,
      },
    });
  };

  return (
    <Stack>
      <SimpleGrid key={`${company.id}-${company.name}`} cols={{ base: 1, md: 2 }}>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Company name"
              placeholder={company.name} // Set the company name as a placeholder
              classNames={classes}
              {...form.getInputProps('companyName')}
            />
            <Group>
              <Button type="submit" disabled={!isDirty() || !isValid() || companyUpdate.isPending}>
                Save
              </Button>
              <Button variant="outline" color="gray" onClick={reset}>
                Cancel
              </Button>
            </Group>
          </Stack>
        </form>
      </SimpleGrid>
      <Divider />
    </Stack>
  );
};

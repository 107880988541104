import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';

import {
  Box,
  Button,
  Card,
  Container,
  Group,
  LoadingOverlay,
  rem,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import {
  RiskSeverityEnum,
  SubmissionCreateDto,
  RiskCategoryEnum,
  EnvironmentTypeEnum,
  SubmissionStatusEnum,
  SubmissionDetailDto,
  SubmissionUpdateDto,
} from '@/openapi/requests';
import {
  RISK_CATEGORY_LABEL_MAP,
  RISK_ENVIRONMENT_LABEL_MAP,
  SEVERITY_LABEL_MAP,
} from '@/constants';
import { MarkdownEditor } from '@/components/Common/Markdown/MarkdownEditor';
import { AttachmentFile } from '@/components/Attachments/AttachmentDisplay/AttachmentFile';
import { AttachmentLoader } from '@/components/Attachments/AttachmentDisplay/AttachmentLoader';
import { isFile } from '@/utils';
import If from '@/components/Common/If/If';
import { SeverityStatus } from '@/components/Common';
import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';
import { MarkdownViewer } from '@/components/Common/Markdown/MarkdownViewer';
import { useCreateNewAttachment } from '@/components/Attachments/create-new-attachment';
import AttachmentUploadButton from '@/components/Attachments/AttachmentUploadButton/AttachmentUploadButton';

interface SubmissionFormProps {
  submission: SubmissionDetailDto | undefined;
  onUpdate?: (values: SubmissionUpdateDto) => Promise<void>;
  onCreate?: (values: SubmissionCreateDto) => Promise<void>;
  edit?: boolean;
  projectId: string;
}

interface CreateSubmissionMutationProps {
  title: string;
  description: string;
  category: RiskCategoryEnum | undefined;
  environment: EnvironmentTypeEnum | undefined;
  severity: RiskSeverityEnum | undefined;
  status: SubmissionStatusEnum | undefined;
  projectId: string;
  attachmentIds: string[];
}

function getEnumOptions<T>(enumType: T) {
  return Object.entries(enumType as string).map(([key, value]) => ({
    value: key,
    label: value,
  }));
}

export function SubmissionForm({
  submission,
  onUpdate,
  onCreate,
  edit,
  projectId,
}: SubmissionFormProps) {
  const navigate = useNavigate();
  const { createAttachment } = useCreateNewAttachment();

  const form = useForm({
    initialValues: {
      title: submission?.title,
      description: submission?.description,
      category: submission?.category,
      environment: submission?.environment,
      severity: submission?.severity,
      status: submission?.status,
      projectId,
      attachmentIds: submission?.attachments ?? [],
    } as CreateSubmissionMutationProps,
    validate: {
      title: (value) => (value ? null : 'Title is required'),
      description: (value) => (value ? null : 'Description is required'),
      category: (value) => (value ? null : 'Category is required'),
      environment: (value) => (value ? null : 'Environment is required'),
      severity: (value) => (value ? null : 'Severity is required'),
    },
  });

  const [isMutating, setIsMutating] = useState(false);
  const [attachments, setAttachments] = useState<(File | string)[]>(form.values.attachmentIds);

  /** Uploads files attachments, Handles both files and IDs.
   *  Returns array of all attachment IDs.
   */
  const uploadAttachments = async () => {
    const attachmentIds: string[] = [];

    const promises = attachments
      .map((attachment) => {
        if (!isFile(attachment)) {
          attachmentIds.push(attachment);
          return null;
        }

        return createAttachment(attachment)
          .then((res) => res.data?.id)
          .catch((err: Error) => {
            showNotification({
              title: 'Error uploading attachment',
              message: err.message,
              color: 'red',
            });
            throw err;
          });
      })
      .filter(Boolean);

    const results = await Promise.allSettled(promises);
    return results
      .filter((r) => r.status === 'fulfilled')
      .map((r) => r.status === 'fulfilled' && r.value)
      .concat(...attachmentIds);
  };

  const handleSubmit = async (
    values: CreateSubmissionMutationProps,
    submissionStatus: SubmissionStatusEnum | undefined
  ) => {
    try {
      setIsMutating(true);
      const isValid = form.validate();
      if (isValid.hasErrors) {
        return;
      }
      const attachmentIds = await uploadAttachments();

      if (edit) {
        if (onUpdate) {
          await onUpdate({
            id: submission!.id,
            title: values.title,
            description: values.description,
            category: values.category,
            environment: values.environment,
            severity: values.severity,
            status: submissionStatus ?? 'DRAFT',
            projectId: values.projectId,
            attachmentIds,
          } as SubmissionCreateDto);
        }
      } else if (onCreate) {
        await onCreate({
          title: values.title,
          description: values.description,
          category: values.category,
          environment: values.environment,
          severity: values.severity,
          status: submissionStatus ?? 'DRAFT',
          projectId: values.projectId,
          attachmentIds,
        } as SubmissionCreateDto);
      }
    } finally {
      setIsMutating(false);
    }
  };

  const isLoading = isMutating;

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Group justify="space-between" mb={12}>
        <Text textfor="h1" mt={12}>
          {edit ? `Edit Submission "${submission?.title}"` : 'Add New Submission'}
        </Text>
      </Group>
      <form
        onSubmit={form.onSubmit(() => {
          // This will prevent the form from actually submitting when the buttons are clicked
          // and we will manually call handleSubmit instead
        })}
      >
        <Stack gap="md">
          <TextInput
            label="Submission Title"
            placeholder="User96 Endpoint is susceptible to XSS"
            withAsterisk
            required
            {...form.getInputProps('title')}
          />
          <Group mb={12}>
            <Select
              label="Severity"
              data={getEnumOptions(SEVERITY_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('severity')}
            />
            <Space />
            <Select
              label="Category"
              data={getEnumOptions(RISK_CATEGORY_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('category')}
            />
            <Space />
            <Select
              label="Environment"
              data={getEnumOptions(RISK_ENVIRONMENT_LABEL_MAP)}
              withAsterisk
              {...form.getInputProps('environment')}
            />
          </Group>
        </Stack>
        <Stack py="sm">
          <Textarea
            label="Description"
            placeholder="Describe the risk. Feel free to use Markdown syntax."
            autosize
            withAsterisk
            renderRoot={() => (
              <>
                <MarkdownEditor
                  value={form.values.description}
                  onChange={(value) => {
                    form.setFieldValue('description', value ?? '');
                  }}
                />
                {form.errors.description && (
                  <p
                    style={{
                      color: 'var(--mantine-color-red-text)',
                      marginTop: '0.5rem',
                      fontSize: '0.875rem',
                    }}
                  >
                    {form.errors.description}
                  </p>
                )}
              </>
            )}
          />

          <Group justify="space-between">
            <AttachmentUploadButton
              onChange={(files) => {
                setAttachments((prev) => [...prev, ...files]);
              }}
            />
            <Group gap="sm">
              <Button variant="outline" type="button" onClick={() => navigate(-1)} size="sm">
                Cancel
              </Button>
              <If condition={edit}>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, submission?.status)}
                >
                  Save
                </Button>
              </If>
              <If condition={!edit}>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, SubmissionStatusEnum.DRAFT)}
                >
                  Save as Draft
                </Button>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => handleSubmit(form.values, SubmissionStatusEnum.REVIEW)}
                >
                  Send for Review
                </Button>
              </If>
            </Group>
          </Group>

          <If condition={attachments.length > 0}>
            <Group gap="sm">
              {attachments.map((attachment) => {
                if (!isFile(attachment)) {
                  return (
                    <AttachmentLoader
                      key={attachment}
                      attachmentId={attachment}
                      onDelete={(attachmentId) => {
                        setAttachments((prev) =>
                          prev.filter((a) => (!isFile(a) ? a !== attachmentId : a))
                        );
                      }}
                    />
                  );
                }

                return (
                  <AttachmentFile
                    key={attachment.name}
                    file={attachment}
                    onDelete={(file) => {
                      setAttachments((prev) =>
                        prev.filter((f) => (isFile(f) ? f.name !== file.name : f))
                      );
                    }}
                  />
                );
              })}
            </Group>
          </If>
        </Stack>
      </form>

      <Container px={0} mt={16} mb={32}>
        <Text textfor="h3">Preview</Text>
        <Card pb={32} mt={16}>
          <Box>
            <Card component={Stack} px={0} gap="sm" bg="none" withBorder={false}>
              <Group justify="space-between">
                <Stack gap={12}>
                  {form.values.title && (
                    <Title order={1} textfor="h1">
                      <Group gap={rem(4)}>{form.values.title}</Group>
                    </Title>
                  )}
                  {form.values.severity && (
                    <SeverityStatus variant="filled" severity={form.values.severity} />
                  )}
                </Stack>
              </Group>
              <Group mt={8}>
                {form.values.category && <RiskCategoryBadge category={form.values.category} />}
                {form.values.environment && (
                  <RiskEnvironmentsBadge environment={form.values.environment} />
                )}
              </Group>
            </Card>
          </Box>
          <MarkdownViewer content={form.values.description} />
        </Card>
      </Container>
    </>
  );
}

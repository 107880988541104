import { QueryClient } from '@tanstack/react-query';
import { paths } from '@/configuration';
import { SubmissionDetailLoader } from './SubmissionDetail.loader';
import { SubmissionDetailPage } from './SubmissionDetail.page';

export const SubmissionDetailPath = (queryClient: QueryClient) => ({
  path: paths.submission(':submissionId'),
  element: <SubmissionDetailPage />,
  loader: SubmissionDetailLoader(queryClient),
});

import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserMeRetrieve } from '@/openapi/queries';
import { StorageKeys } from '@/utils/constants';

const token = localStorage.getItem(StorageKeys.JWT_TOKEN);

export const AuthProvider = () => {
  const navigate = useNavigate();
  const userMe = useUserMeRetrieve(undefined, undefined, {
    enabled: !!token,
    retry: false,
  });

  useEffect(() => {
    if (userMe.isSuccess) {
      navigate('/');
    }
  }, [userMe.isSuccess, navigate]);

  return <Outlet />;
};

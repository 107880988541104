import { useEffect } from 'react';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { IconCircleX } from '@tabler/icons-react';
import { Alert, Button, PasswordInput, TextInput, Stack } from '@mantine/core';
import If from '@/components/Common/If/If';

import { useSignOut } from '@/hooks/use-sign-out';
import { loginSchema } from '@/lib/validations';
import { useCompanyInvitationAcceptExistingUser, useAuthLogin } from '@/openapi/queries';
import { StorageKeys } from '@/utils/constants';

export function ExistingUserInviteForm({
  inviteCode,
  onSuccess,
}: {
  inviteCode: string;
  onSuccess?: () => void;
}) {
  const { signOut } = useSignOut();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: zodResolver(loginSchema),
  });

  const acceptExistingUserInvite = useCompanyInvitationAcceptExistingUser(undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to accept invitation, Please try again',
        message: (error as Error).message,
      });
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });

  const signInMutation = useAuthLogin(undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Sign-in failed',
        message: (error as Error).message,
      });
    },
    onSuccess: (data) => {
      if (data.data?.accessToken) {
        localStorage.setItem(StorageKeys.JWT_TOKEN, JSON.stringify(data.data?.accessToken));
      }

      if (data.data?.refreshToken) {
        localStorage.setItem(StorageKeys.REFRESH_TOKEN, JSON.stringify(data.data?.refreshToken));
      }

      acceptExistingUserInvite.mutate({
        body: { code: inviteCode },
      });
      form.reset();
    },
  });

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = signInMutation.isPending || acceptExistingUserInvite.isPending;

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        signInMutation.mutate({
          body: {
            email: values.email,
            password: values.password,
          },
        });
      })}
    >
      <Stack gap="md">
        <TextInput
          label="Email"
          placeholder="Your Email"
          disabled={isLoading}
          required
          size="md"
          {...form.getInputProps('email')}
        />
        <PasswordInput
          label="Password"
          placeholder="Your Password"
          required
          disabled={isLoading}
          size="md"
          {...form.getInputProps('password')}
        />
      </Stack>

      <If condition={signInMutation.isError}>
        <Alert color="red" icon={<IconCircleX />} title="Sign-in failed" variant="light" mt="sm">
          Sign-in failed
        </Alert>
      </If>

      <Button type="submit" fullWidth loading={isLoading} size="md" mt="xl">
        Sign In and Accept invitation
      </Button>
    </form>
  );
}

import {
  Alert,
  Box,
  Button,
  Group,
  SimpleGrid,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import * as z from 'zod';
import { useQueryClient } from '@tanstack/react-query';
import { useUserMe } from '@/hooks/use-user-me';
import Gravatar from '@/components/Common/Gravatar/Gravatar';
import classes from './Settings.module.css';
import { useUserMeRetrieveKey, useUserRetrieveKey, useUserUpdate } from '@/openapi/queries';

const personalInfoSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email'),
});

type PersonalInfoValues = z.infer<typeof personalInfoSchema>;

export function PersonalInfoForm() {
  const queryClient = useQueryClient();
  const [showAlert, setShowAlert] = useState<boolean | null>(null);

  const user = useUserMe();

  const updateUser = useUserUpdate(undefined, {
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Successfully updated your personal information.',
      });

      await queryClient.invalidateQueries({
        queryKey: [useUserMeRetrieveKey, useUserRetrieveKey],
      });
    },
    onError: () => {
      notifications.show({
        color: 'red',
        title: 'Failure',
        message: 'Failed to update your details. Please try again or contact support.',
      });
    },
  });

  const form = useForm<PersonalInfoValues>({
    validateInputOnChange: true,
    initialValues: {
      name: user.name,
      email: user.email,
    },
    validate: zodResolver(personalInfoSchema),
  });

  const { onSubmit, isValid, isDirty } = form;

  const handleSubmit = (values: PersonalInfoValues) => {
    updateUser.mutate({
      body: {
        name: values.name,
        email: values.email,
      },
    });
  };

  useEffect(() => {
    fetch(`https://www.gravatar.com/avatar/${user.email_sha256}?d=404`)
      .then((response) => {
        setShowAlert(!response.ok);
      })
      .catch(() => {
        setShowAlert(false);
      });
  }, [user.email_sha256]);

  return (
    <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
      <form onSubmit={onSubmit(handleSubmit)}>
        <Stack gap="xs">
          <Group align="center" gap="md" mb="xl">
            <Gravatar emailHash={user.email_sha256} withoutTooltip />
            <Box>
              <Title order={3} textfor="avatar">
                {user.name}
              </Title>
              <Text textfor="info">{user.email}</Text>
            </Box>
          </Group>
          <TextInput
            label="My Name"
            placeholder={user.name}
            classNames={classes}
            {...form.getInputProps('name')}
          />
          <Space />
          <TextInput
            label="My Email"
            placeholder={user.email}
            classNames={classes}
            {...form.getInputProps('email')}
            disabled
          />
          <Space />
          <Group>
            <Button type="submit" disabled={!isDirty() || !isValid() || updateUser.isPending}>
              Update
            </Button>
          </Group>
        </Stack>
      </form>

      {showAlert ? (
        <Box>
          <Alert variant="light" icon={<IconInfoCircleFilled stroke={1.5} size={18} />} p="lg">
            You do not have a profile photo on&nbsp;
            <a href="https://gravatar.com" target="_blank" rel="noreferrer">
              gravatar.com
            </a>
            . Please create/update your Gravatar profile.
          </Alert>
        </Box>
      ) : (
        ''
      )}
    </SimpleGrid>
  );
}

import { MagnifyingGlass } from 'react-loader-spinner';
import { Logo } from '..';
import { UserTypeEnum } from '@/openapi/requests';
import { userTypeTheme } from '@/utils/constants';
import classes from './LoadingScreen.module.css';

interface LoadingScreenProps {
  userType?: UserTypeEnum;
}

const LoadingScreen = ({ userType = UserTypeEnum.CLIENT }: LoadingScreenProps) => (
  <div className={classes.container}>
    <div className={classes.logoContainer}>
      <Logo userType={userType} size="xlarge" />
      <MagnifyingGlass
        visible
        height="80"
        width="80"
        ariaLabel="magnifying-glass-loading"
        wrapperStyle={{}}
        wrapperClass="magnifying-glass-wrapper"
        glassColor="#fff"
        color={userTypeTheme[userType].badgeColor}
      />
    </div>
  </div>
);

export default LoadingScreen;

import { Anchor, Box, Card, Group, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';
import {
  IconCirclePlus,
  IconCopyright,
  IconExternalLink,
  IconParkingCircle,
  IconUnlink,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import {
  useSubmissionRiskLinkCreate,
  useSubmissionRiskLinkDelete,
  useSubmissionRiskLinkList,
} from '@/openapi/queries/queries';
import { SubmissionRiskLinkDetailDto, SubmissionRiskLinkTypeEnum } from '@/openapi/requests';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
import classes from './DuplicatedRiskList.module.css';
import { ConfirmDuplicateModal } from '../ConfirmDuplicateModal/ConfirmDuplicateModal';

export const DuplicatedRiskList = ({
  submissionId,
  projectId,
}: {
  submissionId: string;
  projectId: string;
}) => {
  const queryClient = useQueryClient();
  const [duplicatedRisks, setDuplicatedRisks] = useState<SubmissionRiskLinkDetailDto[]>([]);
  const duplicatedRiskIdRef = useRef<string>();

  const { data: duplicatedRiskData, refetch: refetchDuplicatedRisks } = useSubmissionRiskLinkList({
    query: { submissionId, linkType: SubmissionRiskLinkTypeEnum.DUPLICATE },
  });

  useEffect(() => {
    if (duplicatedRiskData !== undefined) {
      setDuplicatedRisks(duplicatedRiskData);
    }
  }, [duplicatedRiskData]);

  const unlinkRisk = useSubmissionRiskLinkDelete(undefined, {
    onError: (error: unknown) => {
      notifications.show({
        color: 'red',
        title: 'Risk unlink failed',
        message: (error as Error).message,
      });
    },
    onSuccess: async () => {
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk unlinked successfully',
      });
      await refetchDuplicatedRisks();
    },
  });

  const linkCreateMutation = useSubmissionRiskLinkCreate(undefined, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ refetchType: 'all' });
    },
  });

  const createDuplicateLink = async (riskId: string) => {
    await linkCreateMutation.mutateAsync({
      body: {
        linkType: SubmissionRiskLinkTypeEnum.DUPLICATE,
        riskId,
        submissionId,
      },
    });

    await refetchDuplicatedRisks();
  };

  if (duplicatedRisks.length === 0) {
    return null;
  }

  const handleLinkDuplicatedRisk = () => {
    modals.openConfirmModal({
      title: 'Confirm Submission Duplication',
      centered: true,
      children: (
        <QueryClientProvider client={queryClient}>
          <Text size="sm">
            You are about to mark submission as duplicate. Please choose the risk this submission is
            duplicating.
          </Text>
          <ConfirmDuplicateModal
            submissionId={submissionId}
            projectId={projectId}
            onSelectRisk={(riskId) => {
              duplicatedRiskIdRef.current = riskId;
            }}
          />
        </QueryClientProvider>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        if (!duplicatedRiskIdRef.current) {
          notifications.show({
            message: 'Please choose the risk this submission is duplicating',
            color: 'red',
            title: 'Missing duplicated risk',
          });
          return;
        }
        await createDuplicateLink(duplicatedRiskIdRef.current);
      },
    });
  };

  return (
    <Stack gap={4}>
      <Group justify="space-between">
        <Text textfor="label">Duplicated Risks</Text>
        <IconCirclePlus
          size={16}
          onClick={handleLinkDuplicatedRisk}
          style={{ cursor: 'pointer' }}
        />
      </Group>
      <Box>
        <Stack gap={4}>
          <Card withBorder radius="md" className={classes.container}>
            <Stack gap={4}>
              {duplicatedRisks.map((linked_risk) => (
                <Group
                  className={classes.duplicatedRisk}
                  justify="space-between"
                  key={linked_risk.riskId}
                >
                  <Group className={classes.title}>
                    {linked_risk.linkType === SubmissionRiskLinkTypeEnum.PARENTCHILD ? (
                      <IconParkingCircle size={15} />
                    ) : (
                      <IconCopyright size={15} />
                    )}
                    <Anchor
                      component={Link}
                      target="_blank"
                      to={`/risk/${linked_risk.riskId}`}
                      key={linked_risk.riskId}
                    >
                      {linked_risk.riskTitle}
                    </Anchor>
                    <IconExternalLink
                      className={classes.linkIcon}
                      size={15}
                      style={{ cursor: 'pointer' }}
                    />
                  </Group>
                  <ConfirmModal
                    buttonText="Unlink Risk"
                    onConfirm={async () => {
                      await unlinkRisk.mutateAsync({
                        path: {
                          submissionRiskLinkId: linked_risk.id,
                        },
                      });
                    }}
                    title="Confirm Risk Unlink"
                    description="Are you sure you want to unlink this risk?"
                    color="red"
                  >
                    <Group className={classes.unlinkButton}>
                      <IconUnlink size={15} style={{ cursor: 'pointer' }} />
                      <Text color="gray" fz="12px">
                        Unlink
                      </Text>
                    </Group>
                  </ConfirmModal>
                </Group>
              ))}
            </Stack>
          </Card>
        </Stack>
      </Box>
    </Stack>
  );
};

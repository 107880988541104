import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { HeaderMenu } from '@/components/Header/HeaderMenu';
import { AuthenticatedProvider } from '@/Providers/AuthenticatedProvider';
import { AuthProvider } from '@/Providers/AuthProvider';

import NotFoundPage from '@/pages/404/404.page';
import ContractDetailPath from '@/pages/ContractDetail/ContractDetail.path';
import ContractsPath from '@/pages/Contracts/Contracts.path';
import { NewRiskPath } from '@/pages/NewRisk/NewRisk.path';
import { ProjectDetailPath } from '@/pages/ProjectDetail/ProjectDetail.path';
import ProjectsPath from '@/pages/Projects/Projects.path';
import ResetPasswordPage from '@/pages/ResetPassword/ResetPassword.page';
import { RiskDetailPath } from '@/pages/RiskDetail/RiskDetail.path';
import SettingsPath from '@/pages/Settings/Settings.path';
import { EditRiskPath } from '@/pages/EditRisk/EditRisk.path';

import { SignInPage } from '@/pages/SignIn.page';
import { RequestResetPasswordPage } from '@/pages/RequestResetPassword/RequestResetPassword.page';
import { InvitationPage } from '@/pages/invitations/invitation.page';
import { InvitationLoader } from '@/pages/invitations/invitation.loader';

import { paths } from './configuration';
import { SubmissionDetailPath } from './pages/SubmissionDetail/SubmissionDetail.path';
import { EditSubmissionPath } from './pages/EditSubmission/EditSubmission.path';
import { NewSubmissionPath } from './pages/NewSubmission/NewSubmission.path';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';

export function AuthenticatedLayout() {
  return (
    <AuthenticatedProvider>
      <HeaderMenu />
      <Outlet />
    </AuthenticatedProvider>
  );
}

function createAppRouter(queryClient: QueryClient) {
  return createBrowserRouter([
    {
      path: '/auth',
      element: <AuthProvider />,
      children: [
        {
          path: paths.signIn,
          element: <SignInPage />,
        },
        {
          path: paths.requestResetPassword,
          element: <RequestResetPasswordPage />,
        },
      ],
    },
    {
      path: paths.resetPassword,
      element: <ResetPasswordPage />,
    },
    { path: paths.invitation, element: <InvitationPage />, loader: InvitationLoader },
    {
      path: '/',
      element: <AuthenticatedLayout />,
      errorElement: <ErrorFallback />,
      children: [
        ContractDetailPath,
        ContractsPath,
        NewRiskPath(queryClient),
        EditRiskPath(queryClient),
        ProjectDetailPath(queryClient),
        ProjectsPath,
        RiskDetailPath(queryClient),
        SettingsPath,
        NewSubmissionPath(queryClient),
        SubmissionDetailPath(queryClient),
        EditSubmissionPath(queryClient),
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
}

export function Router({ queryClient }: { queryClient: QueryClient }) {
  return <RouterProvider router={createAppRouter(queryClient)} />;
}

import { SubmissionTagBadge } from './SubmissionTagBadge';
import { useSubmissionTagList } from '@/openapi/queries';

interface SubmissionTagsViewProps {
  submissionId: string;
}

export const SubmissionTagsView = ({ submissionId }: SubmissionTagsViewProps) => {
  const { data } = useSubmissionTagList({ path: { submissionId } });

  return data?.map((tag) => (
    <SubmissionTagBadge
      key={tag.id}
      tag={{
        id: tag.id,
        submissionId: tag.submissionId,
        tagId: tag.tagId,
        tagName: tag.tagName,
        tagColor: tag.tagColor,
      }}
    />
  ));
};

// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3

import { type Options } from '@hey-api/client-axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  adminCompanyList,
  adminContractList,
  adminProjectList,
  adminRiskList,
  adminUserList,
  attachmentCreate,
  attachmentPresignedUrlCreate,
  attachmentRetrieve,
  authLogin,
  authLogout,
  commentList,
  companyAddUser,
  companyArchive,
  companyCreate,
  companyDeleteUser,
  companyInvitationAcceptExistingUser,
  companyInvitationAcceptNewUser,
  companyInvitationCreate,
  companyInvitationDelete,
  companyInvitationList,
  companyInvitationResend,
  companyInvitationValidate,
  companyList,
  companyRetrieve,
  companyTagsList,
  companyUpdate,
  contractCreate,
  contractList,
  contractRetrieve,
  contractUpdate,
  emailTemplateList,
  emailTemplateRetrieve,
  emailTemplateUpdate,
  health,
  passwordResetConfirm,
  passwordResetCreate,
  passwordResetValidate,
  projectArchive,
  projectCreate,
  projectDetailsRetrieve,
  projectList,
  projectLogoPresignedUrlCreate,
  projectPartialUpdate,
  projectRetrieve,
  projectSubmissionsStats,
  projectUpdate,
  proposalList,
  refreshToken,
  riskAvailableStatuses,
  riskCommentCreate,
  riskCommentDelete,
  riskCommentPartialUpdate,
  riskCreate,
  riskHistoryList,
  riskLinkCreate,
  riskLinkedRisksList,
  riskList,
  riskPartialUpdate,
  riskRetrieve,
  riskTagCreate,
  riskTagDelete,
  riskTagList,
  riskUnlinkCreate,
  riskUpdate,
  statsCompany,
  statsProject,
  statsRisksProjectsContractsCompanies,
  submissionAvailableStatuses,
  submissionCommentCreate,
  submissionCommentDelete,
  submissionCommentList,
  submissionCommentUpdate,
  submissionCreate,
  submissionList,
  submissionRetrieve,
  submissionRiskCreate,
  submissionRiskLinkCreate,
  submissionRiskLinkDelete,
  submissionRiskLinkList,
  submissionRiskLinkUnlink,
  submissionTagCreate,
  submissionTagDelete,
  submissionTagList,
  submissionUpdate,
  submissionUpdateStatus,
  tagCreate,
  tagDelete,
  tagUpdate,
  unlockRequestApprove,
  unlockRequestCreate,
  unlockRequestList,
  unlockRequestReject,
  userCreate,
  userMeRetrieve,
  userRetrieve,
  userUpdate,
  userUpdateById,
} from '../requests/services.gen';
import {
  AdminCompanyListData,
  AdminCompanyListError,
  AdminContractListData,
  AdminContractListError,
  AdminProjectListData,
  AdminProjectListError,
  AdminRiskListData,
  AdminRiskListError,
  AdminUserListData,
  AdminUserListError,
  AttachmentCreateData,
  AttachmentCreateError,
  AttachmentPresignedUrlCreateData,
  AttachmentPresignedUrlCreateError,
  AttachmentRetrieveData,
  AttachmentRetrieveError,
  AuthLoginData,
  AuthLoginError,
  AuthLogoutError,
  CommentListData,
  CommentListError,
  CompanyAddUserData,
  CompanyAddUserError,
  CompanyArchiveData,
  CompanyArchiveError,
  CompanyCreateData,
  CompanyCreateError,
  CompanyDeleteUserData,
  CompanyDeleteUserError,
  CompanyInvitationAcceptExistingUserData,
  CompanyInvitationAcceptExistingUserError,
  CompanyInvitationAcceptNewUserData,
  CompanyInvitationAcceptNewUserError,
  CompanyInvitationCreateData,
  CompanyInvitationCreateError,
  CompanyInvitationDeleteData,
  CompanyInvitationDeleteError,
  CompanyInvitationListData,
  CompanyInvitationListError,
  CompanyInvitationResendData,
  CompanyInvitationResendError,
  CompanyInvitationValidateData,
  CompanyInvitationValidateError,
  CompanyListError,
  CompanyRetrieveData,
  CompanyRetrieveError,
  CompanyTagsListData,
  CompanyTagsListError,
  CompanyUpdateData,
  CompanyUpdateError,
  ContractCreateData,
  ContractCreateError,
  ContractListData,
  ContractListError,
  ContractRetrieveData,
  ContractRetrieveError,
  ContractUpdateData,
  ContractUpdateError,
  EmailTemplateListData,
  EmailTemplateListError,
  EmailTemplateRetrieveData,
  EmailTemplateRetrieveError,
  EmailTemplateUpdateData,
  EmailTemplateUpdateError,
  HealthError,
  PasswordResetConfirmData,
  PasswordResetConfirmError,
  PasswordResetCreateData,
  PasswordResetCreateError,
  PasswordResetValidateData,
  PasswordResetValidateError,
  ProjectArchiveData,
  ProjectArchiveError,
  ProjectCreateData,
  ProjectCreateError,
  ProjectDetailsRetrieveData,
  ProjectDetailsRetrieveError,
  ProjectListError,
  ProjectLogoPresignedUrlCreateData,
  ProjectLogoPresignedUrlCreateError,
  ProjectPartialUpdateData,
  ProjectPartialUpdateError,
  ProjectRetrieveData,
  ProjectRetrieveError,
  ProjectSubmissionsStatsData,
  ProjectSubmissionsStatsError,
  ProjectUpdateData,
  ProjectUpdateError,
  ProposalListData,
  ProposalListError,
  RefreshTokenData,
  RefreshTokenError,
  RiskAvailableStatusesData,
  RiskAvailableStatusesError,
  RiskCommentCreateData,
  RiskCommentCreateError,
  RiskCommentDeleteData,
  RiskCommentDeleteError,
  RiskCommentPartialUpdateData,
  RiskCommentPartialUpdateError,
  RiskCreateData,
  RiskCreateError,
  RiskHistoryListData,
  RiskHistoryListError,
  RiskLinkCreateData,
  RiskLinkCreateError,
  RiskLinkedRisksListData,
  RiskLinkedRisksListError,
  RiskListData,
  RiskListError,
  RiskPartialUpdateData,
  RiskPartialUpdateError,
  RiskRetrieveData,
  RiskRetrieveError,
  RiskTagCreateData,
  RiskTagCreateError,
  RiskTagDeleteData,
  RiskTagDeleteError,
  RiskTagListData,
  RiskTagListError,
  RiskUnlinkCreateData,
  RiskUnlinkCreateError,
  RiskUpdateData,
  RiskUpdateError,
  StatsCompanyData,
  StatsCompanyError,
  StatsProjectData,
  StatsProjectError,
  StatsRisksProjectsContractsCompaniesError,
  SubmissionAvailableStatusesData,
  SubmissionAvailableStatusesError,
  SubmissionCommentCreateData,
  SubmissionCommentCreateError,
  SubmissionCommentDeleteData,
  SubmissionCommentDeleteError,
  SubmissionCommentListData,
  SubmissionCommentListError,
  SubmissionCommentUpdateData,
  SubmissionCommentUpdateError,
  SubmissionCreateData,
  SubmissionCreateError,
  SubmissionListData,
  SubmissionListError,
  SubmissionRetrieveData,
  SubmissionRetrieveError,
  SubmissionRiskCreateData,
  SubmissionRiskCreateError,
  SubmissionRiskLinkCreateData,
  SubmissionRiskLinkCreateError,
  SubmissionRiskLinkDeleteData,
  SubmissionRiskLinkDeleteError,
  SubmissionRiskLinkListData,
  SubmissionRiskLinkListError,
  SubmissionRiskLinkUnlinkData,
  SubmissionRiskLinkUnlinkError,
  SubmissionTagCreateData,
  SubmissionTagCreateError,
  SubmissionTagDeleteData,
  SubmissionTagDeleteError,
  SubmissionTagListData,
  SubmissionTagListError,
  SubmissionUpdateData,
  SubmissionUpdateError,
  SubmissionUpdateStatusData,
  SubmissionUpdateStatusError,
  TagCreateData,
  TagCreateError,
  TagDeleteData,
  TagDeleteError,
  TagUpdateData,
  TagUpdateError,
  UnlockRequestApproveData,
  UnlockRequestApproveError,
  UnlockRequestCreateData,
  UnlockRequestCreateError,
  UnlockRequestListData,
  UnlockRequestListError,
  UnlockRequestRejectData,
  UnlockRequestRejectError,
  UserCreateData,
  UserCreateError,
  UserMeRetrieveError,
  UserRetrieveData,
  UserRetrieveError,
  UserUpdateByIdData,
  UserUpdateByIdError,
  UserUpdateData,
  UserUpdateError,
} from '../requests/types.gen';
import * as Common from './common';
export const useHealth = <
  TData = Common.HealthDefaultResponse,
  TError = AxiosError<HealthError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseHealthKeyFn(clientOptions, queryKey),
    queryFn: () => health({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useUserMeRetrieve = <
  TData = Common.UserMeRetrieveDefaultResponse,
  TError = AxiosError<UserMeRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseUserMeRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      userMeRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAdminUserList = <
  TData = Common.AdminUserListDefaultResponse,
  TError = AxiosError<AdminUserListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminUserListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAdminUserListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      adminUserList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useUserRetrieve = <
  TData = Common.UserRetrieveDefaultResponse,
  TError = AxiosError<UserRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<UserRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseUserRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      userRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useCompanyList = <
  TData = Common.CompanyListDefaultResponse,
  TError = AxiosError<CompanyListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseCompanyListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      companyList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAdminCompanyList = <
  TData = Common.AdminCompanyListDefaultResponse,
  TError = AxiosError<AdminCompanyListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminCompanyListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAdminCompanyListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      adminCompanyList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useCompanyTagsList = <
  TData = Common.CompanyTagsListDefaultResponse,
  TError = AxiosError<CompanyTagsListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<CompanyTagsListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseCompanyTagsListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      companyTagsList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useCompanyRetrieve = <
  TData = Common.CompanyRetrieveDefaultResponse,
  TError = AxiosError<CompanyRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<CompanyRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseCompanyRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      companyRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useCompanyInvitationList = <
  TData = Common.CompanyInvitationListDefaultResponse,
  TError = AxiosError<CompanyInvitationListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<CompanyInvitationListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseCompanyInvitationListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      companyInvitationList({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useProjectList = <
  TData = Common.ProjectListDefaultResponse,
  TError = AxiosError<ProjectListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseProjectListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      projectList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAdminProjectList = <
  TData = Common.AdminProjectListDefaultResponse,
  TError = AxiosError<AdminProjectListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminProjectListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAdminProjectListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      adminProjectList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useProjectRetrieve = <
  TData = Common.ProjectRetrieveDefaultResponse,
  TError = AxiosError<ProjectRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ProjectRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseProjectRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      projectRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useProjectDetailsRetrieve = <
  TData = Common.ProjectDetailsRetrieveDefaultResponse,
  TError = AxiosError<ProjectDetailsRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ProjectDetailsRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseProjectDetailsRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      projectDetailsRetrieve({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useProjectSubmissionsStats = <
  TData = Common.ProjectSubmissionsStatsDefaultResponse,
  TError = AxiosError<ProjectSubmissionsStatsError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ProjectSubmissionsStatsData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseProjectSubmissionsStatsKeyFn(clientOptions, queryKey),
    queryFn: () =>
      projectSubmissionsStats({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useRiskList = <
  TData = Common.RiskListDefaultResponse,
  TError = AxiosError<RiskListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAdminRiskList = <
  TData = Common.AdminRiskListDefaultResponse,
  TError = AxiosError<AdminRiskListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminRiskListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAdminRiskListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      adminRiskList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useUnlockRequestList = <
  TData = Common.UnlockRequestListDefaultResponse,
  TError = AxiosError<UnlockRequestListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<UnlockRequestListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseUnlockRequestListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      unlockRequestList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useRiskTagList = <
  TData = Common.RiskTagListDefaultResponse,
  TError = AxiosError<RiskTagListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskTagListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskTagListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskTagList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useRiskRetrieve = <
  TData = Common.RiskRetrieveDefaultResponse,
  TError = AxiosError<RiskRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useRiskHistoryList = <
  TData = Common.RiskHistoryListDefaultResponse,
  TError = AxiosError<RiskHistoryListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskHistoryListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskHistoryListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskHistoryList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useRiskLinkedRisksList = <
  TData = Common.RiskLinkedRisksListDefaultResponse,
  TError = AxiosError<RiskLinkedRisksListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskLinkedRisksListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskLinkedRisksListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskLinkedRisksList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useCommentList = <
  TData = Common.CommentListDefaultResponse,
  TError = AxiosError<CommentListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<CommentListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseCommentListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      commentList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useRiskAvailableStatuses = <
  TData = Common.RiskAvailableStatusesDefaultResponse,
  TError = AxiosError<RiskAvailableStatusesError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<RiskAvailableStatusesData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseRiskAvailableStatusesKeyFn(clientOptions, queryKey),
    queryFn: () =>
      riskAvailableStatuses({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useContractList = <
  TData = Common.ContractListDefaultResponse,
  TError = AxiosError<ContractListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ContractListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseContractListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      contractList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAdminContractList = <
  TData = Common.AdminContractListDefaultResponse,
  TError = AxiosError<AdminContractListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AdminContractListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAdminContractListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      adminContractList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useContractRetrieve = <
  TData = Common.ContractRetrieveDefaultResponse,
  TError = AxiosError<ContractRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ContractRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseContractRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      contractRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useProposalList = <
  TData = Common.ProposalListDefaultResponse,
  TError = AxiosError<ProposalListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<ProposalListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseProposalListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      proposalList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAttachmentRetrieve = <
  TData = Common.AttachmentRetrieveDefaultResponse,
  TError = AxiosError<AttachmentRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<AttachmentRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseAttachmentRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      attachmentRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useStatsRisksProjectsContractsCompanies = <
  TData = Common.StatsRisksProjectsContractsCompaniesDefaultResponse,
  TError = AxiosError<StatsRisksProjectsContractsCompaniesError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<unknown, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatsRisksProjectsContractsCompaniesKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statsRisksProjectsContractsCompanies({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useStatsCompany = <
  TData = Common.StatsCompanyDefaultResponse,
  TError = AxiosError<StatsCompanyError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<StatsCompanyData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatsCompanyKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statsCompany({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useStatsProject = <
  TData = Common.StatsProjectDefaultResponse,
  TError = AxiosError<StatsProjectError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<StatsProjectData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatsProjectKeyFn(clientOptions, queryKey),
    queryFn: () =>
      statsProject({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useEmailTemplateList = <
  TData = Common.EmailTemplateListDefaultResponse,
  TError = AxiosError<EmailTemplateListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<EmailTemplateListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseEmailTemplateListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      emailTemplateList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useEmailTemplateRetrieve = <
  TData = Common.EmailTemplateRetrieveDefaultResponse,
  TError = AxiosError<EmailTemplateRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<EmailTemplateRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseEmailTemplateRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      emailTemplateRetrieve({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useSubmissionList = <
  TData = Common.SubmissionListDefaultResponse,
  TError = AxiosError<SubmissionListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useSubmissionRetrieve = <
  TData = Common.SubmissionRetrieveDefaultResponse,
  TError = AxiosError<SubmissionRetrieveError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionRetrieveData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionRetrieveKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionRetrieve({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useSubmissionAvailableStatuses = <
  TData = Common.SubmissionAvailableStatusesDefaultResponse,
  TError = AxiosError<SubmissionAvailableStatusesError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionAvailableStatusesData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionAvailableStatusesKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionAvailableStatuses({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useSubmissionRiskLinkList = <
  TData = Common.SubmissionRiskLinkListDefaultResponse,
  TError = AxiosError<SubmissionRiskLinkListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionRiskLinkListData, true> = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionRiskLinkListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionRiskLinkList({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useSubmissionCommentList = <
  TData = Common.SubmissionCommentListDefaultResponse,
  TError = AxiosError<SubmissionCommentListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionCommentListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionCommentListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionCommentList({ ...clientOptions }).then(
        (response) => response.data as TData
      ) as TData,
    ...options,
  });
export const useSubmissionTagList = <
  TData = Common.SubmissionTagListDefaultResponse,
  TError = AxiosError<SubmissionTagListError>,
  TQueryKey extends Array<unknown> = unknown[],
>(
  clientOptions: Options<SubmissionTagListData, true>,
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseSubmissionTagListKeyFn(clientOptions, queryKey),
    queryFn: () =>
      submissionTagList({ ...clientOptions }).then((response) => response.data as TData) as TData,
    ...options,
  });
export const useAuthLogin = <
  TData = Common.AuthLoginMutationResult,
  TError = AxiosError<AuthLoginError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<AuthLoginData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<AuthLoginData, true>, TContext>({
    mutationKey: Common.UseAuthLoginKeyFn(mutationKey),
    mutationFn: (clientOptions) => authLogin(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useAuthLogout = <
  TData = Common.AuthLogoutMutationResult,
  TError = AxiosError<AuthLogoutError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<unknown, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<unknown, true>, TContext>({
    mutationKey: Common.UseAuthLogoutKeyFn(mutationKey),
    mutationFn: (clientOptions) => authLogout(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePasswordResetCreate = <
  TData = Common.PasswordResetCreateMutationResult,
  TError = AxiosError<PasswordResetCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PasswordResetCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<PasswordResetCreateData, true>, TContext>({
    mutationKey: Common.UsePasswordResetCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => passwordResetCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePasswordResetValidate = <
  TData = Common.PasswordResetValidateMutationResult,
  TError = AxiosError<PasswordResetValidateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PasswordResetValidateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<PasswordResetValidateData, true>, TContext>({
    mutationKey: Common.UsePasswordResetValidateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      passwordResetValidate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const usePasswordResetConfirm = <
  TData = Common.PasswordResetConfirmMutationResult,
  TError = AxiosError<PasswordResetConfirmError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<PasswordResetConfirmData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<PasswordResetConfirmData, true>, TContext>({
    mutationKey: Common.UsePasswordResetConfirmKeyFn(mutationKey),
    mutationFn: (clientOptions) => passwordResetConfirm(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRefreshToken = <
  TData = Common.RefreshTokenMutationResult,
  TError = AxiosError<RefreshTokenError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RefreshTokenData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RefreshTokenData, true>, TContext>({
    mutationKey: Common.UseRefreshTokenKeyFn(mutationKey),
    mutationFn: (clientOptions) => refreshToken(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUserCreate = <
  TData = Common.UserCreateMutationResult,
  TError = AxiosError<UserCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UserCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UserCreateData, true>, TContext>({
    mutationKey: Common.UseUserCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => userCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyCreate = <
  TData = Common.CompanyCreateMutationResult,
  TError = AxiosError<CompanyCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyCreateData, true>, TContext>({
    mutationKey: Common.UseCompanyCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => companyCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationCreate = <
  TData = Common.CompanyInvitationCreateMutationResult,
  TError = AxiosError<CompanyInvitationCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyInvitationCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationCreateData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationValidate = <
  TData = Common.CompanyInvitationValidateMutationResult,
  TError = AxiosError<CompanyInvitationValidateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyInvitationValidateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationValidateData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationValidateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationValidate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationAcceptNewUser = <
  TData = Common.CompanyInvitationAcceptNewUserMutationResult,
  TError = AxiosError<CompanyInvitationAcceptNewUserError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyInvitationAcceptNewUserData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationAcceptNewUserData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationAcceptNewUserKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationAcceptNewUser(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationAcceptExistingUser = <
  TData = Common.CompanyInvitationAcceptExistingUserMutationResult,
  TError = AxiosError<CompanyInvitationAcceptExistingUserError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      Options<CompanyInvitationAcceptExistingUserData, true>,
      TContext
    >,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationAcceptExistingUserData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationAcceptExistingUserKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationAcceptExistingUser(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationResend = <
  TData = Common.CompanyInvitationResendMutationResult,
  TError = AxiosError<CompanyInvitationResendError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyInvitationResendData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationResendData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationResendKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationResend(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyAddUser = <
  TData = Common.CompanyAddUserMutationResult,
  TError = AxiosError<CompanyAddUserError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyAddUserData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyAddUserData, true>, TContext>({
    mutationKey: Common.UseCompanyAddUserKeyFn(mutationKey),
    mutationFn: (clientOptions) => companyAddUser(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useProjectCreate = <
  TData = Common.ProjectCreateMutationResult,
  TError = AxiosError<ProjectCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ProjectCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ProjectCreateData, true>, TContext>({
    mutationKey: Common.UseProjectCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => projectCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useProjectLogoPresignedUrlCreate = <
  TData = Common.ProjectLogoPresignedUrlCreateMutationResult,
  TError = AxiosError<ProjectLogoPresignedUrlCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ProjectLogoPresignedUrlCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ProjectLogoPresignedUrlCreateData, true>, TContext>({
    mutationKey: Common.UseProjectLogoPresignedUrlCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      projectLogoPresignedUrlCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskCreate = <
  TData = Common.RiskCreateMutationResult,
  TError = AxiosError<RiskCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskCreateData, true>, TContext>({
    mutationKey: Common.UseRiskCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUnlockRequestCreate = <
  TData = Common.UnlockRequestCreateMutationResult,
  TError = AxiosError<UnlockRequestCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UnlockRequestCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UnlockRequestCreateData, true>, TContext>({
    mutationKey: Common.UseUnlockRequestCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => unlockRequestCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskLinkCreate = <
  TData = Common.RiskLinkCreateMutationResult,
  TError = AxiosError<RiskLinkCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskLinkCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskLinkCreateData, true>, TContext>({
    mutationKey: Common.UseRiskLinkCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskLinkCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskUnlinkCreate = <
  TData = Common.RiskUnlinkCreateMutationResult,
  TError = AxiosError<RiskUnlinkCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskUnlinkCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskUnlinkCreateData, true>, TContext>({
    mutationKey: Common.UseRiskUnlinkCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskUnlinkCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskCommentCreate = <
  TData = Common.RiskCommentCreateMutationResult,
  TError = AxiosError<RiskCommentCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskCommentCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskCommentCreateData, true>, TContext>({
    mutationKey: Common.UseRiskCommentCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskCommentCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useContractCreate = <
  TData = Common.ContractCreateMutationResult,
  TError = AxiosError<ContractCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ContractCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ContractCreateData, true>, TContext>({
    mutationKey: Common.UseContractCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => contractCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useTagCreate = <
  TData = Common.TagCreateMutationResult,
  TError = AxiosError<TagCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<TagCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<TagCreateData, true>, TContext>({
    mutationKey: Common.UseTagCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => tagCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskTagCreate = <
  TData = Common.RiskTagCreateMutationResult,
  TError = AxiosError<RiskTagCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskTagCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskTagCreateData, true>, TContext>({
    mutationKey: Common.UseRiskTagCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskTagCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useAttachmentPresignedUrlCreate = <
  TData = Common.AttachmentPresignedUrlCreateMutationResult,
  TError = AxiosError<AttachmentPresignedUrlCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<AttachmentPresignedUrlCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<AttachmentPresignedUrlCreateData, true>, TContext>({
    mutationKey: Common.UseAttachmentPresignedUrlCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      attachmentPresignedUrlCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useAttachmentCreate = <
  TData = Common.AttachmentCreateMutationResult,
  TError = AxiosError<AttachmentCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<AttachmentCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<AttachmentCreateData, true>, TContext>({
    mutationKey: Common.UseAttachmentCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => attachmentCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionCreate = <
  TData = Common.SubmissionCreateMutationResult,
  TError = AxiosError<SubmissionCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionCreateData, true>, TContext>({
    mutationKey: Common.UseSubmissionCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => submissionCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionRiskCreate = <
  TData = Common.SubmissionRiskCreateMutationResult,
  TError = AxiosError<SubmissionRiskCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionRiskCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionRiskCreateData, true>, TContext>({
    mutationKey: Common.UseSubmissionRiskCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => submissionRiskCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionRiskLinkCreate = <
  TData = Common.SubmissionRiskLinkCreateMutationResult,
  TError = AxiosError<SubmissionRiskLinkCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionRiskLinkCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionRiskLinkCreateData, true>, TContext>({
    mutationKey: Common.UseSubmissionRiskLinkCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionRiskLinkCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionCommentCreate = <
  TData = Common.SubmissionCommentCreateMutationResult,
  TError = AxiosError<SubmissionCommentCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionCommentCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionCommentCreateData, true>, TContext>({
    mutationKey: Common.UseSubmissionCommentCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionCommentCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionTagCreate = <
  TData = Common.SubmissionTagCreateMutationResult,
  TError = AxiosError<SubmissionTagCreateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionTagCreateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionTagCreateData, true>, TContext>({
    mutationKey: Common.UseSubmissionTagCreateKeyFn(mutationKey),
    mutationFn: (clientOptions) => submissionTagCreate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUserUpdateById = <
  TData = Common.UserUpdateByIdMutationResult,
  TError = AxiosError<UserUpdateByIdError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UserUpdateByIdData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UserUpdateByIdData, true>, TContext>({
    mutationKey: Common.UseUserUpdateByIdKeyFn(mutationKey),
    mutationFn: (clientOptions) => userUpdateById(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUserUpdate = <
  TData = Common.UserUpdateMutationResult,
  TError = AxiosError<UserUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UserUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UserUpdateData, true>, TContext>({
    mutationKey: Common.UseUserUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => userUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyUpdate = <
  TData = Common.CompanyUpdateMutationResult,
  TError = AxiosError<CompanyUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyUpdateData, true>, TContext>({
    mutationKey: Common.UseCompanyUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => companyUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyArchive = <
  TData = Common.CompanyArchiveMutationResult,
  TError = AxiosError<CompanyArchiveError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyArchiveData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyArchiveData, true>, TContext>({
    mutationKey: Common.UseCompanyArchiveKeyFn(mutationKey),
    mutationFn: (clientOptions) => companyArchive(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useProjectUpdate = <
  TData = Common.ProjectUpdateMutationResult,
  TError = AxiosError<ProjectUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ProjectUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ProjectUpdateData, true>, TContext>({
    mutationKey: Common.UseProjectUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => projectUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useProjectArchive = <
  TData = Common.ProjectArchiveMutationResult,
  TError = AxiosError<ProjectArchiveError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ProjectArchiveData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ProjectArchiveData, true>, TContext>({
    mutationKey: Common.UseProjectArchiveKeyFn(mutationKey),
    mutationFn: (clientOptions) => projectArchive(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskUpdate = <
  TData = Common.RiskUpdateMutationResult,
  TError = AxiosError<RiskUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskUpdateData, true>, TContext>({
    mutationKey: Common.UseRiskUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUnlockRequestApprove = <
  TData = Common.UnlockRequestApproveMutationResult,
  TError = AxiosError<UnlockRequestApproveError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UnlockRequestApproveData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UnlockRequestApproveData, true>, TContext>({
    mutationKey: Common.UseUnlockRequestApproveKeyFn(mutationKey),
    mutationFn: (clientOptions) => unlockRequestApprove(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useUnlockRequestReject = <
  TData = Common.UnlockRequestRejectMutationResult,
  TError = AxiosError<UnlockRequestRejectError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<UnlockRequestRejectData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<UnlockRequestRejectData, true>, TContext>({
    mutationKey: Common.UseUnlockRequestRejectKeyFn(mutationKey),
    mutationFn: (clientOptions) => unlockRequestReject(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useContractUpdate = <
  TData = Common.ContractUpdateMutationResult,
  TError = AxiosError<ContractUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ContractUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ContractUpdateData, true>, TContext>({
    mutationKey: Common.UseContractUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => contractUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useTagUpdate = <
  TData = Common.TagUpdateMutationResult,
  TError = AxiosError<TagUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<TagUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<TagUpdateData, true>, TContext>({
    mutationKey: Common.UseTagUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => tagUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useEmailTemplateUpdate = <
  TData = Common.EmailTemplateUpdateMutationResult,
  TError = AxiosError<EmailTemplateUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<EmailTemplateUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<EmailTemplateUpdateData, true>, TContext>({
    mutationKey: Common.UseEmailTemplateUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => emailTemplateUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionUpdateStatus = <
  TData = Common.SubmissionUpdateStatusMutationResult,
  TError = AxiosError<SubmissionUpdateStatusError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionUpdateStatusData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionUpdateStatusData, true>, TContext>({
    mutationKey: Common.UseSubmissionUpdateStatusKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionUpdateStatus(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionUpdate = <
  TData = Common.SubmissionUpdateMutationResult,
  TError = AxiosError<SubmissionUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionUpdateData, true>, TContext>({
    mutationKey: Common.UseSubmissionUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => submissionUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionCommentUpdate = <
  TData = Common.SubmissionCommentUpdateMutationResult,
  TError = AxiosError<SubmissionCommentUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionCommentUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionCommentUpdateData, true>, TContext>({
    mutationKey: Common.UseSubmissionCommentUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionCommentUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useProjectPartialUpdate = <
  TData = Common.ProjectPartialUpdateMutationResult,
  TError = AxiosError<ProjectPartialUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<ProjectPartialUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<ProjectPartialUpdateData, true>, TContext>({
    mutationKey: Common.UseProjectPartialUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => projectPartialUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskPartialUpdate = <
  TData = Common.RiskPartialUpdateMutationResult,
  TError = AxiosError<RiskPartialUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskPartialUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskPartialUpdateData, true>, TContext>({
    mutationKey: Common.UseRiskPartialUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskPartialUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskCommentPartialUpdate = <
  TData = Common.RiskCommentPartialUpdateMutationResult,
  TError = AxiosError<RiskCommentPartialUpdateError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskCommentPartialUpdateData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskCommentPartialUpdateData, true>, TContext>({
    mutationKey: Common.UseRiskCommentPartialUpdateKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      riskCommentPartialUpdate(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyInvitationDelete = <
  TData = Common.CompanyInvitationDeleteMutationResult,
  TError = AxiosError<CompanyInvitationDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyInvitationDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyInvitationDeleteData, true>, TContext>({
    mutationKey: Common.UseCompanyInvitationDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      companyInvitationDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useCompanyDeleteUser = <
  TData = Common.CompanyDeleteUserMutationResult,
  TError = AxiosError<CompanyDeleteUserError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<CompanyDeleteUserData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<CompanyDeleteUserData, true>, TContext>({
    mutationKey: Common.UseCompanyDeleteUserKeyFn(mutationKey),
    mutationFn: (clientOptions) => companyDeleteUser(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskCommentDelete = <
  TData = Common.RiskCommentDeleteMutationResult,
  TError = AxiosError<RiskCommentDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskCommentDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskCommentDeleteData, true>, TContext>({
    mutationKey: Common.UseRiskCommentDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskCommentDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useTagDelete = <
  TData = Common.TagDeleteMutationResult,
  TError = AxiosError<TagDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<TagDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<TagDeleteData, true>, TContext>({
    mutationKey: Common.UseTagDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) => tagDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useRiskTagDelete = <
  TData = Common.RiskTagDeleteMutationResult,
  TError = AxiosError<RiskTagDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<RiskTagDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<RiskTagDeleteData, true>, TContext>({
    mutationKey: Common.UseRiskTagDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) => riskTagDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionRiskLinkDelete = <
  TData = Common.SubmissionRiskLinkDeleteMutationResult,
  TError = AxiosError<SubmissionRiskLinkDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionRiskLinkDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionRiskLinkDeleteData, true>, TContext>({
    mutationKey: Common.UseSubmissionRiskLinkDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionRiskLinkDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionRiskLinkUnlink = <
  TData = Common.SubmissionRiskLinkUnlinkMutationResult,
  TError = AxiosError<SubmissionRiskLinkUnlinkError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionRiskLinkUnlinkData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionRiskLinkUnlinkData, true>, TContext>({
    mutationKey: Common.UseSubmissionRiskLinkUnlinkKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionRiskLinkUnlink(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionCommentDelete = <
  TData = Common.SubmissionCommentDeleteMutationResult,
  TError = AxiosError<SubmissionCommentDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionCommentDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionCommentDeleteData, true>, TContext>({
    mutationKey: Common.UseSubmissionCommentDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) =>
      submissionCommentDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });
export const useSubmissionTagDelete = <
  TData = Common.SubmissionTagDeleteMutationResult,
  TError = AxiosError<SubmissionTagDeleteError>,
  TQueryKey extends Array<unknown> = unknown[],
  TContext = unknown,
>(
  mutationKey?: TQueryKey,
  options?: Omit<
    UseMutationOptions<TData, TError, Options<SubmissionTagDeleteData, true>, TContext>,
    'mutationKey' | 'mutationFn'
  >
) =>
  useMutation<TData, TError, Options<SubmissionTagDeleteData, true>, TContext>({
    mutationKey: Common.UseSubmissionTagDeleteKeyFn(mutationKey),
    mutationFn: (clientOptions) => submissionTagDelete(clientOptions) as unknown as Promise<TData>,
    ...options,
  });

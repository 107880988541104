import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { DragDropContext } from '@hello-pangea/dnd';
import { useDebouncedValue } from '@mantine/hooks';

import { Box, Divider, Group, ScrollArea } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { getOnDragEnd } from '@/components/Risks/RiskList/getOnDragEnd';
import { KanbanColumn } from '@/components/Risks/RiskList/KanbanColumn';
import { RiskBoard } from '@/components/Risks/RiskList/RiskBoard';
import { KanbanRiskColumn } from '@/components/Risks/RiskList/utils';
import If from '@/components/Common/If/If';

import { useRiskFilters } from '@/store/risk-store';
import { debounceDuration } from '@/configuration';

import { RiskStatusEnum, type ProjectDto } from '@/openapi/requests';
import { confettiStore } from '@/store/confetti-store';
import { useRiskList, useRiskListKey, useRiskPartialUpdate } from '@/openapi/queries';

interface KanbanLayoutProps {
  project: ProjectDto;
}

export const KanbanLayout = ({ project }: KanbanLayoutProps) => {
  const queryClient = useQueryClient();
  const { categories, severities, dateRange, tags, hideRejected, assignee } = useRiskFilters();
  const { toggleShoot } = confettiStore();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;

  // TODO: pagination
  const { data, isLoading, isError } = useRiskList({
    query: {
      project: project.id,
      assignee: debAssignee,
      category: debCategories,
      created_after: date_range_from,
      created_before: date_range_to,
      severity: debSeverities,
      status: undefined,
      tag: debTags,
      page: undefined,
      pageSize: undefined,
      sortBy: undefined,
      sortOrder: undefined,
    },
  });

  const [columns, setColumns] = useState(
    new RiskBoard(
      data?.results
        .filter((risk) => ['NEW', 'IN_PROGRESS', 'CLOSED', 'REJECTED'].includes(risk.status))
        .filter((risk) => (hideRejected ? risk.status !== RiskStatusEnum.REJECTED : true))
    ).items
  );

  useEffect(() => {
    const riskBoard = new RiskBoard(
      data?.results.filter((risk) =>
        hideRejected ? risk.status !== RiskStatusEnum.REJECTED : true
      )
    );
    setColumns(riskBoard.items);
  }, [data?.results, hideRejected]);

  const riskMutation = useRiskPartialUpdate(undefined, {
    onSuccess: async (riskData) => {
      if (riskData.data?.status === RiskStatusEnum.CLOSED) {
        toggleShoot(true);
      }

      await queryClient.invalidateQueries({
        queryKey: [useRiskListKey],
      });
    },
    onError: async (error) => {
      notifications.show({
        title: 'Failed to update risk',
        message: error.message,
        color: 'red',
      });
      await queryClient.invalidateQueries({
        queryKey: [useRiskListKey],
      });
    },
  });

  if (isError) {
    return <div>Error!</div>;
  }

  return (
    <Box>
      <Divider />
      <ScrollArea>
        <DragDropContext onDragEnd={getOnDragEnd(columns, setColumns, riskMutation.mutate)}>
          <If condition={!isLoading} fallback={<div>Loading...</div>}>
            <Group align="flex-start" wrap="nowrap">
              {KanbanRiskColumn.map(({ value, label }) => (
                <KanbanColumn
                  key={value}
                  status={value}
                  label={label}
                  risks={columns[value].list}
                  project={project}
                />
              ))}
            </Group>
          </If>
        </DragDropContext>
      </ScrollArea>
    </Box>
  );
};

import { useEffect, useState } from 'react';

import { IconSwitchVertical } from '@tabler/icons-react';
import { Box, Button, Group, Pagination, Stack, Title } from '@mantine/core';
import { CreateComment } from '@/components/Risks/RiskDetails/CreateComment';
import { CommentCard } from './RiskCommentCard';

import { useUserMe } from '@/hooks/use-user-me';
import { useCommentList } from '@/openapi/queries';

export const RiskCommentList = ({
  riskId,
  setCommentCount,
}: {
  riskId: string;
  setCommentCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = 20;
  const sortBy = 'createdAt';

  // get comment list from API
  const {
    data: commentsResult,
    isLoading: isCommentsLoading,
    isError: isCommentsError,
    refetch: refetchComments,
  } = useCommentList({
    path: { riskId },
    query: {
      page,
      pageSize,
      sortBy,
      sortOrder,
    },
  });

  // get user data from API
  const userMe = useUserMe();

  const refreshCommentList = async () => {
    await refetchComments();
  };

  useEffect(() => {
    setTotalPages(commentsResult?.totalPages ?? 0);
    setCommentCount(commentsResult?.totalCount ?? 0);
  }, [commentsResult?.totalCount, commentsResult?.totalPages, setCommentCount]);

  // wait for comments to load
  if (isCommentsError) {
    return <div>Failed to load comments</div>;
  }
  if (isCommentsLoading) {
    return <div>Loading comments...</div>;
  }

  const userEmailSha256 = userMe.email_sha256 || 'ABS123';

  return (
    <Box py="md">
      <Stack gap="md">
        <Stack gap="md">
          <Group justify="space-between" align="center">
            <Group gap="xs">
              <Title order={2} size="h6">
                Comments
              </Title>
            </Group>
            <Button
              variant="subtle"
              leftSection={<IconSwitchVertical stroke={1.5} size="12px" />}
              color="dark"
              mt={0}
              onClick={() => {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                setPage(1);
              }}
            >
              {sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}
            </Button>
          </Group>

          <CreateComment
            userEmailSha256={userEmailSha256}
            riskId={riskId}
            refreshCommentList={refreshCommentList}
          />
          <Stack>
            {/* display list of comments */}
            {commentsResult?.results.map((c) => (
              <CommentCard
                riskId={riskId}
                key={c.id}
                comment={c}
                isAuthor={userMe.id === c.author.id}
                refreshCommentList={refreshCommentList}
              />
            ))}
            {/* show pagination */}
            <Pagination value={page} onChange={setPage} total={totalPages} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

import { Box, Divider, Flex, Group, Stack, Text } from '@mantine/core';
import { SubmissionDetailDto, ProjectDto, UserTypeEnum } from '@/openapi/requests';
import { SubmissionStatusSelect } from '../SubmissionStatusSelect/SubmissionStatusSelect';
import classes from './SubmissionSidebar.module.css';
import { Gravatar, SeverityStatus } from '@/components/Common';
import { RiskCategoryBadge } from '@/components/Risks/RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '@/components/Risks/RiskEnvironmentBadge/RiskEnvironmentBadge';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { useUserType } from '@/hooks/use-user-type';
import { ChildRiskList } from '../ChildRiskList/ChildRiskList';
import { DuplicatedRiskList } from '../DuplicatedRiskList/DuplicatedRiskList';
import { SubmissionTagsMenu } from '../SubmissionTags/SubmissionTagsMenu';
import { SubmissionTagsView } from '../SubmissionTags/SubmissionTagsView';

export function SubmissionSidebar({
  submission,
  project,
}: {
  submission: SubmissionDetailDto;
  project: ProjectDto;
}) {
  const userType = useUserType();
  const isResearcher = userType === UserTypeEnum.RESEARCHER;

  return (
    <Stack justify="start" gap={16}>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <SubmissionStatusSelect
            submissionId={submission.id}
            status={submission.status}
            projectId={project.id}
          />
        </Flex>
      </Stack>
      <Stack gap={4} className={classes.hideMobile}>
        <Flex>
          <SeverityStatus variant="filled" severity={submission.severity} />
        </Flex>
      </Stack>
      <Stack gap={4} className={classes.hideMobile}>
        <Text textfor="label">Tags</Text>
        <Box>
          <Group>
            <RiskCategoryBadge category={submission.category} />
            <RiskEnvironmentsBadge environment={submission.environment} />
            {!isResearcher && (
              <>
                <Divider orientation="vertical" />
                <SubmissionTagsView submissionId={submission.id} />
                <SubmissionTagsMenu submissionId={submission.id} />
              </>
            )}
          </Group>
        </Box>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">Disclosed</Text>
        <Text textfor="body">
          <DateDisplay showDate date={submission.createdAt} />
        </Text>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">Project</Text>
        <Text textfor="body">{project.name}</Text>
      </Stack>
      <Stack gap={4}>
        <Text textfor="label">Disclosed by</Text>
        <Group>
          <Gravatar emailHash={submission.createdByEmailSha256} size={24} withoutTooltip />
          <Text textfor="body">{submission.createdByName}</Text>
        </Group>
      </Stack>
      {userType === UserTypeEnum.TRIAGER && (
        <>
          <ChildRiskList submissionId={submission.id} />
          <DuplicatedRiskList submissionId={submission.id} projectId={project.id} />
        </>
      )}
    </Stack>
  );
}

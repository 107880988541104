import { useEffect, useMemo, useState } from 'react';
import { Button, CheckIcon, Combobox, Group, Text, useCombobox } from '@mantine/core';
import { IconUser, IconX } from '@tabler/icons-react';
import classes from './RiskFilters.module.css';
import { FilterOptions } from '@/hooks/use-filters';
import { useRiskFilters } from '@/store/risk-store';
import { useCompany } from '@/hooks/use-company';
import { useUserMe } from '@/hooks/use-user-me';
import { Gravatar } from '@/components/Common';

export function AssigneeMultiSelect({
  onChange,
  selected,
}: {
  onChange: (selected: string[]) => void;
  selected: string[];
}) {
  const [selectedItems, setSelectedItems] = useState<string[]>(selected);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems, onChange]);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const isSelected = selected.length;

  const { users: teamMembers } = useCompany();

  const { assignee, setAssignee } = useRiskFilters();
  const { id, email_sha256 } = useUserMe();

  const assigneeMeta = useMemo(
    () => ({
      label: 'Assignee',
      id: 'assignee',
      options: [
        {
          label: 'Me',
          value: id,
          emailHash: email_sha256,
        },
        ...teamMembers
          .filter((member) => member.id !== id)
          .map((member) => ({
            value: member.id,
            label: member.name || '',
            emailHash: member.email_sha256,
          })),
      ],
      selected: assignee,
      onChange: (e: string[]) => {
        setAssignee(e);
      },
      icon: <IconUser stroke={1.5} size="1rem" />,
      avatar: true,
    }),
    [teamMembers, assignee, setAssignee, email_sha256, id]
  );

  const options = assigneeMeta.options.map((item: FilterOptions, i: number) => (
    <Combobox.Option value={item.value} key={i}>
      <Group justify="space-between">
        <Group>
          <Gravatar
            tooltipPosition="bottom"
            name={item.value}
            emailHash={item.emailHash!}
            size={18}
            withoutTooltip
          />
          <Text>{item.label}</Text>
        </Group>
        {selected.includes(item.value as never) && <CheckIcon size={12} />}
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      width={null}
      position="bottom-start"
      withArrow
      withinPortal={false}
      positionDependencies={[selectedItems]}
      onOptionSubmit={(val) => {
        setSelectedItems((current) =>
          val === 'all'
            ? ['all']
            : current.includes(val)
              ? current.filter((item) => item !== val)
              : [...current.filter((value) => value !== 'all'), val]
        );
      }}
    >
      <Combobox.Target>
        <Button
          onClick={() => {
            combobox.toggleDropdown();
          }}
          variant={isSelected ? 'light' : 'subtle'}
          color={isSelected ? 'blue' : 'gray'}
          size="xs"
          mt={0}
          leftSection={<IconUser stroke={1.5} size="1rem" />}
          rightSection={
            isSelected ? (
              <IconX
                z={10}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedItems([]);
                }}
                className={classes.closeIcon}
                stroke={4}
                size={18}
              />
            ) : null
          }
        >
          Assignee
        </Button>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

import { Badge, Box, Container, Divider, Grid, Group, Stack, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import clsx from 'clsx';
import { CompanyContextProvider } from '@/contexts/CompanyContext';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import { SubmissionDetailDto } from '@/openapi/requests';
import { SubmissionHeader } from '@/components/Submissions/SubmissionHeader/SubmissionHeader';
import classes from './SubmissionDetail.module.css';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';
import { SubmissionDetails } from '@/components/Submissions/SubmissionDetails/SubmissionDetails';
import { SubmissionCommentList } from '@/components/Submissions/SubmissionDetails/SubmissionCommentList';
import { SubmissionSidebar } from '@/components/Submissions/SubmissionDetails/SubmissionSidebar';
import { useProjectRetrieve } from '@/openapi/queries';

interface LoaderProps {
  submission: SubmissionDetailDto;
}

export function SubmissionDetailPage() {
  const { submission } = useLoaderData() as LoaderProps;
  const project = useProjectRetrieve({ path: { projectId: submission.projectId } });
  const [commentCount, setCommentCount] = useState(0);

  useTrackPageView('submission_detail');

  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPoint = 200;

      if (scrollPosition > triggerPoint) {
        setIsStickyHeaderVisible(true);
      } else {
        setIsStickyHeaderVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <CompanyContextProvider companyId={project.data!.company.id}>
      <Box
        className={clsx(
          classes.stickyHeader,
          isStickyHeaderVisible ? classes.visible : classes.hidden
        )}
      >
        <Container>
          <SubmissionHeader submission={submission} stickyNav />
        </Container>
        <Divider />
      </Box>
      <Container>
        <Stack gap="xs" mt="xl">
          <Box>
            <BreadcrumbNavigation
              breadcrumbs={[
                { label: 'Project', href: '/projects/' },
                { label: project.data!.name, href: `/project/${project.data!.id}` },
                { label: submission.title, href: `/submission/${submission.id}` },
              ]}
            />
          </Box>

          <Grid align="start" px={{ md: 'sm' }} pt="sm" gutter={32}>
            <Grid.Col
              span={{
                xs: 12,
                md: 8,
              }}
            >
              <SubmissionHeader submission={submission} />
              <Box px={{ xs: 0, md: 'sm' }} py="sm">
                <SubmissionDetails submission={submission} />
              </Box>
              <Tabs defaultValue="comments">
                <Tabs.List>
                  <Tabs.Tab value="comments">
                    <Group>
                      Comments{' '}
                      {commentCount && (
                        <Badge size="sm" color="gray" variant="outline">
                          {commentCount}
                        </Badge>
                      )}
                    </Group>
                  </Tabs.Tab>
                  <Tabs.Tab value="history">History</Tabs.Tab>
                  <Tabs.Tab value="details" className={classes.hideDesktop}>
                    Details
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="comments">
                  <SubmissionCommentList
                    submissionId={submission.id}
                    setCommentCount={setCommentCount}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="history">Submission History</Tabs.Panel>
                <Tabs.Panel value="details" className={classes.hideDesktop}>
                  <Box py="md">
                    <SubmissionSidebar submission={submission} project={project.data!} />
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>
            <Grid.Col
              span={{
                xs: 12,
                md: 4,
              }}
              className={classes.hideMobile}
            >
              <SubmissionSidebar submission={submission} project={project.data!} />
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </CompanyContextProvider>
  );
}

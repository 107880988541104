import { AxiosError, AxiosRequestConfig } from 'axios';
import { client } from './openapi/requests/services.gen';
import { StorageKeys } from './utils/constants';

client.setConfig({
  baseURL: import.meta.env.VITE_API_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface RetryQueueItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (value?: any) => void;
  reject: (error?: unknown) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

client.instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(StorageKeys.JWT_TOKEN);
    if (token && token !== 'undefined') {
      config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
    }

    if (!config.url || !config.params) {
      return config;
    }

    for (const [key, value] of Object.entries<string>(config.params)) {
      const stringToSearch = `{${key}}`;
      if (config.url !== undefined && config.url.search(stringToSearch) !== -1) {
        config.url = config.url.replace(`{${key}}`, encodeURIComponent(value));
        delete config.params[key];
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

client.instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (window.location.pathname.includes('/invitation')) {
      return;
    }

    if (error.response && error.response.status === 401 && originalRequest) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken = localStorage.getItem(StorageKeys.REFRESH_TOKEN);
          if (refreshToken) {
            const response = await client.instance.post(
              `${import.meta.env.VITE_API_URL}/auth/refresh_token`,
              {
                refreshToken: JSON.parse(refreshToken),
              }
            );
            if (response.status === 201) {
              localStorage.setItem(
                StorageKeys.JWT_TOKEN,
                JSON.stringify(response.data.accessToken)
              );
              localStorage.setItem(
                StorageKeys.REFRESH_TOKEN,
                JSON.stringify(response.data.refreshToken)
              );

              originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;

              refreshAndRetryQueue.forEach((queueItem) => {
                client.instance
                  .request(queueItem.config)
                  .then((_response) => {
                    queueItem.resolve(_response);
                  })
                  .catch((_error) => {
                    queueItem.reject(_error);
                  });
              });
              refreshAndRetryQueue.length = 0;
              return client.instance(originalRequest);
            }
          } else {
            isRefreshing = false;
            // if (window.location.pathname.includes('/invitation')) {
            //   return Promise.reject(error);
            // }
            if (!window.location.pathname.includes('/auth')) {
              const intendedUrl = window.location.pathname;
              window.location.href = `/auth/sign-in?intended=${intendedUrl}`;
            }
          }
        } catch (_error) {
          localStorage.removeItem(StorageKeys.JWT_TOKEN);
          localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
          window.location.href = '/auth/sign-in';
        } finally {
          isRefreshing = false;
        }
      } else {
        if (originalRequest?.url?.includes('/auth/refresh_token')) {
          localStorage.removeItem(StorageKeys.JWT_TOKEN);
          localStorage.removeItem(StorageKeys.REFRESH_TOKEN);

          window.location.href = '/auth/sign-in';
        }

        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ resolve, reject, config: originalRequest });
        });
      }
    }
    return Promise.reject(error);
  }
);

import { useEffect, useState } from 'react';
import { Box, Button, Group, Pagination, Stack, Title } from '@mantine/core';
import { IconSwitchVertical } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useSubmissionCommentList } from '@/openapi/queries';
import { useUserMe } from '@/hooks/use-user-me';
import { SubmissionCommentCard } from './SubmissionCommentCard';
import { SubmissionCreateComment } from './SubmissionCreateComment';

export const SubmissionCommentList = ({
  submissionId,
  setCommentCount,
}: {
  submissionId: string;
  setCommentCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = 20;
  const sortBy = 'createdAt';
  const queryClient = useQueryClient();

  const commentsResult = useSubmissionCommentList({
    path: { submissionId },
    query: {
      page,
      pageSize,
      sortBy,
      sortOrder,
    },
  });

  const userMe = useUserMe();

  const refreshCommentList = async () => {
    await queryClient.invalidateQueries();
  };

  useEffect(() => {
    setCommentCount(commentsResult.data?.totalCount ?? 0);
    setTotalPages(commentsResult.data?.totalPages ?? 0);
  }, [commentsResult.data?.totalCount, commentsResult.data?.totalPages, setCommentCount]);

  if (commentsResult.isError) {
    return <div>Failed to load comments</div>;
  }

  if (commentsResult.isLoading) {
    return <div>Loading...</div>;
  }

  const userEmailSha256 = userMe.email_sha256 || 'ABS123';

  return (
    <Box py="md">
      <Stack gap="md">
        <Stack gap="md">
          <Group justify="space-between" align="center">
            <Group gap="xs">
              <Title order={2} size="h6">
                Comments
              </Title>
            </Group>
            <Button
              variant="subtle"
              leftSection={<IconSwitchVertical stroke={1.5} size="12px" />}
              color="dark"
              mt={0}
              onClick={() => {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                setPage(1);
              }}
            >
              {sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}
            </Button>
          </Group>

          <SubmissionCreateComment
            submissionId={submissionId}
            refreshCommentList={refreshCommentList}
            userEmailSha256={userEmailSha256}
          />
          <Stack>
            {commentsResult.data?.results.map((c) => (
              <SubmissionCommentCard
                key={c.id}
                comment={c}
                isAuthor={userMe.id === c.authorId}
                refreshCommentList={refreshCommentList}
              />
            ))}
            <Pagination value={page} onChange={setPage} total={totalPages} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

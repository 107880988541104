import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useSubmissionList } from '@/openapi/queries';
import { ProjectDto } from '@/openapi/requests';
import { SubmissionCard } from '../SubmissionCard/SubmissionCard';
import { useSubmissionFiltersStore } from '@/store/submission-store';

interface SubmissionCardsProps {
  project: ProjectDto;
  page: number;
}

const SubmissionCards = ({ project, page }: SubmissionCardsProps) => {
  const { categories, severities, status, tags, dateRange } = useSubmissionFiltersStore();
  const { data, isLoading, isError } = useSubmissionList({
    query: {
      projectId: project.id,
      page,
      pageSize: 1000,
      categories,
      severities,
      statuses: status,
      tags,
      dateRange,
    },
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return data?.results.map((submission) => (
    <SubmissionCard
      submission={submission}
      layout="Row"
      key={submission.id}
      projectId={project.id}
    />
  ));
};

export default SubmissionCards;

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FC } from 'react';

interface DateDisplayProps {
  date: string;
  showDate?: boolean;
}

 
dayjs.extend(relativeTime);

const DateDisplay: FC<DateDisplayProps> = ({ date, showDate }: DateDisplayProps) => {
   
  const formattedDate: string = showDate
    ? dayjs(date).format('MMM D, YYYY')
    : dayjs(date).fromNow();

  return <>{formattedDate}</>;
};

// DateDisplay.defaultProps = {
//     date: dayjs().toISOString(),
// };

export default DateDisplay;

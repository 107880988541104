import { Badge, Box, Divider, Group, Stack, Title } from '@mantine/core';
import { SubmissionDetailDto } from '@/openapi/requests';
import { AttachmentItem } from '@/components/Attachments/AttachmentItem/AttachmentItem';
import { MarkdownViewer } from '@/components/Common/Markdown/MarkdownViewer';

export const SubmissionDetails = ({ submission }: { submission: SubmissionDetailDto }) => (
  <Stack gap="lg">
    <Box py="md">
      <Title order={2} size="h6" mb="md">
        Description
      </Title>
      <Box>
        <MarkdownViewer content={submission.description} />
      </Box>
    </Box>
    <Divider />
    <Box py="md">
      <Title order={2} size="h6" mb="md">
        <Group>
          Attachments <Badge>{submission.attachments.length}</Badge>
        </Group>
      </Title>
      {submission.attachments.length > 0 && (
        <Box>
          {submission.attachments.map((attachmentId) => (
            <AttachmentItem key={attachmentId} attachmentId={attachmentId} />
          ))}
        </Box>
      )}
    </Box>
    <Divider />
  </Stack>
);

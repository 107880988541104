import { Pill, darken, lighten } from '@mantine/core';
import { SubmissionTagDetailDto } from '@/openapi/requests';

interface SubmissionTagBadgeProps {
  tag: SubmissionTagDetailDto;
  onRemove?: (tagId: string) => void;
  withRemoveButton?: boolean;
}

export const SubmissionTagBadge = ({
  tag,
  onRemove,
  withRemoveButton = false,
}: SubmissionTagBadgeProps) => {
  const handleRemove = () => {
    if (!withRemoveButton) return;

    onRemove?.(tag.id);
  };

  return (
    <Pill
      key={tag.id}
      c={darken(tag.tagColor || 'blue', 0.1)}
      bg={lighten(tag.tagColor || 'blue', 0.8)}
      size="xs"
      radius="sm"
      fw="bold"
      tt="capitalize"
      withRemoveButton={withRemoveButton}
      onRemove={handleRemove}
    >
      {tag.tagName}
    </Pill>
  );
};

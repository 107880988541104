import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';

import { Button, Group, Modal, Stack, Switch, Text } from '@mantine/core';

export const UserStatusSwitcher = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const [isUserActive, setIsUserActive] = useState(true);

  const handleDisable = () => {
     
    setIsUserActive(false);
    close();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      open();
    } else {
      setIsUserActive(true);
    }
  };

  return (
    <>
      <Switch defaultChecked checked={isUserActive} onChange={handleChange} />

      <Modal title="Disable User" opened={opened} onClose={close} centered>
        <Stack>
          <Text>Are you sure you want to disable this user? </Text>

          <Group>
            <Button type="submit" color="red" onClick={handleDisable}>
              Disable
            </Button>
            <Button variant="subtle" onClick={close}>
              Cancel
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

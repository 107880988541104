import { Container, Space } from '@mantine/core';
import { useParams } from 'react-router-dom';

import ContractHeader from '@/components/Contracts/ContractHeader/ContractHeader';
import { ContractDetail } from '@/components/Contracts/ContractDetail/ContractDetail';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { useContractRetrieve } from '@/openapi/queries';

export function ContractDetailPage() {
  const { contractId } = useParams();
  if (contractId === undefined) {
    throw new Error('contractId is required');
  }
  const { data: contract, isLoading, isError } = useContractRetrieve({ path: { contractId } });

  useTrackPageView('contract_detail');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (!contract) {
    return <div>Error loading contract</div>;
  }

  return (
    <Container>
      <ContractHeader contract={contract} />
      <Space />
      <ContractDetail contract={contract} />
    </Container>
  );
}

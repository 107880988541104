import { RiskDto, RiskStatusEnum, SeverityEnum } from '@/openapi/requests';

interface RiskCounts {
    newRiskCount: number;
    inProgressRiskCount: number;
    closedRiskCount: number;
    criticalRiskCount: number;
    highRiskCount: number;
    mediumRiskCount: number;
    lowRiskCount: number;
    informationalRiskCount: number;
}

export const getRiskCount = (risksData: RiskDto[]): RiskCounts => {
    const data = Array.isArray(risksData) ? risksData : [];

    return data.reduce((acc: RiskCounts, risk: {
        status?: RiskStatusEnum;
        severity?: SeverityEnum;
    }) => {
        const result: RiskCounts = { ...acc };

        switch (risk.status) {
            case RiskStatusEnum.NEW: {
                result.newRiskCount += 1;
                break;
            }
            case RiskStatusEnum.IN_PROGRESS: {
                result.inProgressRiskCount += 1;
                break;
            }
            case RiskStatusEnum.CLOSED: {
                result.closedRiskCount += 1;
                break;
            }
        }

        switch (risk.severity) {
            case SeverityEnum.CRITICAL: {
                result.criticalRiskCount += 1;
                break;
            }
            case SeverityEnum.HIGH: {
                result.highRiskCount += 1;
                break;
            }
            case SeverityEnum.MEDIUM: {
                result.mediumRiskCount += 1;
                break;
            }
            case SeverityEnum.LOW: {
                result.lowRiskCount += 1;
                break;
            }
            case SeverityEnum.INFORMATIONAL: {
                result.informationalRiskCount += 1;
                break;
            }
        }

        return result;
    }, {
        newRiskCount: 0,
        inProgressRiskCount: 0,
        closedRiskCount: 0,
        criticalRiskCount: 0,
        highRiskCount: 0,
        mediumRiskCount: 0,
        lowRiskCount: 0,
        informationalRiskCount: 0,
    });
};

import { Anchor, Box, Card, Group, Stack, Text } from '@mantine/core';
import { IconCopyright, IconExternalLink, IconParkingCircle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useSubmissionRiskLinkList } from '@/openapi/queries/queries';
import { SubmissionRiskLinkTypeEnum } from '@/openapi/requests';
import classes from './RiskSubmissionsList.module.css';

export const RiskSubmissionsList = ({ riskId }: { riskId: string }) => {
  const { data: submissionsRiskLinkData } = useSubmissionRiskLinkList({
    query: { riskId },
  });

  if (!submissionsRiskLinkData?.length) {
    return null;
  }

  return (
    <Stack gap={4}>
      <Text textfor="label">Linked Submissions</Text>
      <Box>
        <Stack gap={4}>
          <Card withBorder radius="md" className={classes.container}>
            <Stack gap={4}>
              {submissionsRiskLinkData.map((linked_risk) => (
                <Group
                  className={classes.duplicatedRisk}
                  justify="space-between"
                  key={linked_risk.id}
                >
                  <Group className={classes.title}>
                    {linked_risk.linkType === SubmissionRiskLinkTypeEnum.PARENTCHILD ? (
                      <IconParkingCircle size={15} />
                    ) : (
                      <IconCopyright size={15} />
                    )}
                    <Anchor
                      component={Link}
                      target="_blank"
                      to={`/submission/${linked_risk.submissionId}`}
                      key={linked_risk.submissionId}
                    >
                      {linked_risk.submissionTitle}
                    </Anchor>
                    <IconExternalLink
                      className={classes.linkIcon}
                      size={15}
                      style={{ cursor: 'pointer' }}
                    />
                  </Group>
                </Group>
              ))}
            </Stack>
          </Card>
        </Stack>
      </Box>
    </Stack>
  );
};

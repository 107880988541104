import {
  IconAlertTriangle,
  IconBolt,
  IconCategory,
  IconCircleCheck,
  IconCircleX,
  IconProgress,
  IconStatusChange,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { RISK_CATEGORY_LABEL_MAP, SEVERITY_LABEL_MAP } from '@/constants';
import { Status, useRiskFilters } from '@/store/risk-store';
import { CategoryEnum, RiskTagDto, SeverityEnum } from '@/openapi/requests';

export interface FilterOptions {
  value: string;
  label: string;
  type?: string;
  color?: string;
  badge?: boolean;
  id?: string;
  tag?: string;
  emailHash?: string;
  meta?: RiskTagDto;
  icon?: React.ReactNode;
}

export interface FilterMeta<T = unknown> {
  avatar?: boolean;
  label: string;
  id: string;
  options: FilterOptions[];
  icon?: React.ReactNode;
  default?: string[];
  selected: T[] | undefined;
  onChange?: (value: T[]) => void;
  remove?: () => void;
}

export const useFilters = () => {
  const { setCategories, setSeverities, categories, severities, status, setStatus } =
    useRiskFilters();

  const filters = useMemo(
    () => [
      {
        label: 'Status',
        id: 'status',
        options: [
          { value: 'NEW', label: 'New', icon: <IconBolt size="16" /> },
          { value: 'IN_PROGRESS', label: 'In Progress', icon: <IconProgress size="16" /> },
          { value: 'CLOSED', label: 'Closed', icon: <IconCircleCheck size="16" /> },
          { value: 'REJECTED', label: 'Rejected', icon: <IconCircleX size="16" /> },
        ],
        selected: status,
        onChange: (e: Status[]) => {
          setStatus(e);
        },
        icon: <IconStatusChange stroke={1.5} size="1rem" />,
      } as FilterMeta<Status>,
      {
        label: 'Severity',
        id: 'severity',
        options: Object.values(SeverityEnum).map((severity) => ({
          value: severity,
          label: SEVERITY_LABEL_MAP[severity],
          badge: true,
        })),
        onChange: (e: SeverityEnum[]) => {
          setSeverities(e);
        },
        selected: severities,
        icon: <IconAlertTriangle stroke={1.5} size="1rem" />,
      } as FilterMeta<SeverityEnum>,
      {
        label: 'Category',
        id: 'category',
        options: Object.values(CategoryEnum).map((category) => ({
          value: category,
          label: RISK_CATEGORY_LABEL_MAP[category],
        })),
        selected: categories,
        onChange: (e: CategoryEnum[]) => {
          setCategories(e);
        },
        icon: <IconCategory stroke={1.5} size="1rem" />,
      } as FilterMeta<CategoryEnum>,
    ],
    [status, setStatus, severities, setSeverities, categories, setCategories]
  );

  return { filters };
};

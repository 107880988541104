import { AttachmentCreateDto } from '@/openapi/requests';

import { uploadToS3 } from './requests';
import { useAttachmentCreate } from '@/openapi/queries';

export const useCreateNewAttachment = () => {
  const attachmentCreate = useAttachmentCreate();

  const createAttachment = async (file: File) => {
    const psr = await uploadToS3(file);
    if (psr === null) {
      throw new Error('Invalid psr');
    }

    const attachment: AttachmentCreateDto = {
      key: psr.fields.key,
      filename: file.name,
      contentType: file.type,
      size: file.size,
    };

    // Use the attachmentCreate mutation to create the attachment
    return attachmentCreate.mutateAsync({ body: attachment });
  };

  return { createAttachment, attachmentCreate };
};

import { IconBook, IconPencil, IconPlus } from '@tabler/icons-react';
import {
  Group,
  rem,
  Space,
  Text,
  Card,
  Tooltip,
  Anchor,
  ThemeIcon,
  ActionIcon,
  Button,
} from '@mantine/core';
import React from 'react';
import ProjectStats from '@/components/Projects/ProjectStats/ProjectStats';
import BreadcrumbNavigation, {
  type Breadcrumb,
} from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';
import { ProjectScopeModal } from '@/components/Projects/ProjectScopeModal/ProjectScopeModal';
import { ProjectTitleEditModal } from '@/components/Projects/ProjectTitleEditModal/ProjectTitleEditModal';
import If from '@/components/Common/If/If';

import { ContractDto, ProjectDto, UserTypeEnum } from '@/openapi/requests';

import classes from './ProjectHeader.module.css';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/configuration';

interface ActiveProjectHeaderProps {
  project: ProjectDto;
  contract?: ContractDto;
  userType: UserTypeEnum;
  breadcrumbs: Breadcrumb[];
}

const Icon = ({ children }: React.PropsWithChildren) => (
  <ThemeIcon variant="transparent" color="gray" size="md">
    {children}
  </ThemeIcon>
);

export function ProjectHeader({
  project,
  contract,
  userType,
  breadcrumbs,
}: ActiveProjectHeaderProps) {
  const navigate = useNavigate();
  return (
    <Group className={classes.HeaderContainer} justify="space-between" py={rem(8)}>
      <div>
        <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
        <Space />
        <Group>
          <Text textfor="h3">{project.name}</Text>

          <If condition={userType !== UserTypeEnum.RESEARCHER}>
            <ProjectTitleEditModal projectId={project.id} currentTitle={project.name}>
              <Tooltip label="Change Project title">
                <ActionIcon variant="subtle">
                  <IconPencil stroke={1.5} size={16} />
                </ActionIcon>
              </Tooltip>
            </ProjectTitleEditModal>
          </If>

          {/* only show the "New Risk" button for researchers with a valid contract */}
          <If condition={userType === UserTypeEnum.RESEARCHER && contract?.active}>
            <ProjectScopeModal projectId={project.id}>
              <Anchor>
                <Icon>
                  <Tooltip label="Project scope">
                    <IconBook stroke={1.5} size={16} />
                  </Tooltip>
                </Icon>
              </Anchor>
            </ProjectScopeModal>
          </If>
        </Group>
        <Text textfor="info">{project.company.name}</Text>
      </div>
      {userType === UserTypeEnum.RESEARCHER ? (
        <Button
          mb={5}
          leftSection={<IconPlus stroke={1.5} size="1rem" />}
          onClick={() => {
            navigate(`${paths.newSubmission(project.id)}?contract=${contract?.id}`);
          }}
        >
          Add Submission
        </Button>
      ) : (
        <Card p="xs">
          <ProjectStats project={project} />
        </Card>
      )}
    </Group>
  );
}

export default ProjectHeader;

import { IconCalendar, IconTrashX, IconX } from '@tabler/icons-react';
import { Button, Group, Menu } from '@mantine/core';
import classes from '../../Risks/RiskList/RiskFilters.module.css';
import { useUserType } from '@/hooks/use-user-type';
import If from '@/components/Common/If/If';
import { ButtonMultiSelect } from '@/components/Risks/RiskList/ButtonMultiSelect';
import { DateRangeFilter } from '@/components/Risks/RiskFilters/DateRangeFilter';
import { useSubmissionFiltersStore } from '@/store/submission-store';
import { FilterMeta, useSubmissionFilters } from '@/hooks/use-submission-filters';
import { SubmissionTagsSelect } from './SubmissionTagsSelect';
import { UserTypeEnum } from '@/openapi/requests';

interface SubmissionFiltersProps {
  activeTab: 'list' | 'kanban';
}

export const SubmissionFilters = ({ activeTab }: SubmissionFiltersProps) => {
  const {
    dateRange,
    removeDateRange,
    setDateRange,
    categories,
    status,
    severities,
    tags,
    setTags,
    removeAll,
  } = useSubmissionFiltersStore();
  const { filters } = useSubmissionFilters();
  const isDateSelected = dateRange.filter((date) => date !== null).length > 0;
  const userType = useUserType();

  return (
    <Group>
      {filters.map((filter, i) => (
        <ButtonMultiSelect
          key={i}
          activeTab={activeTab}
          filterMeta={filter as FilterMeta}
          onChange={filter.onChange as (selected: string | string[]) => void}
          selected={filter.selected}
        />
      ))}
      <If condition={userType !== UserTypeEnum.RESEARCHER}>
        <SubmissionTagsSelect selected={tags} onChange={setTags} />
      </If>
      <Menu position="bottom-start">
        <Menu.Target>
          <Button
            variant={isDateSelected ? 'light' : 'subtle'}
            color={isDateSelected ? 'blue' : 'gray'}
            size="xs"
            mt={0}
            leftSection={<IconCalendar stroke={1.5} size="1rem" />}
            rightSection={
              isDateSelected ? (
                <IconX
                  z={99999}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeDateRange('from');
                    removeDateRange('to');
                  }}
                  className={classes.closeIcon}
                  stroke={4}
                  size={18}
                />
              ) : null
            }
          >
            Date Range
          </Button>
        </Menu.Target>
        <Menu.Dropdown p="md">
          <DateRangeFilter selected={dateRange} onChange={setDateRange} />
        </Menu.Dropdown>
      </Menu>
      {tags.length || categories.length || status.length || severities.length || isDateSelected ? (
        <Button
          color="red.5"
          size="xs"
          mt={0}
          leftSection={<IconTrashX stroke={1.5} size="1rem" />}
          onClick={() => {
            removeAll();
          }}
          variant="subtle"
        >
          Clear All
        </Button>
      ) : null}
    </Group>
  );
};

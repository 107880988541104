import { Grid, Stack } from '@mantine/core';
import { RiskCards } from '@/components/Risks/RiskList/RiskCards';
import { ProjectOverviewCard } from '@/components/Projects/ProjectOverviewCard/ProjectOverviewCard';

import { useUserType } from '@/hooks/use-user-type';
import { UserTypeEnum, type ProjectDto } from '@/openapi/requests';

interface ListLayoutProps {
  project: ProjectDto;
}

export const ListLayout = ({ project }: ListLayoutProps) => {
  const userType = useUserType();

  const showOverviewCard = userType !== UserTypeEnum.RESEARCHER;

  return (
    <>
      <Grid>
        <Grid.Col
          span={{
            md: showOverviewCard ? 8 : undefined,
          }}
        >
          <Stack gap="md" pt="sm">
            <RiskCards project={project} page={1} />
          </Stack>
        </Grid.Col>

        {showOverviewCard && (
          <Grid.Col
            span={{
              md: 4,
            }}
            pt="lg"
          >
            <ProjectOverviewCard project={project} />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};

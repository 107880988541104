import { useAttachmentRetrieve } from '@/openapi/queries';
import { AttachmentDisplay } from './AttachmentDisplay';

interface AttachmentContentProps {
  attachmentId: string;
  onDelete: (attachmentId: string) => void;
}

export const AttachmentLoader = ({ attachmentId, onDelete }: AttachmentContentProps) => {
  const ret = useAttachmentRetrieve({ path: { attachmentId } });

  if (ret.isLoading) {
    return <AttachmentDisplay onDelete={() => undefined} isLoading={ret.isLoading} />;
  }

  if (ret.isError || !ret.data) {
    return (
      <AttachmentDisplay
        isError
        onDelete={() => {
          onDelete(attachmentId);
        }}
      />
    );
  }

  const { content_type, filename, size } = ret.data;

  return (
    <AttachmentDisplay
      onDelete={() => {
        onDelete(attachmentId);
      }}
      fileType={content_type}
      filename={filename}
      fileSize={size}
    />
  );
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import { notifications } from '@mantine/notifications';
import { attachmentPresignedUrlCreate, PresignedUrlResponseDto } from '@/openapi/requests';

async function getPreSignedJson(file: File): Promise<PresignedUrlResponseDto | undefined> {
  const res = await attachmentPresignedUrlCreate({
    body: {
      filename: file.name,
      contentType: file.type,
    },
  });

  return res.data;
}

async function uploadFileToS3(presigneUrl: string, file: File) {
  let ret: AxiosResponse;
  try {
    ret = await axios.put(presigneUrl, file, {
      headers: {
        'Content-Type': file.type || 'applcation/octet-stream',
      },
    });
  } catch (err) {
    notifications.show({
      color: 'red',
      title: 'Failed to upload attachment',
      message: (err as AxiosError).message,
    });
    return false;
  }

  // check if response is 204 created
  if (!(ret.status === 204 || ret.status === 200)) {
    notifications.show({
      color: 'red',
      title: 'Failed to upload attachment',
      message: ret.statusText,
    });
    return false;
  }

  return true;
}

export async function uploadToS3(payload: Blob | null) {
  if (!payload) return null;

  const file = payload as File;

  // get s3 pre-signed data from API
  const psr = await getPreSignedJson(file);

  // build upload form
  if (!psr) return null;

  // upload form data
  const success: boolean = await uploadFileToS3(psr.url, file);

  return success ? psr : null;
}

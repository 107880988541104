import { CategoryEnum, RiskSeverityEnum } from './openapi/requests';

export const RISK_CATEGORY_LABEL_MAP: Record<CategoryEnum, string> = {
  [CategoryEnum.BROKEN_ACCESS_CONTROL]: 'Broken Access Control',
  [CategoryEnum.CRYPTOGRAPHIC_FAILURE]: 'Cryptographic Failure',
  [CategoryEnum.INJECTION]: 'Injection',
  [CategoryEnum.INSECURE_DESIGN]: 'Insecure Design',
  [CategoryEnum.SECURITY_MISCONFIGURATION]: 'Security Misconfiguration',
  [CategoryEnum.VULNERABLE_AND_OUTDATED_COMPONENTS]: 'Vulnerable & Outdated Components',
  [CategoryEnum.IDENTIFICATION_AND_AUTH_FAILURE]: 'Identification and Authentication Failure',
  [CategoryEnum.DATA_INTEGRITY_FAILURE]: 'Data Integrity Failure',
  [CategoryEnum.SECURE_LOGGING_FAILURE]: 'Secure Logging Failure',
  [CategoryEnum.SERVER_SIDE_REQUEST_FORGERY]: 'Server-Side Request Forgery',
};

export type RiskCategoryType = keyof typeof RISK_CATEGORY_LABEL_MAP;

export enum RISK_ENVIRONMENT_LABEL_MAP {
  WEB = 'Web',
  MOBILE = 'Mobile',
  API = 'API',
  NETWORK = 'Network',
}

export const SEVERITY_LABEL_MAP: Record<RiskSeverityEnum, string> = {
  [RiskSeverityEnum.CRITICAL]: 'Critical',
  [RiskSeverityEnum.HIGH]: 'High',
  [RiskSeverityEnum.MEDIUM]: 'Medium',
  [RiskSeverityEnum.LOW]: 'Low',
  [RiskSeverityEnum.INFORMATIONAL]: 'Informational',
};

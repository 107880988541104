import { useState } from 'react';
import clsx from 'clsx';
import { notifications } from '@mantine/notifications';

import {
  ActionIcon,
  Button,
  Group,
  rem,
  Stack,
  Text,
  Textarea,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { AttachmentItem } from '@/components/Attachments/AttachmentItem/AttachmentItem';
import Gravatar from '@/components/Common/Gravatar/Gravatar';
import DateDisplay from '@/components/Core/DateDisplay/DateDisplay';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';

import classes from './RiskDetails.module.css';

import { RiskCommentDto } from '@/openapi/requests';
import { useRiskCommentDelete, useRiskCommentPartialUpdate } from '@/openapi/queries';

const DeleteCommentButton = ({
  riskId,
  commentId,
  refreshCommentList,
}: {
  riskId: string;
  commentId: string;
  refreshCommentList: () => Promise<void>;
}) => {
  const deleteComment = useRiskCommentDelete(undefined, {
    onSuccess: async () => {
      await refreshCommentList();
      notifications.show({
        title: 'Comment Deleted',
        message: 'The comment has been deleted successfully.',
        color: 'green',
      });
    },
    onError: () => {
      notifications.show({
        title: 'Failed to delete comment',
        message: 'An error occurred while deleting the comment.',
        color: 'red',
      });
    },
  });

  const handleDeleteComment = async () => {
    await deleteComment.mutateAsync({
      path: {
        commentId,
        riskId,
      },
    });
  };

  return (
    <ConfirmModal
      buttonText="Delete Comment"
      onConfirm={handleDeleteComment}
      title="Confirm Delete Comment"
      description="Are you sure you want to delete this comment ?"
      color="red"
    >
      <Tooltip label="Delete">
        <ActionIcon variant="subtle" size="md" color="red">
          <IconTrash stroke={1.5} size={16} />
        </ActionIcon>
      </Tooltip>
    </ConfirmModal>
  );
};

const EditCommentInput = ({
  content,
  onCommentUpdated,
  onCancel,
  isLoading = false,
}: {
  content: string;
  onCommentUpdated: (comment: string) => void;
  onCancel: () => void;
  isLoading?: boolean;
}) => {
  const [comment, setComment] = useState(content);

  const handleSave = () => {
    onCommentUpdated(comment);
  };

  return (
    <Stack gap="xs">
      <Textarea
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <Group justify="flex-end">
        <Button onClick={onCancel} disabled={isLoading} variant="outline" color="gray">
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={comment === content} loading={isLoading}>
          Save
        </Button>
      </Group>
    </Stack>
  );
};

export const CommentCard = ({
  riskId,
  comment: {
    id,
    author: { email_sha256, name },
    content,
    created,
    attachments,
  },
  isAuthor,
  refreshCommentList,
}: {
  riskId: string;
  comment: RiskCommentDto;
  isAuthor: boolean;
  refreshCommentList: () => Promise<void>;
}) => {
  const [isEditMode, setEditMode] = useState(false);

  const openEditMode = () => {
    setEditMode(true);
  };

  const closeEditMode = () => {
    setEditMode(false);
  };

  const updateComment = useRiskCommentPartialUpdate(undefined, {
    onSuccess: async () => {
      await refreshCommentList();
      notifications.show({
        title: 'Comment Updated',
        message: 'Your comment has been updated',
        color: 'green',
      });
      closeEditMode();
    },
    onError: () => {
      notifications.show({
        title: 'Comment Update Failed',
        message: 'Your comment update failed ',
        color: 'red',
      });
    },
  });

  const handleCommentUpdated = (newContent: string) => {
    updateComment.mutate({
      path: {
        riskId,
        commentId: id,
      },
      body: {
        content: newContent,
      },
    });
  };

  const showActions = isAuthor && !isEditMode;

  return (
    <Group align="flex-start" wrap="nowrap" gap="md">
      <Gravatar emailHash={email_sha256} size={32} withoutTooltip />
      <Group
        className={clsx(
          classes.comment,
          isAuthor && classes.isAuthor,
          isEditMode && classes.isEditMode
        )}
        align="flex-start"
        wrap="nowrap"
        gap="md"
        p="md"
        flex={1}
      >
        <Stack gap={rem(6)} w="100%">
          <Group className={classes.commentHeader} justify="space-between" align="flex-start">
            <Title order={4} textfor="avatar">
              {name}
            </Title>
            <Group>
              <Text textfor="info" className={classes.commentDate}>
                <DateDisplay date={created} />
              </Text>
              {showActions && (
                <Group className={classes.commentActions}>
                  <DeleteCommentButton
                    riskId={riskId}
                    commentId={id}
                    refreshCommentList={refreshCommentList}
                  />

                  <Tooltip label="Edit">
                    <ActionIcon onClick={openEditMode} variant="subtle" size="md" color="gray">
                      <IconPencil stroke={1.5} size={16} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              )}
            </Group>
          </Group>

          {!isEditMode ? (
            <Text className={classes.message} textfor="message">
              {content}
            </Text>
          ) : (
            <EditCommentInput
              content={content}
              onCommentUpdated={handleCommentUpdated}
              onCancel={closeEditMode}
              isLoading={updateComment.isPending}
            />
          )}

          {attachments.map((attachmentId) => (
            <AttachmentItem attachmentId={attachmentId} key={attachmentId} />
          ))}
        </Stack>
      </Group>
    </Group>
  );
};

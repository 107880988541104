import React from 'react';
import moment from 'moment';
import { useDebouncedValue } from '@mantine/hooks';

import RiskCard from '@/components/Risks/RiskCard/RiskCard';

import { useRiskFilters } from '@/store/risk-store';

import { RiskStatusEnum, type ProjectDto } from '@/openapi/requests';
import { debounceDuration } from '@/configuration';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useRiskList } from '@/openapi/queries';

interface RiskCardsProps {
  project: ProjectDto;
  page: number;
}

export const RiskCards = React.memo(({ project, page }: RiskCardsProps) => {
  const { categories, severities, dateRange, tags, status, hideRejected, assignee } =
    useRiskFilters();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const [debStatuses] = useDebouncedValue(status, debounceDuration);

  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  // TODO: pagination
  const { data, isLoading, isError } = useRiskList({
    query: {
      project: project.id,
      assignee: debAssignee,
      category: debCategories,
      created_after: date_range_from,
      created_before: date_range_to,
      severity: debSeverities,
      status: debStatuses,
      tag: debTags,
      page,
      pageSize: undefined,
      sortBy: undefined,
      sortOrder: undefined,
    },
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error!</div>;
  }

  return data?.results
    .filter((risk) => (hideRejected ? risk.status !== RiskStatusEnum.REJECTED : true))
    .map((risk) => <RiskCard projectId={project.id} key={risk.id} layout="Row" risk={risk} />);
});

RiskCards.displayName = 'RiskCards';

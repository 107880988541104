import { useEffect, useState } from 'react';
import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useRiskList, useSubmissionRiskLinkList } from '@/openapi/queries';
import { SubmissionRiskLinkTypeEnum } from '@/openapi/requests';

interface RiskItem {
  riskId: string;
  title: string;
}

export const ConfirmDuplicateModal = ({
  submissionId,
  projectId,
  onSelectRisk,
}: {
  submissionId: string;
  projectId: string;
  onSelectRisk: (riskId: string) => void;
}) => {
  const [filteredRisks, setFilteredRisks] = useState<RiskItem[]>([]);
  const [, setSelectedRiskId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const duplcatedRisks = useSubmissionRiskLinkList({
    query: { submissionId, linkType: SubmissionRiskLinkTypeEnum.DUPLICATE },
  });

  const {
    data: risksData,
    isLoading,
    isError,
  } = useRiskList({
    query: { project: projectId },
  });

  useEffect(() => {
    const risks =
      risksData?.results
        .filter(
          (risk) =>
            !duplcatedRisks.data?.some((linked_risk) => linked_risk.riskId === risk.id) &&
            (!searchValue || risk.title.toLowerCase().includes(searchValue.toLowerCase()))
        )
        .map((risk) => ({
          riskId: risk.id,
          title: risk.title,
        })) ?? [];
    setFilteredRisks((prevRisks) => {
      const areEqual =
        prevRisks.length === risks.length &&
        prevRisks.every((prev, index) => prev.riskId === risks[index].riskId);
      return areEqual ? prevRisks : risks;
    });
  }, [duplcatedRisks.data, risksData?.results, searchValue]);

  const handleSubmit = (riskId: string) => {
    onSelectRisk(riskId);
  };

  return (
    <>
      <Combobox
        onOptionSubmit={(optionValue) => {
          setSelectedRiskId(optionValue);
          combobox.closeDropdown();
          setSearchValue(filteredRisks.find((_risk) => _risk.riskId === optionValue)?.title ?? '');
          handleSubmit(optionValue);
        }}
        withinPortal
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            flex={1}
            size="sm"
            placeholder="Search by title"
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.currentTarget.value);
              combobox.resetSelectedOption();
              combobox.openDropdown();
            }}
            onClick={() => {
              combobox.openDropdown();
            }}
            onFocus={() => {
              combobox.openDropdown();
            }}
            onBlur={() => {
              combobox.closeDropdown();
            }}
            rightSection={isLoading && <Loader size={18} />}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={filteredRisks.length === 0}>
          <Combobox.Options>
            {filteredRisks.slice(0, 9).map((_risk) => (
              <Combobox.Option value={_risk.riskId} key={_risk.riskId}>
                {_risk.title}
              </Combobox.Option>
            ))}
            {(!filteredRisks.length || isError) && (
              <Combobox.Empty>No results found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </>
  );
};

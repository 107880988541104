import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

import { Alert, Button, Card, Center, LoadingOverlay, Stack } from '@mantine/core';

import {
  ResetPasswordForm,
  type ResetPasswordValues,
} from '@/components/Settings/SettingsForms/ResetPasswordForm';
import { Logo } from '@/components/Common';
import { AuthLayout } from '@/layouts/AuthLayout/AuthLayout';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import { usePasswordResetConfirm, usePasswordResetValidate } from '@/openapi/queries';

const InvalidOrExpiredToken = () => (
  <Center>
    <Alert title="Invalid or Expired Token" color="red" mx="md">
      The password reset link you used is invalid or has expired. Please submit a new password reset
      request to get a fresh link sent to your email.
    </Alert>
  </Center>
);

const PasswordResetSuccess = () => (
  <Center>
    <Stack align="flex-start" gap="sm">
      <Alert title="Password Reset Successful" color="green">
        Password reset successful! You can now log in with your new password.
      </Alert>
      <Button component={Link} to="/">
        Log In now!
      </Button>
    </Stack>
  </Center>
);

const PasswordResetCard = ({ token }: { token: string }) => {
  const { mutate, isPending, isSuccess } = usePasswordResetConfirm(undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Password Reset Failed',
        message: error.message,
      });
    },
  });

  const handleSubmit = (values: ResetPasswordValues) => {
    mutate({
      body: {
        code: token,
        password: values.newPassword,
      },
    });
  };

  if (isSuccess) {
    return <PasswordResetSuccess />;
  }

  return (
    <Center>
      <Stack align="center" gap="md">
        <Logo />
        <Card shadow="xs">
          <ResetPasswordForm handleSubmit={handleSubmit} isPending={isPending} />
        </Card>
      </Stack>
    </Center>
  );
};

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { mutate, isSuccess, isPending } = usePasswordResetValidate(undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Password Reset Failed',
        message: error.message,
      });
    },
  });

  useEffect(() => {
    if (token) {
      mutate({ body: { code: token } });
    }
  }, [token, mutate]);

  useTrackPageView('reset_password');

  if (isPending) {
    return <LoadingOverlay visible overlayProps={{ blur: 2 }} />;
  }

  if (isSuccess) {
    return (
      <AuthLayout>
        <PasswordResetCard token={token!} />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <InvalidOrExpiredToken />
    </AuthLayout>
  );
}

export default ResetPasswordPage;

import { useQueryClient } from '@tanstack/react-query';
import { StorageKeys } from '@/utils/constants';
import { useRiskFilters } from '@/store/risk-store';
import { useSubmissionFiltersStore } from '@/store/submission-store';
import { useAuthLogout } from '@/openapi/queries/queries';

interface SignOutProps {
  redirectToRoot?: boolean;
  rootPath?: string;
}

export const useSignOut = ({
  redirectToRoot = false,
  rootPath = '/auth/sign-in',
}: SignOutProps = {}) => {
  const queryClient = useQueryClient();
  const { removeAll: removeRiskFilters } = useRiskFilters();
  const { removeAll: removeSubmissionFilters } = useSubmissionFiltersStore();

  const signOutMutation = useAuthLogout(undefined, {
    onSuccess: async () => {
      localStorage.removeItem(StorageKeys.JWT_TOKEN);
      localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
      queryClient.clear();
      removeRiskFilters();
      removeSubmissionFilters();
      if (redirectToRoot) {
        window.location.href = rootPath;
      }
    },
  });

  const signOut = () => signOutMutation.mutate({});

  return { signOut };
};

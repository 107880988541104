import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Anchor, Box, Button, TextInput, ThemeIcon } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { paths } from '@/configuration';
import { usePasswordResetCreate } from '@/openapi/queries';

interface RequestResetPasswordProps {
  email: string;
}

export const RequestResetPasswordForm = () => {
  const form = useForm<RequestResetPasswordProps>({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const { mutate, isPending } = usePasswordResetCreate(undefined, {
    onSuccess: () => {
      notifications.show({
        color: 'green',
        title: 'Check your email',
        message:
          'We sent you an email with instructions to reset your password. Please check your inbox.',
      });
    },
    onError: () => {
      notifications.show({
        color: 'red',
        title: 'Email failed',
        message:
          'There was an error sending the password reset email. Please try again or contact support.',
      });
    },
  });

  const handleResetPassword = (values: RequestResetPasswordProps) => {
    const userEmail = values.email;
    if (!userEmail) return;

    mutate({ body: { email: userEmail } });
  };

  return (
    <form onSubmit={form.onSubmit(handleResetPassword)}>
      <TextInput
        label="Email"
        placeholder="Your Email"
        required
        leftSection={
          <ThemeIcon>
            <IconMail strokeWidth="inherit" />
          </ThemeIcon>
        }
        {...form.getInputProps('email')}
      />

      <Button type="submit" loading={isPending} fullWidth size="md" mt="xl">
        Reset Password
      </Button>

      <Box ta="center" mt="md">
        <Anchor component={Link} to={paths.signIn}>
          Sign in?
        </Anchor>
      </Box>
    </form>
  );
};

import { Badge, Group } from '@mantine/core';

import { EnvironmentTypeEnum } from '@/openapi/requests';

interface RiskEnvironmentProps {
  environment: EnvironmentTypeEnum;
}

export const RiskEnvironmentsBadge = ({ environment }: RiskEnvironmentProps) => (
  <Group gap="xs">
    <Badge key={environment} variant="light" radius="sm" tt="capitalize">
      {environment}
    </Badge>
  </Group>
);

import { useLoaderData } from 'react-router-dom';

import { Container, Divider, rem, Tabs } from '@mantine/core';
import { ProjectHeader } from '@/components/Projects/ProjectHeader/ProjectHeader';
import RiskList from '@/components/Risks/RiskList/RiskList';
import { CompanyContextProvider } from '@/contexts/CompanyContext';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { paths } from '@/configuration';
import type { ContractListDto, ProjectDto, UserDto, UserTypeEnum } from '@/openapi/requests';
import SubmissionList from '@/components/Submissions/SubmissionList/SubmissionList';

interface LoaderProps {
  project: ProjectDto;
  contractList: ContractListDto;
  user: UserDto;
}

export function ProjectDetailPage() {
  const { project, contractList, user } = useLoaderData() as LoaderProps;

  useTrackPageView('project_details');

  if (contractList.results.length === 0) {
    return <div>Failed to load contract results</div>;
  }

  // we are assuming that a researcher can only have 1 contract with a project
  const contract = contractList.results[0];

  const breadcrumbs = [
    { label: 'Project', href: paths.projects },
    { label: project.name, href: paths.project(project.id) },
  ];

  const Header = () => {
    switch (user.user_type as string) {
      case 'CLIENT':
      case 'TRIAGER':
        return (
          <ProjectHeader
            project={project}
            // contracts={contractList.results}
            userType={user.user_type as UserTypeEnum}
            breadcrumbs={breadcrumbs}
          />
        );
      case 'RESEARCHER':
        return (
          <ProjectHeader
            project={project}
            contract={contract}
            userType={user.user_type as UserTypeEnum}
            breadcrumbs={breadcrumbs}
          />
        );
      default:
        return <></>;
    }
  };

  const ItemsList = () => {
    switch (user.user_type as string) {
      case 'CLIENT':
        return <RiskList project={project} />;
      case 'TRIAGER':
        // Tab based interface with both Lists
        return (
          <Tabs defaultValue="submissions">
            <Tabs.List>
              <Tabs.Tab value="submissions">Submissions</Tabs.Tab>
              <Tabs.Tab value="risks">Risks</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="submissions">
              <SubmissionList project={project} />
            </Tabs.Panel>
            <Tabs.Panel value="risks">
              <RiskList project={project} />
            </Tabs.Panel>
          </Tabs>
        );
      case 'RESEARCHER':
        return <SubmissionList project={project} />;
      default:
        return <></>;
    }
  };

  return (
    <CompanyContextProvider companyId={project.company.id}>
      <Container>
        <Header />
        <Divider mb={rem(4)} />
        <ItemsList />
      </Container>
    </CompanyContextProvider>
  );
}

import { QueryClient } from '@tanstack/react-query';
import { NewRisk } from './NewRisk.page';
import { NewRiskLoader } from './NewRisk.loader';
import { paths } from '@/configuration';

export const NewRiskPath = (queryClient: QueryClient) => ({
  path: paths.newRisk(':projectId'),
  element: <NewRisk />,
  loader: NewRiskLoader(queryClient),
});

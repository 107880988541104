// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3 

import { type Options } from "@hey-api/client-axios";
import { type QueryClient } from "@tanstack/react-query";
import { adminCompanyList, adminContractList, adminProjectList, adminRiskList, adminUserList, attachmentRetrieve, commentList, companyInvitationList, companyList, companyRetrieve, companyTagsList, contractList, contractRetrieve, emailTemplateList, emailTemplateRetrieve, health, projectDetailsRetrieve, projectList, projectRetrieve, projectSubmissionsStats, proposalList, riskAvailableStatuses, riskHistoryList, riskLinkedRisksList, riskList, riskRetrieve, riskTagList, statsCompany, statsProject, statsRisksProjectsContractsCompanies, submissionAvailableStatuses, submissionCommentList, submissionList, submissionRetrieve, submissionRiskLinkList, submissionTagList, unlockRequestList, userMeRetrieve, userRetrieve } from "../requests/services.gen";
import { AdminCompanyListData, AdminContractListData, AdminProjectListData, AdminRiskListData, AdminUserListData, AttachmentRetrieveData, CommentListData, CompanyInvitationListData, CompanyRetrieveData, CompanyTagsListData, ContractListData, ContractRetrieveData, EmailTemplateListData, EmailTemplateRetrieveData, ProjectDetailsRetrieveData, ProjectRetrieveData, ProjectSubmissionsStatsData, ProposalListData, RiskAvailableStatusesData, RiskHistoryListData, RiskLinkedRisksListData, RiskListData, RiskRetrieveData, RiskTagListData, StatsCompanyData, StatsProjectData, SubmissionAvailableStatusesData, SubmissionCommentListData, SubmissionListData, SubmissionRetrieveData, SubmissionRiskLinkListData, SubmissionTagListData, UnlockRequestListData, UserRetrieveData } from "../requests/types.gen";
import * as Common from "./common";
export const prefetchUseHealth = (queryClient: QueryClient, clientOptions: Options<unknown, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseHealthKeyFn(clientOptions), queryFn: () => health({ ...clientOptions }).then(response => response.data) });
export const prefetchUseUserMeRetrieve = (queryClient: QueryClient, clientOptions: Options<unknown, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseUserMeRetrieveKeyFn(clientOptions), queryFn: () => userMeRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAdminUserList = (queryClient: QueryClient, clientOptions: Options<AdminUserListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseAdminUserListKeyFn(clientOptions), queryFn: () => adminUserList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseUserRetrieve = (queryClient: QueryClient, clientOptions: Options<UserRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseUserRetrieveKeyFn(clientOptions), queryFn: () => userRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseCompanyList = (queryClient: QueryClient, clientOptions: Options<unknown, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseCompanyListKeyFn(clientOptions), queryFn: () => companyList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAdminCompanyList = (queryClient: QueryClient, clientOptions: Options<AdminCompanyListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseAdminCompanyListKeyFn(clientOptions), queryFn: () => adminCompanyList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseCompanyTagsList = (queryClient: QueryClient, clientOptions: Options<CompanyTagsListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseCompanyTagsListKeyFn(clientOptions), queryFn: () => companyTagsList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseCompanyRetrieve = (queryClient: QueryClient, clientOptions: Options<CompanyRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseCompanyRetrieveKeyFn(clientOptions), queryFn: () => companyRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseCompanyInvitationList = (queryClient: QueryClient, clientOptions: Options<CompanyInvitationListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseCompanyInvitationListKeyFn(clientOptions), queryFn: () => companyInvitationList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseProjectList = (queryClient: QueryClient, clientOptions: Options<unknown, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseProjectListKeyFn(clientOptions), queryFn: () => projectList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAdminProjectList = (queryClient: QueryClient, clientOptions: Options<AdminProjectListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseAdminProjectListKeyFn(clientOptions), queryFn: () => adminProjectList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseProjectRetrieve = (queryClient: QueryClient, clientOptions: Options<ProjectRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseProjectRetrieveKeyFn(clientOptions), queryFn: () => projectRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseProjectDetailsRetrieve = (queryClient: QueryClient, clientOptions: Options<ProjectDetailsRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseProjectDetailsRetrieveKeyFn(clientOptions), queryFn: () => projectDetailsRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseProjectSubmissionsStats = (queryClient: QueryClient, clientOptions: Options<ProjectSubmissionsStatsData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseProjectSubmissionsStatsKeyFn(clientOptions), queryFn: () => projectSubmissionsStats({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskList = (queryClient: QueryClient, clientOptions: Options<RiskListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskListKeyFn(clientOptions), queryFn: () => riskList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAdminRiskList = (queryClient: QueryClient, clientOptions: Options<AdminRiskListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseAdminRiskListKeyFn(clientOptions), queryFn: () => adminRiskList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseUnlockRequestList = (queryClient: QueryClient, clientOptions: Options<UnlockRequestListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseUnlockRequestListKeyFn(clientOptions), queryFn: () => unlockRequestList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskTagList = (queryClient: QueryClient, clientOptions: Options<RiskTagListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskTagListKeyFn(clientOptions), queryFn: () => riskTagList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskRetrieve = (queryClient: QueryClient, clientOptions: Options<RiskRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskRetrieveKeyFn(clientOptions), queryFn: () => riskRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskHistoryList = (queryClient: QueryClient, clientOptions: Options<RiskHistoryListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskHistoryListKeyFn(clientOptions), queryFn: () => riskHistoryList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskLinkedRisksList = (queryClient: QueryClient, clientOptions: Options<RiskLinkedRisksListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskLinkedRisksListKeyFn(clientOptions), queryFn: () => riskLinkedRisksList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseCommentList = (queryClient: QueryClient, clientOptions: Options<CommentListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseCommentListKeyFn(clientOptions), queryFn: () => commentList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseRiskAvailableStatuses = (queryClient: QueryClient, clientOptions: Options<RiskAvailableStatusesData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseRiskAvailableStatusesKeyFn(clientOptions), queryFn: () => riskAvailableStatuses({ ...clientOptions }).then(response => response.data) });
export const prefetchUseContractList = (queryClient: QueryClient, clientOptions: Options<ContractListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseContractListKeyFn(clientOptions), queryFn: () => contractList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAdminContractList = (queryClient: QueryClient, clientOptions: Options<AdminContractListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseAdminContractListKeyFn(clientOptions), queryFn: () => adminContractList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseContractRetrieve = (queryClient: QueryClient, clientOptions: Options<ContractRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseContractRetrieveKeyFn(clientOptions), queryFn: () => contractRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseProposalList = (queryClient: QueryClient, clientOptions: Options<ProposalListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseProposalListKeyFn(clientOptions), queryFn: () => proposalList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseAttachmentRetrieve = (queryClient: QueryClient, clientOptions: Options<AttachmentRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseAttachmentRetrieveKeyFn(clientOptions), queryFn: () => attachmentRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseStatsRisksProjectsContractsCompanies = (queryClient: QueryClient, clientOptions: Options<unknown, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseStatsRisksProjectsContractsCompaniesKeyFn(clientOptions), queryFn: () => statsRisksProjectsContractsCompanies({ ...clientOptions }).then(response => response.data) });
export const prefetchUseStatsCompany = (queryClient: QueryClient, clientOptions: Options<StatsCompanyData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseStatsCompanyKeyFn(clientOptions), queryFn: () => statsCompany({ ...clientOptions }).then(response => response.data) });
export const prefetchUseStatsProject = (queryClient: QueryClient, clientOptions: Options<StatsProjectData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseStatsProjectKeyFn(clientOptions), queryFn: () => statsProject({ ...clientOptions }).then(response => response.data) });
export const prefetchUseEmailTemplateList = (queryClient: QueryClient, clientOptions: Options<EmailTemplateListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseEmailTemplateListKeyFn(clientOptions), queryFn: () => emailTemplateList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseEmailTemplateRetrieve = (queryClient: QueryClient, clientOptions: Options<EmailTemplateRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseEmailTemplateRetrieveKeyFn(clientOptions), queryFn: () => emailTemplateRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionList = (queryClient: QueryClient, clientOptions: Options<SubmissionListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionListKeyFn(clientOptions), queryFn: () => submissionList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionRetrieve = (queryClient: QueryClient, clientOptions: Options<SubmissionRetrieveData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionRetrieveKeyFn(clientOptions), queryFn: () => submissionRetrieve({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionAvailableStatuses = (queryClient: QueryClient, clientOptions: Options<SubmissionAvailableStatusesData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionAvailableStatusesKeyFn(clientOptions), queryFn: () => submissionAvailableStatuses({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionRiskLinkList = (queryClient: QueryClient, clientOptions: Options<SubmissionRiskLinkListData, true> = {}) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionRiskLinkListKeyFn(clientOptions), queryFn: () => submissionRiskLinkList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionCommentList = (queryClient: QueryClient, clientOptions: Options<SubmissionCommentListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionCommentListKeyFn(clientOptions), queryFn: () => submissionCommentList({ ...clientOptions }).then(response => response.data) });
export const prefetchUseSubmissionTagList = (queryClient: QueryClient, clientOptions: Options<SubmissionTagListData, true>) => queryClient.prefetchQuery({ queryKey: Common.UseSubmissionTagListKeyFn(clientOptions), queryFn: () => submissionTagList({ ...clientOptions }).then(response => response.data) });

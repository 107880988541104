import { json, LoaderFunctionArgs } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { ensureUseSubmissionRetrieveData } from '@/openapi/queries/ensureQueryData';
import { prefetchUseProjectRetrieve } from '@/openapi/queries/prefetch';

export const SubmissionDetailLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { submissionId } = params;
    if (submissionId === undefined) {
      throw json({ message: 'submissionId is required' }, { status: 401 });
    }

    const submission = await ensureUseSubmissionRetrieveData(queryClient, {
      path: { submissionId },
    });

    await prefetchUseProjectRetrieve(queryClient, { path: { projectId: submission.projectId } });

    return { submission };
  };

import { json, LoaderFunctionArgs } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
  ensureUseContractListData,
  ensureUseProjectRetrieveData,
  ensureUseProposalListData,
  ensureUseUserMeRetrieveData,
} from '@/openapi/queries/ensureQueryData';

export const ProjectDetailLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { projectId } = params;
    if (projectId === undefined) {
      throw json({ message: 'projectId is required' }, { status: 401 });
    }

    const project = await ensureUseProjectRetrieveData(queryClient, {
      path: { projectId },
    });

    const contractList = await ensureUseContractListData(queryClient, {
      path: { projectId },
    });

    const proposalList = await ensureUseProposalListData(queryClient, { path: { projectId } });

    const user = await ensureUseUserMeRetrieveData(queryClient);

    return { project, contractList, proposalList, user };
  };

import { createContext, ReactNode } from 'react';
import { CompanyDto, UserTypeEnum } from '@/openapi/requests';

import { useUserType } from '@/hooks/use-user-type';
import LoadingScreen from '@/components/Common/LoadingScreen/LoadingScreen';
import { useCompanyRetrieve } from '@/openapi/queries';

interface CompanyContextType {
  company: CompanyDto;
}

interface CompanyContextProviderProps {
  companyId: string;
  children: ReactNode;
}

export const CompanyContext = createContext<CompanyContextType | null>(null);

export const CompanyContextProvider = ({ companyId, children }: CompanyContextProviderProps) => {
  const userType = useUserType();

  const {
    data: company,
    isLoading,
    isError,
    error,
  } = useCompanyRetrieve({
    path: { companyId },
    query: {
      enabled: userType !== UserTypeEnum.RESEARCHER,
      retry: false,
    },
  });

  if (userType === UserTypeEnum.RESEARCHER) {
    return children;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !company) {
    throw error;
  }

  return <CompanyContext.Provider value={{ company }}>{children}</CompanyContext.Provider>;
};

import { notifications } from '@mantine/notifications';

import { IconDotsVertical, IconReload, IconUserCancel } from '@tabler/icons-react';
import { ActionIcon, Avatar, Group, Menu, Stack, Table, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { InviteDto } from '@/openapi/requests';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
import {
  useCompanyInvitationDelete,
  useCompanyInvitationListKey,
  useCompanyInvitationResend,
} from '@/openapi/queries';

export const InvitationRow = ({ invite, companyId }: { invite: InviteDto; companyId: string }) => {
  const queryClient = useQueryClient();
  const companyInvitationResend = useCompanyInvitationResend(undefined, {
    onSuccess: async () => {
      notifications.show({
        title: 'Invitation Resent',
        message: 'The invitation has been resent to the user',
        color: 'green',
      });
      await queryClient.invalidateQueries({
        queryKey: [useCompanyInvitationListKey],
      });
    },
    onError: () => {
      notifications.show({
        title: 'Unexpected Error',
        message: "We couldn't resend the invitation, please try again",
        color: 'red',
      });
    },
  });

  const companyInvitationDelete = useCompanyInvitationDelete(undefined, {
    onSuccess: async () => {
      notifications.show({
        title: 'Invitation Deleted',
        message: 'The invitation has been deleted',
        color: 'green',
      });
      await queryClient.invalidateQueries({
        queryKey: [useCompanyInvitationListKey],
      });
    },
    onError: () => {
      notifications.show({
        title: 'Unexpected Error',
        message: "We couldn't delete the invitation, please try again",
        color: 'red',
      });
    },
  });

  const handleResendInvitation = () => {
    companyInvitationResend.mutate({
      path: { companyId, invitationId: invite.id },
    });
  };

  const handleCancelInvitation = () => {
    companyInvitationDelete.mutate({
      path: { companyId, invitationId: invite.id },
    });
  };

  return (
    <Table.Tr>
      <Table.Td>
        <Group>
          <Avatar
            size={32}
            src={`https://www.gravatar.com/avatar/${invite.email}?d=robohash&r=g&s=512`}
          />
          <Stack gap={0}>
            <Text textfor="body">{invite.email}</Text>
          </Stack>
        </Group>
      </Table.Td>

      <Table.Td>
        <Menu>
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDotsVertical stroke={1.5} size={18} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={handleResendInvitation}
              leftSection={<IconReload stroke={1.5} size={16} />}
            >
              Resend
            </Menu.Item>
            <Menu.Item
              color="red"
              onClick={() => {
                document.getElementById('cancel')?.click();
              }}
              leftSection={<IconUserCancel stroke={1.5} size={16} />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <ConfirmModal
          buttonText="Delete Invite"
          onConfirm={handleCancelInvitation}
          title="Delete Invitation"
          description="Are you sure you want to delete this invite?"
          color="red"
          cancelText="Keep Invite"
        >
          <div id="cancel" />
        </ConfirmModal>
      </Table.Td>
    </Table.Tr>
  );
};

import { QueryClient } from '@tanstack/react-query';
import { paths } from '@/configuration';
import { EditSubmissionPage } from './EditSubmission.page';
import { EditSubmissionLoader } from './EditSubmission.loader';

export const EditSubmissionPath = (queryClient: QueryClient) => ({
  path: paths.editSubmission(':submissionId'),
  element: <EditSubmissionPage />,
  loader: EditSubmissionLoader(queryClient),
});
